import { useNavigate } from 'react-router-dom'
import { FaSignOutAlt as LogoutIcon } from 'react-icons/fa'

import { AuthenticationService } from '@core/services'
import { IconButton } from '@presentation/components/molecules'

import * as S from './sidebar-footer.styles'

const SidebarFooter: React.FC = () => {
  const navigate = useNavigate()

  const onLogout = () => {
    AuthenticationService.logout()
    navigate('/login')
  }

  return (
    <S.Container>
      <IconButton
        icon={<LogoutIcon />}
        variant="outline-light"
        onClick={onLogout}
      >
        Sair
      </IconButton>
    </S.Container>
  )
}

export default SidebarFooter
