import { LocalForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  id: string
  onEdit: () => void
}

const EditLocalModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    path: '/locais',
    formId: 'edit-local',
    nameForTitle: 'Local',
    validationSchema,
    mapRequest: data => ({
      ...data,
      bloco_id: data.bloco.id,
      tipo_local_id: data.tipoLocal.id
    }),
    mapData: data => {
      return {
        ...data,
        tipoLocal: data.tipo_local
      }
    },
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <LocalForm />
    </EditModal>
  )
}

export default EditLocalModal
