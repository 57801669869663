import { memo, useEffect, useRef } from 'react'

import {
  useDashboard,
  useOnClickOutside,
  useResponsive
} from '@presentation/hooks'
import SidebarHeader from './header/sidebar-header'
import SidebarNavigation from './navigation/sidebar-navigation'
import SidebarFooter from './footer/sidebar-footer'

import * as S from './styles'

const Sidebar: React.FC = () => {
  const { isOpenSidebar, toggleSidebar } = useDashboard()
  const { isMobile } = useResponsive()
  const sidebarRef = useRef(null)

  // Se a tela for trocada para mobile fecha o sidebar se estiver aberto
  // Se a tela for trocada para uma maior que mobile abre o sidebar se estiver fechado
  const handleResponsiveSidebar = () =>
    (isMobile && isOpenSidebar) || (!isMobile && !isOpenSidebar)

  useEffect(() => {
    if (handleResponsiveSidebar()) {
      toggleSidebar()
    }
  }, [isMobile])

  // Se for mobile e o sidebar estiver aberto, fecha ao clicar fora do componente
  const handleClickOutside = () => {
    if (isOpenSidebar && isMobile) {
      toggleSidebar()
    }
  }
  useOnClickOutside(sidebarRef, () => handleClickOutside())

  return (
    <S.Container isOpen={isOpenSidebar} ref={sidebarRef}>
      <SidebarHeader />
      <S.Divider />
      <SidebarNavigation />
      <S.Divider />
      <SidebarFooter />
    </S.Container>
  )
}

export default memo(Sidebar)
