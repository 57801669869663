import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaArrowLeft as ArrowIcon } from 'react-icons/fa'

import { env } from '@main/configs'
import { IconButton } from '@presentation/components/molecules'

import * as S from './styles'

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
  }, [])

  return (
    <S.Container>
      <S.Code>404</S.Code>
      <S.Title>Algo deu errado aqui :(</S.Title>
      <S.Description>
        Estamos trabalhando nisso e vamos corrigí-lo o mais rápido possível
      </S.Description>
      <IconButton
        icon={<ArrowIcon />}
        onClick={() => navigate(env.app.homepage)}
      >
        Voltar
      </IconButton>
      <S.WaveIcon />
    </S.Container>
  )
}

export default NotFoundPage
