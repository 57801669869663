import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

const EixoSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Eixo"
      name="eixo"
      path={`/eixos?sort_by=nome`}
      control={control}
      error={formState?.errors.eixo?.message}
      isRequired
    />
  )
}

export default EixoSelectApi
