import { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { FileRejection, useDropzone } from 'react-dropzone'
import { AiFillCloseCircle as CloseIcon } from 'react-icons/ai'

import { Alert } from '@core/gateways'
import { DropzoneProps } from '@presentation/types/components/organisms'
import { Transition } from '@presentation/components/molecules'

import { DropFile, PDFThumbnail, UploadFile } from './components'
import * as S from './styles'

const Dropzone: React.FC<DropzoneProps> = ({
  supportedFormats,
  maxSize,
  file,
  onFileChange
}) => {
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    fileRejections.forEach(file => {
      const firstError = file.errors[0]

      switch (firstError.code) {
        case 'file-invalid-type':
          setFileError('Formato de arquivo não suportado.')
          break

        case 'file-too-large':
          setFileError('O arquivo é maior do que 2MB.')
          break
      }
    })
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      accept: supportedFormats,
      multiple: false,
      maxSize,
      onDrop: onDrop
    })

  const [fileError, setFileError] = useState<string>()

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onFileChange(acceptedFiles[0])
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (fileError) {
      const showAlert = async () => {
        await Alert.call({
          title: 'Falha de Envio',
          description: fileError,
          type: 'error'
        })
      }

      void showAlert()
      setFileError(undefined)
    }
  }, [fileError])

  const onCloseFile = () => {
    onFileChange(undefined)
  }

  return (
    <S.Container {...(file ? {} : getRootProps())} isActive={isDragActive}>
      <input {...getInputProps()} />

      {file ? (
        <S.FileFrame>
          <S.FileAction>
            <CloseIcon onClick={onCloseFile} />
          </S.FileAction>
          <S.FileViewer>
            {file.type === 'application/pdf' ? (
              <PDFThumbnail file={file} />
            ) : (
              <Image src={URL.createObjectURL(file)} />
            )}
          </S.FileViewer>

          <S.FileInfo>
            <small>{file.name}</small>
          </S.FileInfo>
        </S.FileFrame>
      ) : (
        <Transition
          flag={isDragActive}
          configs={{
            from: { opacity: 0, transform: 'translateY(100%)' },
            enter: { opacity: 1, transform: 'translateY(0%)' }
          }}
          currentComponent={<UploadFile />}
          transitionComponent={<DropFile />}
        />
      )}
    </S.Container>
  )
}

export default Dropzone
