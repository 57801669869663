import styled from 'styled-components'
import { rgba } from 'polished'
import { FaTimes } from 'react-icons/fa'

export const CloseIcon = styled(FaTimes)`
  cursor: pointer;
  color: ${props => rgba(props.theme.colors.white, 0.8)};

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`
