import { Title } from '@presentation/components/atoms'
import { PageProps } from '@presentation/types/components/templates'

import { Content, Header } from './components'

const Page: React.FC<PageProps> = ({
  children,
  isLoading = false,
  ...rest
}) => {
  return (
    <>
      <Title title={rest.title} />

      <Header isLoading={isLoading} {...rest} />

      <Content isLoading={isLoading}>{children}</Content>
    </>
  )
}

export default Page
