import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPlus as AddIcon } from 'react-icons/fa'

import { FormContext } from '@presentation/contexts'
import { useModal } from '@presentation/hooks'
import { ActionButton } from '@presentation/components/molecules'
import {
  Modal,
  PessoaIdentificacaoForm
} from '@presentation/components/organisms'
import {
  ModalProps,
  PessoaIdentificacaoFormInput
} from '@presentation/types/components/organisms'

import { hasIdentificacaoDuplicate, validationSchema } from './shared'

type Props = {
  append: Function
  fields: any[]
}

const AddIdentificacaoModal: React.FC<Props> = ({ append, fields }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const formMethods = useForm<PessoaIdentificacaoFormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const identificacao = formMethods.watch('identificacao')

  const [hasDuplicate, setHasDuplicate] = useState<boolean>(false)

  useEffect(() => {
    const hasDuplicate = hasIdentificacaoDuplicate(identificacao, fields)
    if (hasDuplicate) {
      setHasDuplicate(true)
    } else {
      setHasDuplicate(false)
    }
  }, [identificacao])

  const onSuccess = () => {
    resetForm()
    closeModal()
  }

  const onSubmit = () => {
    const input = formMethods.getValues()
    append(input)
    onSuccess()
  }

  const resetForm = () => {
    const values = formMethods.getValues()
    const keys = Object.keys(values)

    for (const key of keys) {
      formMethods.setValue<any>(key, null)
    }
  }

  const buttonActions = [
    <Button
      variant="success"
      disabled={!formMethods.formState.isValid || hasDuplicate}
      onClick={onSubmit}
    >
      Salvar
    </Button>
  ]

  const modalConfigs: ModalProps = {
    isOpen,
    icon: <AddIcon />,
    title: `Cadastro de Identificação`,
    description: `Use o formulário abaixo para cadastrar uma nova identificação.`,
    actions: buttonActions,
    size: 'lg',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="add" onClick={openModal} />

      <Modal {...modalConfigs}>
        <FormContext.Provider value={formMethods}>
          <PessoaIdentificacaoForm
            fields={fields}
            hasDuplicate={hasDuplicate}
          />
        </FormContext.Provider>
      </Modal>
    </>
  )
}

export default AddIdentificacaoModal
