import * as yup from 'yup'

export const maxFileSize = 2 * 1024 * 1024

export const supportedFormats = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf'
]

export const validationSchema = yup.object({
  file: yup
    .mixed()
    .required('O arquivo é obrigatório.')
    .test(
      'fileSize',
      'O arquivo é maior do que 2MB.',
      value => value && value.size <= maxFileSize
    )
    .test(
      'fileFormat',
      'Formato de arquivo não suportado',
      value => value && supportedFormats.includes(value.type)
    )
})
