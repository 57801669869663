import { Form, Row } from 'react-bootstrap'

import { useForm } from '@presentation/hooks'
import { Input } from '@presentation/components/molecules'

const CargoForm: React.FC = () => {
  const { register, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Input
          label="Nome"
          name="nome"
          register={register}
          error={formState.errors.nome?.message}
          required
          type="text"
          placeholder="Digite o nome do cargo"
          autoComplete="off"
        />
      </Row>
    </Form>
  )
}

export default CargoForm
