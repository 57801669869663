import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPen as EditPessoaIcon } from 'react-icons/fa'

import { Alert, LocalStorage } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { Pessoa, PessoaIdentificacao } from '@core/models/entities'
import { UnexpectedError, ValidationError } from '@core/models/errors'
import { ObjectUtils } from '@presentation/utils'
import { useErrors } from '@presentation/hooks'
import { FormContext } from '@presentation/contexts'
import { Page } from '@presentation/components/templates'
import { PessoaForm } from '@presentation/components/organisms'
import { ActionButton, ErrorList } from '@presentation/components/molecules'
import { PessoaFormInput } from '@presentation/types/components/organisms'
import { PageProps } from '@presentation/types/components/templates'

import { validationSchema } from '../validation'
import { mapRequest } from '../utils'
import { mapData } from './utils'

type Params = {
  id: string
}

const EditPessoaPage: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams() as Params
  const formMethods = useForm<PessoaFormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  })
  const { errors, setErrors, clearErrors } = useErrors({
    shouldScrollTop: true
  })

  const [data, setData] = useState<any>()
  const [profilePhoto, setProfilePhoto] = useState<File>()
  const [canTransit, setCanTransit] = useState<boolean>()

  useEffect(() => {
    if (data) {
      formMethods.reset(data)
      void forceValidation()
    } else {
      void loadData()
    }
  }, [data])

  const loadData = async () => {
    try {
      const pessoa = await AdministracaoService.loadOne({
        id: params.id,
        path: '/pessoas'
      })
      handleTransit(pessoa)
      const mappedData = mapData(pessoa)
      setData(mappedData)

      const profilePhoto = await AdministracaoService.getProfilePhoto({
        id: params.id,
        token: LocalStorage.get({ key: 'accessToken' })
      })
      setProfilePhoto(profilePhoto)
    } catch (error) {
      onError(error as Error)
    }
  }

  const handleTransit = (data: Pessoa) => {
    const hasComprovante = data.comprovante_covid19

    if (hasComprovante) {
      const isActive = data.identificacoes.some(
        (identificacao: PessoaIdentificacao) => {
          return (
            identificacao.situacao_id === 1 || identificacao.situacao_id === 5
          )
        }
      )

      setCanTransit(isActive)

      return
    }

    setCanTransit(false)
  }

  const forceValidation = async () => {
    await formMethods.trigger()
  }

  const reloadData = () => setData(undefined)

  const onSuccess = () => {
    Alert.callSuccess({
      title: 'Atualizado com Sucesso',
      onConfirm: reloadData
    })
  }

  const onError = (error: Error) => {
    Alert.callError({
      title: 'Falha de Atualização',
      description: error.message
    })
  }

  const onSubmit: SubmitHandler<PessoaFormInput> = async input => {
    try {
      const mappedInput = mapRequest(input)
      const formData = ObjectUtils.serializeToFormData(mappedInput)

      await AdministracaoService.update({
        id: params.id,
        data: formData,
        path: '/pessoas'
      })
      onSuccess()
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
      }

      if (error instanceof UnexpectedError) {
        onError(error)
      }
    }
  }

  const pageConfigs: PageProps = {
    icon: <EditPessoaIcon />,
    title: 'Edição de Pessoa',
    description: `Use o formulário abaixo para atualizar a pessoa selecionada em nosso banco de dados.`,
    actions: [<ActionButton type="back" onClick={() => navigate(-1)} />]
  }

  return (
    <Page {...pageConfigs}>
      {errors.length > 0 && <ErrorList errors={errors} onHide={clearErrors} />}
      <FormContext.Provider
        value={{
          formId: 'edit-pessoa',
          onSubmit: formMethods.handleSubmit(onSubmit),
          ...formMethods
        }}
      >
        <PessoaForm
          defaultProfilePhoto={profilePhoto}
          canTransit={canTransit}
        />
      </FormContext.Provider>
    </Page>
  )
}

export default EditPessoaPage
