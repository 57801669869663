import variables from './variables'

export const darkTheme = Object.assign({}, variables, {
  title: 'dark',

  colors: {
    white: '#e9ecef',
    danger: '#dc3545',
    gray: '#ccc',

    primary: '#e9ecef',
    secondary: '#043927',

    background: '#31383E',
    backgroundHover: '#3A444E',
    backgroundNavbar: '#3A444E',
    backgroundContent: '#3A444E',
    backgroundSidebar: '#3A444E',
    backgroundDropdown: '#31383E',
    backgroundSidebarDropdown: '#31383E',
    backgroundContentAccordeon: '#31383E',

    textPrimary: '#e9ecef',
    textSecondary: '#333333',

    borderInput: '#e9ecef',
    backgroundInput: '#3A444E',
    borderDisabledInput: '#e9ecef'
  }
})
