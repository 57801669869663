import { Section } from '@presentation/components/molecules'

const LegislacaoSection: React.FC = () => {
  return (
    <>
      <Section title="Legislação Aplicada" />

      <p>
        Ao utilizar o serviço Intranet, o usuário deverá acessar a plataforma
        somente para fins lícitos e em conformidade com o presente termo e
        limitações legais, bem como quaisquer politicas aplicáveis no Brasil.
        Caso possua alguma dúvida sobre os serviços da Intranet que envolvam a
        conformidade em relação a legislação brasileira, não deixe de conferir
        abaixo as leis e normativos para consulta:
      </p>

      <ul>
        <li>
          <a
            href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm"
            target="_blank"
          >
            Lei nº 13.709, de 14 de agosto de 2018
          </a>
          : Lei Geral de Proteção de Dados Pessoais (LGPD);
        </li>
        <li>
          <a
            href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2014/lei/l12965.htm"
            target="_blank"
          >
            Lei nº 12.965, de 23 de abril de 2014
          </a>{' '}
          (Marco Civil da Internet) : estabelece princípios, garantias, direitos
          e deveres para o uso da Internet no Brasil;
        </li>
        <li>
          <a
            href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm"
            target="_blank"
          >
            Lei nº 12.527, de 18 de novembro de 2011
          </a>{' '}
          (Lei de Acesso à Informação) : regula o acesso a informações previsto
          na Constituição Federal;
        </li>
        <li>
          <a
            href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2017/lei/l13460.htm"
            target="_blank"
          >
            Lei nº 13.460, de 26 de junho de 2017
          </a>
          : dispõe sobre participação, proteção e defesa dos direitos do usuário
          dos serviços públicos da administração pública;
        </li>
        <li>
          <a
            href="https://www.in.gov.br/materia/-/asset_publisher/Kujrw0TZC2Mb/content/id/56970098/do1-2018-12-27-decreto-n-9-637-de-26-de-dezembro-de-2018-56969938"
            target="_blank"
          >
            Decreto nº 9.637, de 26 de dezembro de 2018
          </a>
          : institui a Política Nacional de Segurança da Informação, dispõe
          sobre a governança da segurança da informação, e altera o Decreto nº
          2.295, de 4 de agosto de 1997, que regulamenta o disposto no artigo
          24, caput, inciso IX, da Lei nº 8.666, de 21 de junho de 1993.
        </li>
        <li>
          <a
            href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2012/decreto/d7845.htm"
            target="_blank"
          >
            Decreto nº 7.845, de 14 de novembro de 2012
          </a>
          : regulamenta procedimentos para credenciamento de segurança e
          tratamento de informação classiﬁcada em qualquer grau de sigilo, e
          dispõe sobre o Núcleo de Segurança e Credenciamento;
        </li>
      </ul>
    </>
  )
}

export default LegislacaoSection
