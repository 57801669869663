import styled, { css } from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { rgba } from 'polished'

import { media } from '@presentation/styles'

const leftPoint = css`
  content: ' ';
  position: relative;
  display: inline-block;

  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  left: -18px;
  vertical-align: middle;
`

export const NavLink = styled(RouterNavLink)`
  ${({ theme }) => css`
    position: relative;
    padding: 0.7rem 0 0.7rem 3.3rem;

    font-size: 0.95rem;
    color: ${rgba(theme.colors.white, 0.9)};
    text-align: left;
    text-decoration: none;

    &:hover {
      color: ${theme.colors.white};
      font-weight: 500;
    }

    &.active {
      color: ${theme.colors.white};
      font-weight: 500;

      &::before {
        ${leftPoint}
      }
    }

    ${media.greaterThan('mobile')`
      &.collapsible {
        padding: 0.7rem 0 0.7rem 2.7rem;
      }
    `}
  `}
`
