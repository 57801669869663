import styled from 'styled-components'
export const AccordionWrapper = styled.div`
  justify-content: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
`

export const Container = styled.div`
  background: ${props =>
    props.theme.title === 'dark'
      ? props.theme.colors.background
      : props.theme.colors.backgroundHover};
  padding: 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
`
export const Subtitle = styled.h5`
  padding-bottom: 3px;
  margin-bottom: 0.5rem;
  border-bottom: 2px ${props => props.theme.colors.primary} solid;
`
