import { Form, Row } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { IoDocumentAttachSharp as DocumentIcon } from 'react-icons/io5'

import { Alert } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { Checkbox, SubmitButton } from '@presentation/components/molecules'
import { Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'
import { TermosUsoPage } from '@presentation/pages/termos'

import { validationSchema } from './validation'
import * as S from './styles'

type Props = {
  token: string
  isOpen: boolean
  closeModal: () => void
  onAccept: () => void
}

const AcceptTermosUsoModal: React.FC<Props> = ({
  token,
  isOpen,
  closeModal,
  onAccept
}) => {
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const formId = 'aceitar-termos-form'

  const onError = async (error: Error) => {
    reset()
    closeModal()
    await Alert.call({
      title: 'Falha nos Termos de Uso',
      description: error.message,
      type: 'error'
    })
  }

  const onSubmit: SubmitHandler<any> = async input => {
    try {
      await AdministracaoService.acceptTerms({
        token
      })
      onAccept()
    } catch (error) {
      await onError(error as Error)
    }
  }

  const buttonActions = [
    <SubmitButton
      form={formId}
      isValid={formState.isValid}
      isSubmitting={formState.isSubmitting}
    >
      Aceitar
    </SubmitButton>
  ]
  const modalConfigs: ModalProps = {
    isOpen,
    icon: <DocumentIcon />,
    title: `Termos de Uso`,
    description:
      'A utilização do sistema integrado Intranet será liberado após aceite das condições de uso abaixo.',
    actions: buttonActions,
    size: 'lg',
    onClose: closeModal
  }

  return (
    <Modal {...modalConfigs}>
      <S.ScrollBox>
        <TermosUsoPage />
      </S.ScrollBox>

      <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Checkbox
            name="wasAccepted"
            register={register}
            label="Li e concordo com os Termos de Uso"
            error={formState.errors?.wasAccepted}
          />
        </Row>
      </Form>
    </Modal>
  )
}

export default AcceptTermosUsoModal
