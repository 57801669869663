import { FaLocationArrow as LotacoesIcon } from 'react-icons/fa'

import { Lotacao, PermissaoTipo } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import {
  AddLotacaoModal,
  DeleteLotacaoModal,
  EditLotacaoModal,
  ReactivateLotacaoModal
} from './components'
import { toTitleCase } from '@presentation/utils'

const LotacoesPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/lotacoes',
    options: { sortBy: 'nome' },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <LotacoesIcon />,
    title: 'Lotações',
    description: 'Página de gerenciamento de lotações do campus.',
    actions: hasPermission(PermissaoTipo.Escrita)
      ? [<AddLotacaoModal onAdd={fetchData} />]
      : []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        label: 'Responsável',
        value: 'responsavel_id'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/pessoa-identificacoes?tipo=3&situacao=5',
          isMulti: true,
          getOptionLabel: (option: any) => option.pessoa.nome
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      status: 1
    }
  }

  const columns: Array<TableColumn<Lotacao>> = [
    {
      name: 'Nome',
      selector: lotacao => lotacao.nome,
      sortable: true,
      center: true
    },
    {
      name: 'Email',
      selector: lotacao => lotacao.email,
      sortable: true,
      center: true
    },
    {
      name: 'Responsavel',
      selector: lotacao =>
        lotacao.responsavel.pessoa
          ? toTitleCase(lotacao.responsavel.pessoa.nome)
          : '',
      sortable: true,
      center: true
    },
    {
      name: 'Ações',
      cell: lotacao => (
        <>
          {lotacao.status ? (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <EditLotacaoModal id={lotacao.id} onEdit={fetchData} />
              )}
              {hasPermission(PermissaoTipo.Remocao) && (
                <DeleteLotacaoModal id={lotacao.id} onDelete={fetchData} />
              )}
            </>
          ) : (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <ReactivateLotacaoModal
                  id={lotacao.id}
                  onReactivate={fetchData}
                />
              )}
            </>
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default LotacoesPage
