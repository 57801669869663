import { PessoaTipo } from '@core/models/entities'
import { GrupoAcessoForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { SelectOption } from '@presentation/types/components/molecules'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from '../validation'

type Props = {
  onAdd: () => void
}

const AddLotacaoModal: React.FC<Props> = ({ onAdd }) => {
  const mapRequest = (data: any) => {
    return {
      nome: data.nome,
      descricao: data.descricao,
      pessoas_tipo: data.pessoaTipos.map(
        (pessoaTipo: PessoaTipo) => pessoaTipo.id
      ),
      permissoes: data.permissoes
        .map((permissao: SelectOption, index: number) => {
          return {
            permissao_id: index,
            tipo: permissao.value
          }
        })
        .filter((item: any) => item.tipo !== undefined)
    }
  }

  const modalConfigs: AddModalProps = {
    path: '/grupos-acesso',
    formId: 'add-grupo-acesso',
    nameForTitle: 'Grupo de Acesso',
    validationSchema: validationSchema,
    mapRequest: mapRequest,
    size: 'xl',
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <GrupoAcessoForm key="1" />
    </AddModal>
  )
}

export default AddLotacaoModal
