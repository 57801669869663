import { PessoaSituacaoTipo, PessoaTipo } from '@core/models/entities'
import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

type Props = {
  fields: any[]
  ignoreId?: number
}

export const TipoSelectApi: React.FC<Props> = ({ fields, ignoreId }) => {
  const { control, formState } = useForm()

  const removeIgnoreId = (items: number[]) => {
    if (ignoreId !== undefined) {
      const editField = fields[ignoreId]

      const index = items.indexOf(editField.tipo.id)
      if (index > -1) {
        items.splice(index, 1)
      }
    }
  }

  const usedIds = fields
    .filter(
      field =>
        field.situacao.id === PessoaSituacaoTipo.Matriculado ||
        field.situacao.id === PessoaSituacaoTipo.Ativo
    )
    .map(field => field.tipo.id)

  const isTipoOptionDisabled = (option: PessoaTipo) => {
    removeIgnoreId(usedIds) // edit mode
    return usedIds.includes(option.id)
  }

  return (
    <SelectApi
      label="Tipo"
      name="tipo"
      path="/pessoa-tipos?sort_by=descricao"
      control={control}
      placeholder="Selecione aqui"
      error={formState?.errors.tipo?.message}
      getOptionLabel={option => option.descricao}
      getOptionValue={option => option.id}
      searchKey="descricao"
      isOptionDisabled={(option: any) =>
        isTipoOptionDisabled(option as PessoaTipo)
      }
      isRequired
    />
  )
}

export default TipoSelectApi
