import variables from './variables'

export const lightTheme = Object.assign({}, variables, {
  title: 'light',

  colors: {
    white: '#e9ecef',
    danger: '#dc3545',
    gray: '#ccc',

    primary: '#075f40',
    secondary: '#043927',

    background: '#e9ecef',
    backgroundHover: '#e9ecef',
    backgroundNavbar: '#043927',
    backgroundSidebar: '#075f40',
    backgroundContent: '#f9f9f9',
    backgroundDropdown: '#f9f9f9',
    backgroundSidebarDropdown: '#043927',
    backgroundContentAccordeon: '#e9ecef',

    textPrimary: '#333333',
    textSecondary: '#e9ecef',

    borderInput: '#ced4da',
    backgroundInput: '#fff',
    borderDisabledInput: '#ced4da'
  }
})
