import { CursoForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from '../validation'

type Props = {
  id: number
  onEdit: () => void
}

const EditCursoModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    formId: 'edit-curso',
    nameForTitle: 'Curso',
    path: '/cursos',
    validationSchema,
    mapData: data => ({
      nome: data.nome,
      cursoTipo: data.curso_tipo,
      eixo: data.eixo,
      externo: data.externo ? '1' : '0'
    }),
    mapRequest: data => ({
      nome: data.nome,
      curso_tipo_id: data.cursoTipo.id,
      eixo_id: data.eixo.id,
      externo: data.externo
    }),
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <CursoForm />
    </EditModal>
  )
}

export default EditCursoModal
