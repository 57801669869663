import { Section } from '@presentation/components/molecules'

const DireitosSection: React.FC = () => {
  return (
    <>
      <Section title="Direitos do Usuário" />

      <p>
        Segundo a Lei de Proteção de Dados Pessoais, confere ao usuário os
        seguintes direitos:
      </p>

      <ul>
        <li>
          <strong>Direito de confirmação e acesso (Art. 18, I e II):</strong> é
          o direito do usuário de obter do serviço a confirmação de que os dados
          pessoais que lhe digam respeito são ou não objeto de tratamento e, se
          for esse o caso, o direito de acessar os seus dados pessoais;
        </li>
        <li>
          <strong>Direito de retificação (Art. 18, III):</strong> é o direito de
          solicitar a correção de dados incompletos, inexatos ou desatualizados;
        </li>
        <li>
          <strong>
            Direito à limitação do tratamento dos dados (Art. 18, IV):
          </strong>{' '}
          é o direito do usuário de limitar o tratamento de seus dados pessoais,
          podendo exigir a eliminação de dados desnecessários, excessivos ou
          tratados em desconformidade com o disposto na Lei Geral de Proteção de
          Dados;
        </li>
        <li>
          <strong>Direito de oposição (Art. 18, § 2º):</strong> é o direito do
          usuário de, a qualquer momento, se opor ao tratamento de dados por
          motivos relacionados com a sua situação particular, com fundamento em
          uma das hipóteses de dispensa de consentimento ou em caso de
          descumprimento ao disposto na Lei Geral de Proteção de Dados;
        </li>
        <li>
          <strong>Direito de portabilidade dos dados (Art. 18, V):</strong> é o
          direito do usuário de realizar a portabilidade dos dados a outro
          fornecedor de serviço ou produto, mediante requisição expressa, de
          acordo com a regulamentação da autoridade nacional, observados os
          segredos comercial e industrial;
        </li>
        <li>
          <strong>
            Direito de não ser submetido a decisões automatizadas (Art. 20,
            LGPD):
          </strong>{' '}
          o titular dos dados tem direito a solicitar a revisão de decisões
          tomadas unicamente com base em tratamento automatizado de dados
          pessoais que afetem seus interesses, incluídas as decisões destinadas
          a definir o seu perfil pessoal, profissional, de consumo e de crédito
          ou os aspectos de sua personalidade.
        </li>
      </ul>

      <p>
        Segundo as políticas do campus, confere ao usuário os seguintes
        direitos:
      </p>

      <ul>
        <li>
          Recebimento de um cartão gratuito (primeira via) de acesso as
          dependências do campus, após apresentação de um documento
          comprobatório do seu vínculo a instituição e o comprovante vacinal de
          Covid-19;
        </li>
        <li>
          Em caso de defeito inerente ao cartão, uma segunda via poderá ser
          emitida gratuitamente mediante apresentação do cartão anterior;
        </li>
        <li>
          Em caso de roubo ou furto do cartão, uma segunda via poderá ser
          emitida gratuitamente mediante apresentação do Boletim de Ocorrência
          (BO);
        </li>
        <li>
          Em caso de perda ou extravio, o usuário poderá emitir uma segunda via
          do cartão por meio de uma taxa de R$ 8.00, a ser paga no Restaurante
          Acadêmico;
        </li>
        <li>
          Em caso de esquecimento do cartão, o usuário poderá adquirir o ticket
          de entrada no Restaurante Acadêmico, mediante explicação ao guichê de
          atendimento;
        </li>
        <li>
          O usuário poderá abastecer seu cartão com até cinquenta refeições;
        </li>
        <li>
          Por dia será permitido apenas um acesso do usuário ao Restaurante
          Acadêmico por cada refeição;
        </li>
      </ul>
    </>
  )
}

export default DireitosSection
