import { GiTurnstile as TransitoIcon } from 'react-icons/gi'

import { PageProps } from '@presentation/types/components/templates'
import { Page } from '@presentation/components/templates'
import { Col, Form, Ratio, Row } from 'react-bootstrap'

import { Input, Message, SelectApi } from '@presentation/components/molecules'
import {
  AvailablePessoaTipo,
  Pessoa,
  PessoaSituacaoTipo
} from '@core/models/entities'
import { KeyboardEventHandler, useState, useEffect } from 'react'
import { RestauranteService, AdministracaoService } from '@core/services'
import { NotFoundError, ValidationError } from '@core/models/errors'
import { useForm } from 'react-hook-form'
import { Alert, Audio, LocalStorage } from '@core/gateways'
import { formatDate, isNumeric } from '@presentation/utils'
import {
  loadPessoaById,
  loadPessoaByNumeroCartao
} from '@core/services/administracao'
import { ErrorAlertAudio } from '@presentation/assets/audio'

import * as S from './styles'
import { PessoaTransitoLanche } from '@core/types/services'

type FormInput = {
  nome: string
  identificacao: string
  tipo: string
  curso: string
}

const TransitoPage: React.FC = () => {
  const { register, reset } = useForm<FormInput>()

  const [selectedAluno, setSelectedAluno] = useState<Pessoa | null>()
  const [inputValue, setInputValue] = useState<string>()
  const [pessoa, setPessoa] = useState<PessoaTransitoLanche>()
  const [errors, setErrors] = useState<string[]>([])
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const [profilePhoto, setProfilePhoto] = useState<File>()
  const [transitoQuantity, setTransitoQuantity] = useState<number>()
  const [transitoDate, setTransitoDate] = useState<string>()
  const [canTransit, setCanTransit] = useState<boolean>()

  useEffect(() => {
    void loadTransitoStats()
  }, [])

  useEffect(() => {
    if (selectedAluno) {
      void handleAlunoTransito({ pessoaId: selectedAluno.id })
      if (pessoa) {
        void loadAlunoFotoByPessoaId(selectedAluno.id)
      }
    }
  }, [selectedAluno])

  useEffect(() => {
    if (pessoa) {
      void loadAlunoFotoByPessoaId(pessoa.pessoa_id.toString())

      reset({
        nome: pessoa.nome,
        identificacao: pessoa.identificacao.toString(),
        tipo: pessoa.pessoa_tipo,
        curso: pessoa.curso
      })
    }
  }, [pessoa])

  const loadTransitoStats = async () => {
    const data = await RestauranteService.obterTransitoStats()

    if (data) {
      setTransitoQuantity(data.quantidade_transitos_turno)
      setTransitoDate(formatDate(data.data))
    }
  }

  const loadAlunoFotoByPessoaId = async (id: string) => {
    const profilePhoto = await AdministracaoService.getProfilePhoto({
      id,
      token: LocalStorage.get({ key: 'accessToken' })
    })

    setProfilePhoto(profilePhoto)
  }

  const handleAlunoTransito = async (input: {
    pessoaId?: string
    numeroCartao?: string
  }) => {
    try {
      const data = await RestauranteService.autorizaTransitoLanche({
        pessoaId: input.pessoaId,
        numeroCartao: input.numeroCartao
      })

      setPessoa(data.pessoa)
      setCanTransit(true)
      setTransitoQuantity(data.quantidade_transitos_turno)
      setTransitoDate(formatDate(data.data))
      setErrors([])

      return true
    } catch (error) {
      console.log(error)
      if (error instanceof ValidationError) {
        void handleAluno(input)
        await handleErrors(error.errors)

        return true
      } else {
        void handleAluno(input)
        await handleErrors([(error as Error).message])
      }

      return false
    }
  }

  const handleErrors = async (errors: string[]) => {
    setCanTransit(false)
    setErrors(errors)
    await Audio.play({ filename: ErrorAlertAudio })
  }

  const mapPessoaToPessoaTransitoLanche = (pessoa: Pessoa) => {
    let identificacao = pessoa.identificacoes.find(
      identificacao =>
        (identificacao.pessoa_tipo_id === AvailablePessoaTipo.AlunoTecnico ||
          identificacao.pessoa_tipo_id ===
            AvailablePessoaTipo.AlunoIntegrado) &&
        (identificacao.situacao_id === PessoaSituacaoTipo.Matriculado ||
          identificacao.situacao_id === PessoaSituacaoTipo.Ativo)
    )

    if (identificacao === undefined) {
      identificacao = pessoa.identificacoes[0]
    }

    return {
      pessoa_id: parseInt(pessoa.id),
      nome: pessoa.nome,
      nome_social: pessoa.nome_social,
      curso: identificacao.aluno?.curso.nome,
      categoria: identificacao.categoria.descricao,
      identificacao: identificacao.identificacao,
      situacao: identificacao.situacao.descricao,
      pessoa_tipo: identificacao.pessoa_tipo.descricao
    }
  }

  const handleAluno = async ({
    pessoaId,
    numeroCartao
  }: {
    pessoaId?: string
    numeroCartao?: string
  }) => {
    try {
      if (pessoaId) {
        const data = await loadPessoaById({ id: pessoaId })
        setPessoa(mapPessoaToPessoaTransitoLanche(data))
      } else if (numeroCartao) {
        const data = await loadPessoaByNumeroCartao({
          numeroCartao: numeroCartao
        })
        setPessoa(mapPessoaToPessoaTransitoLanche(data))
      }
    } catch (error) {
      if (error instanceof NotFoundError) {
        Alert.callError({
          title: 'Aluno Não Encontrado',
          description: error.message
        })

        setPessoa(undefined)
        setProfilePhoto(undefined)
        setCanTransit(false)
        setErrors([])
        resetFields()
      }
    }
  }

  const makeAlunosPath = () => {
    let path = '/pessoas?'
    const acceptedTipoIds = [
      AvailablePessoaTipo.AlunoTecnico,
      AvailablePessoaTipo.AlunoIntegrado
    ]

    acceptedTipoIds.forEach(acceptedTipoId => {
      path = path + `tipo[]=${acceptedTipoId}` + '&'
    })
    path = path + 'situacao=1&sort_by=nome'

    return path
  }

  const onAlunoSelectChange = (selectedAluno: Pessoa) => {
    setSelectedAluno(selectedAluno)
  }

  const onAlunoSelectInputChange = (value: string) => {
    setInputValue(value)
  }

  const onAlunoSelectPressEnter: KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter' && inputValue && isNumeric(inputValue)) {
      void handleAlunoTransito({ numeroCartao: inputValue })
      resetAlunoSelect()
      setMenuIsOpen(false)
    }
  }

  const resetAlunoSelect = () => {
    setInputValue('')
    setSelectedAluno(null)
  }

  const resetFields = () => {
    reset({
      nome: '',
      identificacao: '',
      tipo: '',
      curso: ''
    })
  }

  const pageConfigs: PageProps = {
    title: 'Trânsito do Lanche',
    description: 'Página de controle do trânsito de lanche dos alunos.',
    icon: <TransitoIcon />,
    actions: [
      <S.SelectApiWrapper>
        <SelectApi
          path={makeAlunosPath()}
          value={selectedAluno}
          inputValue={inputValue}
          onInputChange={onAlunoSelectInputChange}
          onKeyDown={onAlunoSelectPressEnter}
          onChange={onAlunoSelectChange}
          autoFocus
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
        />
      </S.SelectApiWrapper>
    ]
  }

  return (
    <Page {...pageConfigs}>
      <Row className="mb-3">
        <Col>
          <S.PersonPicture>
            <Ratio aspectRatio={'1x1'} style={{ height: '100%' }}>
              <S.Picture
                src={
                  profilePhoto
                    ? URL.createObjectURL(profilePhoto)
                    : 'https://imgur.com/vcZ08Ql.png'
                }
              ></S.Picture>
            </Ratio>
          </S.PersonPicture>
        </Col>
        <Col>
          <Form>
            <Row className="mb-3">
              <Input name="nome" register={register} label="Nome" readOnly />
            </Row>
            <Row className="mb-3">
              <Input
                name="identificacao"
                register={register}
                label="Identificação"
                readOnly
              />
            </Row>
            <Row className="mb-3">
              <Input name="tipo" register={register} label="Tipo" readOnly />
            </Row>
            <Row className="mb-3">
              <Input name="curso" register={register} label="Curso" readOnly />
            </Row>
          </Form>
        </Col>
        <Col>
          <S.CardsContainer>
            <S.Card>
              <S.CardTitle>Número de Trânsitos</S.CardTitle>
              <S.CardContent>{transitoQuantity}</S.CardContent>
            </S.Card>
            <S.Card>
              <S.CardTitle>Data do Trânsito</S.CardTitle>
              <S.CardContent>{transitoDate}</S.CardContent>
            </S.Card>
          </S.CardsContainer>
        </Col>
      </Row>
      <Row>
        {errors.length > 0 && (
          <Message variant="danger">
            <S.MessageContent>
              <S.MessageTitle>Trânsito Não Autorizado!</S.MessageTitle>
              <S.MessageDescription>{errors[0]}</S.MessageDescription>
            </S.MessageContent>
          </Message>
        )}
        {canTransit && (
          <Message variant="success">
            <S.MessageContent>
              <S.MessageTitle>Trânsito Autorizado!</S.MessageTitle>
            </S.MessageContent>
          </Message>
        )}
      </Row>
    </Page>
  )
}

export default TransitoPage
