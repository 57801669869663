import { ReactivateModal } from '@presentation/components/templates'

type Props = {
  id: string
  onReactivate: () => void
}

const ReactivateLotacaoModal: React.FC<Props> = ({ id, onReactivate }) => {
  const modalConfigs = {
    id,
    path: '/lotacoes',
    nameForTitle: 'Lotacao',
    onReactivate
  }

  return <ReactivateModal {...modalConfigs} />
}

export default ReactivateLotacaoModal
