import { Yup } from '@core/proxies'

export const validationSchema = Yup.object().shape({
  tipo: Yup.mixed().required('O campo é obrigatório.'),
  identificacao: Yup.string().required('O campo é obrigatório.'),
  situacao: Yup.mixed().when('tipo', {
    is: (tipo: any) => (tipo ? !['visitante'].includes(tipo.attr) : false),
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  curso: Yup.mixed().when('tipo', {
    is: (tipo: any) =>
      tipo
        ? [
            'graduacao',
            'integrado',
            'tecnico',
            'mestrado',
            'extensao'
          ].includes(tipo.attr)
        : false,
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  categoria: Yup.mixed().when('tipo', {
    is: (tipo: any) =>
      tipo
        ? [
            'graduacao',
            'integrado',
            'tecnico',
            'servidor',
            'estagiario',
            'terceirizado'
          ].includes(tipo.attr)
        : false,
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  eixo: Yup.mixed().when(['tipo', 'categoria'], {
    is: (tipo: any, categoria: any) => {
      if (tipo && categoria) {
        if (categoria.id === 11) {
          return ['servidor'].includes(tipo.attr)
        }
      }

      return false
    },
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  cargo: Yup.mixed().when(['tipo', 'categoria'], {
    is: (tipo: any, categoria: any) => {
      if (tipo && categoria) {
        if (categoria.id === 12) {
          return ['servidor'].includes(tipo.attr)
        }
      }

      return false
    },
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  setor: Yup.mixed().when(['tipo', 'categoria'], {
    is: (tipo: any, categoria: any) => {
      if (tipo && categoria) {
        if (categoria.id !== 1) {
          return [
            'graduacao',
            'integrado',
            'tecnico',
            'servidor',
            'estagiario',
            'terceirizado'
          ].includes(tipo.attr)
        }
      }

      return false
    },
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  local: Yup.mixed().when(['tipo', 'categoria'], {
    is: (tipo: any, categoria: any) => {
      if (tipo) {
        if (categoria && categoria.id !== 1) {
          return ['servidor', 'estagiario', 'terceirizado'].includes(tipo.attr)
        }

        return ['mestrado'].includes(tipo.attr)
      }

      return false
    },
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  empresa: Yup.mixed().when(['tipo', 'categoria'], {
    is: (tipo: any, categoria: any) => {
      if (tipo && categoria) {
        if (categoria.id !== 1) {
          return ['terceirizado'].includes(tipo.attr)
        }
      }

      return false
    },
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  responsavel: Yup.mixed().when(['tipo'], {
    is: (tipo: any, categoria: any) => {
      if (tipo) {
        if (categoria && categoria.id !== 1) {
          return ['terceirizado'].includes(tipo.attr)
        }

        return ['mestrado', 'extensao'].includes(tipo.attr)
      }

      return false
    },
    then: Yup.mixed().required('O campo é obrigatório.')
  }),
  telefoneResponsavel: Yup.string().when(['tipo', 'categoria'], {
    is: (tipo: any, categoria: any) => {
      if (tipo && categoria) {
        if (categoria.id !== 1) {
          return ['terceirizado'].includes(tipo.attr)
        }
      }

      return false
    },
    then: Yup.string()
      .phone('Digite um telefone válido.')
      .max(20, 'O campo atingiu o limite máximo de 20 caracteres')
      .required('O campo é obrigatório.')
  }),
  motivo: Yup.string().when('tipo', {
    is: (tipo: any) => {
      if (tipo) {
        return ['visitante'].includes(tipo.attr)
      }

      return false
    },
    then: Yup.string()
      .max(255, 'O campo atingiu o limite máximo de 255 caracteres')
      .required('O campo é obrigatório.')
  }),
  dataInicio: Yup.string()
    .nullable()
    .when(['tipo', 'categoria'], {
      is: (tipo: any, categoria: any) => {
        if (tipo) {
          if (categoria && categoria.id !== 1) {
            return ['graduacao', 'integrado', 'tecnico', 'estagiario'].includes(
              tipo.attr
            )
          }

          return ['extensao', 'visitante'].includes(tipo.attr)
        }

        return false
      },
      then: Yup.string().required('O campo é obrigatório.')
    }),
  dataFim: Yup.string()
    .nullable()
    .when(['tipo', 'categoria'], {
      is: (tipo: any, categoria: any) => {
        if (tipo) {
          if (categoria && categoria.id !== 1) {
            return ['graduacao', 'integrado', 'tecnico', 'estagiario'].includes(
              tipo.attr
            )
          }
          return ['extensao', 'visitante'].includes(tipo.attr)
        }

        return false
      },
      then: Yup.string().required('O campo é obrigatório.')
    })
})
