import styled, { css } from 'styled-components'
import { math } from 'polished'

import { media } from '@presentation/styles'

type DashboardProps = {
  isCollapsible: boolean
}

export const Container = styled.div<DashboardProps>`
  ${({ theme, isCollapsible }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    float: right;

    transition: ${theme.transitions.default};

    ${media.greaterThan('mobile')`
      width: ${
        isCollapsible
          ? `calc(100% - ${theme.shared.sidebar.width})`
          : `calc(100% - ${theme.shared.sidebar.collapseWidth})`
      }
    `}
  `}
`

export const MainWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${math(
        `${theme.shared.navbar.height} + ${theme.shared.dashboard.spacing}`
      )}
      ${theme.shared.dashboard.spacing} ${theme.shared.dashboard.spacing}
      ${theme.shared.dashboard.spacing};
  `}
`
