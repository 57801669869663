import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { rgba } from 'polished'

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 14.375rem;
  box-shadow: inset 0px 11px 8px -14px rgba(0, 0, 0, 0.5),
    inset 0px -11px 8px -14px rgba(0, 0, 0, 0.5);
`

export const Item = styled(Dropdown.Item)`
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  color: ${props => rgba(props.theme.colors.textPrimary, 0.85)};

  &:hover {
    background-color: ${props => props.theme.colors.backgroundHover};
    color: ${props => props.theme.colors.textPrimary};
  }
`

export const Image = styled.img`
  border-radius: 50%;
  float: left;
  height: 2.25rem;
  width: 2.25rem;
  margin-right: 0.625rem;
`

export const Details = styled.span`
  display: flex;
  flex-direction: column;
`

export const Subject = styled.p`
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  font-size: 0.9rem;
  line-height: 1.3;
  -webkit-line-clamp: 2; /* add ... na quebra da segunda linha */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  visibility: visible;
  white-space: break-spaces;
  hyphens: auto;
  margin-bottom: 0.375rem;
`

export const Date = styled.small`
  display: block;
  font-size: 0.8rem;
  color: ${props => rgba(props.theme.colors.textPrimary, 0.8)};
`

export const NoData = styled.div`
  color: ${props => rgba(props.theme.colors.textPrimary, 0.85)};
  text-align: center;
  line-height: 6rem;
  font-size: 0.9rem;
  cursor: default;
  user-select: none;
`
