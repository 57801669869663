import { GrupoAcesso } from '@core/models/entities'
import {
  PessoaFormInput,
  PessoaIdentificacaoFormInput
} from '@presentation/types/components/organisms'
import { ObjectUtils } from '@presentation/utils'

export const mapRequest = (input: PessoaFormInput) => {
  const request: any = {
    foto: input.foto,
    nome: input.nome,
    nome_social: input.nomeSocial,
    nascimento: input.dataNascimento,
    identidade: input.rg,
    cpf: input.cpf,
    email_institucional: input.emailInstitucional,
    email: input.emailPessoal,
    celular: input.telefone,
    numero_cartao: input.numeroCartao,
    obs: input.observacoes,
    identificacoes: mapIdentificacoesRequest(input.identificacoes)
  }

  ObjectUtils.removeEmptyString(request)

  return request
}

export const mapIdentificacoesRequest = (
  identificacoesInput: PessoaIdentificacaoFormInput[]
) => {
  const identificacoesRequest = []

  for (const identificacaoInput of identificacoesInput) {
    const request = mapIdentificacaoRequest(identificacaoInput)
    identificacoesRequest.push(request)
  }

  return identificacoesRequest
}

export const mapIdentificacaoRequest = (
  identificacaoInput: PessoaIdentificacaoFormInput
) => {
  const isVisitante = identificacaoInput.tipo.attr === 'visitante'
  const request = {
    identificacao: identificacaoInput.identificacao,
    pessoa_tipo_id: identificacaoInput.tipo.id,
    situacao_id: isVisitante ? 5 : identificacaoInput.situacao.id,
    grupos_acesso: isVisitante
      ? []
      : mapGruposAcesso(identificacaoInput.gruposAcesso)
  }

  Object.assign(
    request,
    identificacaoInput.identificacaoId
      ? { id: identificacaoInput.identificacaoId }
      : {}
  ) // edit
  Object.assign(request, mapIdentificacaoTipoRequest(identificacaoInput))

  return request
}

const mapGruposAcesso = (gruposAcessoInput: GrupoAcesso[]) => {
  const onlyIds = gruposAcessoInput.map(grupoAcessoInput => grupoAcessoInput.id)

  return onlyIds
}

const mapIdentificacaoTipoRequest = (input: PessoaIdentificacaoFormInput) => {
  const attr = input.tipo.attr

  switch (attr) {
    case 'tecnico':
    case 'integrado':
    case 'graduacao':
      return mapTipoAlunoRequest(input)

    case 'mestrado':
      return mapTipoMestradoRequest(input)

    case 'extensao':
      return mapTipoExtensaoRequest(input)

    case 'estagiario':
      return mapTipoEstagiarioRequest(input)

    case 'servidor':
      return mapTipoServidorRequest(input)

    case 'terceirizado':
      return mapTipoTerceirizadoRequest(input)

    case 'visitante':
      return mapTipoVisitanteRequest(input)
  }
}

const mapTipoAlunoRequest = (input: PessoaIdentificacaoFormInput) => {
  const request = {
    id: input.tipoId,
    curso_id: input.curso.id
  }

  if (input.categoria.id !== 1) {
    Object.assign(request, {
      setor_id: input.setor.id,
      data_inicial: input.dataInicio,
      data_final: input.dataFim
    })
  }

  return {
    categoria_id: input.categoria.id,
    [input.tipo.attr]: request
  }
}

const mapTipoMestradoRequest = (input: PessoaIdentificacaoFormInput) => {
  const request = {
    id: input.tipoId,
    curso_id: input.curso.id,
    responsavel_id: input.responsavel.id,
    local_id: input.local.id
  }

  return {
    categoria_id: 1,
    mestrado: request
  }
}

const mapTipoExtensaoRequest = (input: PessoaIdentificacaoFormInput) => {
  const request = {
    id: input.tipoId,
    curso_id: input.curso.id,
    responsavel_id: input.responsavel.id,
    data_inicial: input.dataInicio,
    data_final: input.dataFim
  }

  return {
    extensao: request
  }
}

const mapTipoEstagiarioRequest = (input: PessoaIdentificacaoFormInput) => {
  const request = {
    id: input.tipoId,
    setor_id: input.setor.id,
    local_id: input.local.id,
    data_inicial: input.dataInicio,
    data_final: input.dataFim
  }

  return {
    categoria_id: input.categoria.id,
    estagiario: request
  }
}

const mapTipoServidorRequest = (input: PessoaIdentificacaoFormInput) => {
  const categoriaId = input.categoria.id

  const request = {
    id: input.tipoId,
    setor_id: input.setor.id,
    local_id: input.local.id,
    funcao_id: input.funcao?.id
  }

  if (categoriaId === 11) {
    Object.assign(request, {
      eixo_id: input.eixo.id,
      cargo_id: 1
    })
  } else if (categoriaId === 12) {
    Object.assign(request, {
      cargo_id: input.cargo.id
    })
  }

  return {
    categoria_id: input.categoria.id,
    servidor: request
  }
}

const mapTipoVisitanteRequest = (input: PessoaIdentificacaoFormInput) => {
  const request = {
    id: input.tipoId,
    responsavel_id: input.responsavel.id,
    data_inicial: input.dataInicio,
    data_final: input.dataFim,
    motivo: input.motivo
  }

  return {
    visitante: request
  }
}

const mapTipoTerceirizadoRequest = (input: PessoaIdentificacaoFormInput) => {
  const request = {
    id: input.tipoId,
    responsavel_id: input.responsavel.id,
    setor_id: input.setor.id,
    local_id: input.local.id,
    cargo_id: input.cargo.id,
    empresa_id: input.empresa.id,
    contato_responsavel: input.telefoneResponsavel
  }

  return {
    categoria_id: input.categoria.id,
    terceirizado: request
  }
}
