import { Col, Form, Row } from 'react-bootstrap'

import { Input, SelectApi } from '@presentation/components/molecules'
import { useForm } from '@presentation/hooks'

const SetorForm: React.FC = () => {
  const { register, control, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Col md>
          <Input
            label="Nome"
            name="nome"
            register={register}
            error={formState?.errors.nome?.message}
            required
            type="text"
            placeholder="Digite o nome do setor"
            autoComplete="off"
          />
        </Col>

        <Col md>
          <Input
            label="Email"
            name="email"
            register={register}
            error={formState?.errors.email?.message}
            required
            type="email"
            placeholder="Digite o email do setor"
            autoComplete="off"
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md>
          <SelectApi
            name="lotacao"
            path="/lotacoes"
            control={control}
            placeholder="Selecione aqui"
            label="Lotação"
            isRequired
          />
        </Col>

        <Col md>
          <SelectApi
            name="local"
            control={control}
            path="/locais"
            placeholder="Selecione aqui"
            label="Local"
            isRequired
          />
        </Col>
      </Row>

      <Row>
        <Col md>
          <Input
            as="textarea"
            label="Observações"
            name="observacoes"
            register={register}
            placeholder="Escreva aqui as observações do setor"
            error={formState?.errors.observacoes?.message}
            style={{ height: '120px' }}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default SetorForm
