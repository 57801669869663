import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import { FiUploadCloud } from 'react-icons/fi'

type ContainerProps = {
  isActive: boolean
}

export const Container = styled.div<ContainerProps>`
  ${({ isActive, theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed
      ${isActive ? theme.colors.secondary : theme.colors.primary};
    border-radius: 7px;
    min-height: 13rem;
    padding: 2rem 0;
  `}
`

export const FileFrame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 12.5rem;
  height: 15.625rem;

  background: ${props => props.theme.colors.background};
  border-radius: px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
`

export const FileAction = styled.div`
  position: absolute;
  right: 4px;
  top: 0;
  height: 2rem;
  padding: 0.25rem;

  svg {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    font-size: 1.25rem;

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
`

export const FileViewer = styled.div`
  height: 11.625rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 auto;
    width: 90%;
    max-height: 11.625rem;
  }

  canvas {
    margin: 0 auto;
    width: 7.5rem !important;
    max-height: 11.625rem !important;
  }
`

export const FileInfo = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2rem;
  width: 100%;

  small {
    padding: 0.25rem;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.375rem 0.5rem;
  }
`

export const DragMessage = styled.small`
  color: ${props => props.theme.colors.primary};
`

export const DropMessage = styled.span`
  color: ${props => props.theme.colors.secondary};
`

export const UploadIcon = styled(FiUploadCloud)`
  font-size: 5rem;
  color: ${props => props.theme.colors.secondary};
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
`

export const Animated = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
