import { PageProps } from '@presentation/types/components/templates'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import * as S from './styles'

const Header: React.FC<PageProps> = ({
  title,
  description,
  icon,
  isLoading,
  actions
}) => {
  return (
    <S.Header>
      <S.IconWrapper>
        {isLoading ? <Skeleton width={60} height={60} /> : icon}
      </S.IconWrapper>

      <S.HeadingWrapper>
        <S.Title>{isLoading ? <Skeleton width={250} /> : title}</S.Title>

        <S.Description>
          {isLoading ? <Skeleton width={250} /> : description}
        </S.Description>
      </S.HeadingWrapper>

      <S.ButtonGroup>
        {isLoading ? (
          <Skeleton width={100} height={30} />
        ) : (
          <>
            {actions && (
              <>
                {actions.map((action, index) => (
                  <span key={index}>{action}</span>
                ))}
              </>
            )}
          </>
        )}
      </S.ButtonGroup>
    </S.Header>
  )
}

export default Header
