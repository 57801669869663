import { Filter } from '@presentation/types/components/organisms'

export const getStatusFilter = (): Filter => {
  return {
    type: {
      value: 'status',
      label: 'Status'
    },
    description: {
      type: 'select',
      configs: {
        options: [
          { label: 'Ativo', value: '1' },
          { label: 'Inativo', value: '0' }
        ]
      }
    }
  }
}
