import { LegacyRef, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Document, Page } from 'react-pdf'

import * as S from './styles'

type Props = {
  file: File
}

const PDFViewer: React.FC<Props> = ({ file }) => {
  const canvasRef = useRef<HTMLCanvasElement>()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pagesQuantity, setPagesQuantity] = useState<number>(0)

  const canPreviousPage = currentPage > 1
  const canNextPage = currentPage < pagesQuantity

  const previousPage = () => {
    if (canPreviousPage) {
      setCurrentPage(currentPage - 1)
    }
  }

  const nextPage = () => {
    if (canNextPage) {
      setCurrentPage(currentPage + 1)
    }
  }

  const onLoadSuccess = ({ numPages }: any): void => {
    setPagesQuantity(numPages)
  }

  return (
    <S.Container>
      <S.Toolbar>
        <S.ButtonGroup>
          <Button onClick={previousPage} disabled={!canPreviousPage}>
            &laquo; Voltar
          </Button>
          <Button onClick={nextPage} disabled={!canNextPage}>
            Próximo &raquo;
          </Button>
        </S.ButtonGroup>
        <span>
          Página {currentPage} de {pagesQuantity}
        </span>
      </S.Toolbar>

      <Document
        file={file}
        loading={'Carregando PDF...'}
        error={'Não foi possível carregar o PDF.'}
        noData={'Nenhum PDF encontrado.'}
        onLoadSuccess={onLoadSuccess}
      >
        <Page
          canvasRef={canvasRef as LegacyRef<HTMLCanvasElement>}
          pageNumber={currentPage}
          renderAnnotationLayer={false}
        />
      </Document>
    </S.Container>
  )
}

export default PDFViewer
