import { LotacaoForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  id: string
  onEdit: () => void
}

const EditLotacaoModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    path: '/lotacoes',
    formId: 'edit-lotacao',
    nameForTitle: 'Lotação',
    validationSchema,
    mapData: data => ({
      nome: data.nome,
      responsavel: data.responsavel.pessoa,
      email: data.email,
      observacoes: data.observacoes
    }),
    mapRequest: data => ({
      nome: data.nome,
      responsavel_id: data.responsavel.id,
      email: data.email,
      observacoes: data.observacoes
    }),
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <LotacaoForm />
    </EditModal>
  )
}

export default EditLotacaoModal
