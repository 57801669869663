import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'

export const Footer = styled(Dropdown.Item)`
  margin-top: 0.25rem;
  padding: 0.625rem 1.25rem;
  text-align: center;
  color: ${props => props.theme.colors.textPrimary};

  :hover {
    font-weight: 700;
    color: ${props => props.theme.colors.textPrimary};
    background-color: ${props => props.theme.colors.backgroundHover};
  }

  :active {
    background-color: #e9ecef;
  }
`
