import {
  GrupoAcesso,
  GrupoAcessoPermissao,
  PessoaTipo
} from '@core/models/entities'
import { GrupoAcessoForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from '../validation'

type Props = {
  id: number
  onEdit: () => void
}

const EditGrupoAcessoModal: React.FC<Props> = ({ id, onEdit }) => {
  // É necessário alocar a permissão na posição do id dela
  const getPermissoesData = (permissoes: GrupoAcessoPermissao[]) => {
    const data: any[] = []
    permissoes.forEach(permissao => {
      data[permissao.id] = {
        value: permissao.tipo.toString()
      }
    })

    return data
  }

  const mapData = (data: GrupoAcesso) => {
    return {
      nome: data.nome,
      descricao: data.descricao,
      pessoaTipos: data.pessoas_tipo,
      permissoes: getPermissoesData(data.permissoes)
    }
  }

  const mapRequest = (data: any) => {
    return {
      nome: data.nome,
      descricao: data.descricao,
      pessoas_tipo: data.pessoaTipos.map(
        (pessoaTipo: PessoaTipo) => pessoaTipo.id
      ),
      permissoes: data.permissoes
        .map((permissao: { value: string }, index: number) => {
          return {
            permissao_id: index,
            tipo: permissao.value
          }
        })
        .filter((item: any) => item.tipo !== undefined)
    }
  }

  const modalConfigs: EditModalProps = {
    id,
    path: '/grupos-acesso',
    formId: 'edit-grupo-acesso',
    nameForTitle: 'Grupo de Acesso',
    validationSchema,
    mapData: mapData,
    mapRequest: mapRequest,
    size: 'xl',
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <GrupoAcessoForm />
    </EditModal>
  )
}

export default EditGrupoAcessoModal
