import { CursoForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from '../validation'

type Props = {
  onAdd: () => void
}

const AddCursoModal: React.FC<Props> = ({ onAdd }) => {
  const modalConfigs: AddModalProps = {
    formId: 'add-curso',
    nameForTitle: 'Curso',
    path: '/cursos',
    validationSchema,
    mapRequest: data => ({
      nome: data.nome,
      curso_tipo_id: data.cursoTipo.id,
      eixo_id: data.eixo.id,
      externo: data.externo
    }),
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <CursoForm />
    </AddModal>
  )
}

export default AddCursoModal
