import { memo, useEffect, useState } from 'react'

import { useLinksRapidos } from '@presentation/hooks'
import { fileToBase64 } from '@presentation/utils'

import * as S from './styles'

type Props = {
  linkId: number
  path: string
  title: string
}

const HomePage: React.FC<Props> = ({ linkId, path, title }) => {
  const linksRapidos = useLinksRapidos()
  const [imageBase64, setImageBase64] = useState<string>()

  useEffect(() => {
    const loadImage = async () => {
      const image = await linksRapidos.loadImageById(linkId)
      const imageBase64 = await fileToBase64(image)
      setImageBase64(imageBase64)
    }

    void loadImage()
  }, [])

  return (
    <S.Container href={path} target="_blank">
      <S.Image src={imageBase64} />
      <S.Title>{title}</S.Title>
    </S.Container>
  )
}

export default memo(HomePage)
