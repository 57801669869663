import { memo } from 'react'
import { MdHelpOutline as HelpIcon } from 'react-icons/md'

import { useModal } from '@presentation/hooks'
import { Quote } from '@presentation/components/atoms'
import { Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'

import * as S from './styles'

const CannotAccess: React.FC = () => {
  const { isOpen, closeModal, openModal } = useModal()

  const modalConfigs: ModalProps = {
    isOpen,
    icon: <HelpIcon />,
    title: 'Acesso ao Intranet',
    onClose: closeModal
  }

  return (
    <>
      <S.Link onClick={() => openModal()}>Não consegue acessar?</S.Link>
      <Modal {...modalConfigs}>
        <S.Text>
          <p>
            Segundo a seção 'Acesso a Plataforma' presente nos{' '}
            <a href="/termos-de-uso" target={'_blank'}>
              termos de uso
            </a>
            :
          </p>

          <Quote>
            Acesso a plataforma é restrito apenas aos usuários previamente
            cadastrados no sistema (alunos, servidores e visitantes).
          </Quote>

          <p>
            Mediante isso, considera-se usuário aquele que possui um cadastro
            realizado na Coordenação de Tecnologia da Informação (CTI) do
            campus. Caso você seja aluno ingresso durante a pandemia, já
            realizamos seu cadastro prévio.
          </p>

          <p>Sobre a identificação de cada tipo de pessoa do campus, temos:</p>

          <ul>
            <li>
              <strong>Usuário dos servidores:</strong> matrícula SIAPE;
            </li>
            <li>
              <strong>Usuário dos alunos:</strong> matrícula do curso;
            </li>
            <li>
              <strong>Usuário dos terceirizados:</strong> CPF.
            </li>
          </ul>
        </S.Text>
      </Modal>
    </>
  )
}

export default memo(CannotAccess)
