import styled from 'styled-components'
import { Button as BsButton } from 'react-bootstrap'

export const Button = styled(BsButton)`
  position: fixed;
  right: 30px;
  bottom: 85px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.65rem;
  z-index: ${props => props.theme.indexes.high};
  cursor: pointer;
  opacity: 0.6;

  &:hover {
    opacity: 0.9;
  }
`
