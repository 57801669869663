import { Dispatch, SetStateAction, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Cropper from 'react-easy-crop'

import { useCrop, useMultiModal } from '@presentation/hooks'
import { Slider } from '@presentation/components/molecules'

import * as S from './styles'

type Props = {
  image: string
  onImageChange: Dispatch<SetStateAction<File | undefined>>
  onAfterCrop: () => void
}

const CropperStepModal: React.FC<Props> = ({
  image,
  onImageChange,
  onAfterCrop
}) => {
  const { previousStep, onActionsChange } = useMultiModal()
  const { croppedImage, cropImage, ...cropperConfigs } = useCrop({
    image
  })

  useEffect(() => {
    onActionsChange([
      <Button onClick={previousStep}>Voltar</Button>,
      <Button onClick={onSave}>Salvar</Button>
    ])
  }, [])

  useEffect(() => {
    if (croppedImage) {
      onImageChange(croppedImage)
    }
  }, [croppedImage])

  const onSave = () => {
    cropImage()
    onAfterCrop()
  }

  return (
    <>
      <S.CropperWrapper>
        <Cropper image={image} aspect={1} {...cropperConfigs} />
      </S.CropperWrapper>

      <S.SliderWrapper>
        <Slider
          value={cropperConfigs.zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={value => cropperConfigs.onZoomChange(value)}
        />
      </S.SliderWrapper>
    </>
  )
}

export default CropperStepModal
