import { Variant } from 'react-bootstrap/esm/types'

import { ComprovanteStatus } from '@core/types/services'

import * as S from './styles'

type Props = {
  status: ComprovanteStatus
  variant: Variant
}

const StatusTag: React.FC<Props> = ({ status, variant }) => {
  return <S.Container bg={variant}>{status}</S.Container>
}

export default StatusTag
