import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPlus as AddIcon } from 'react-icons/fa'

import { Alert } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { useModal } from '@presentation/hooks'
import { UnexpectedError, ValidationError } from '@core/models/errors'
import { Modal } from '@presentation/components/organisms'
import {
  ErrorList,
  ActionButton,
  SubmitButton
} from '@presentation/components/molecules'
import { ModalProps } from '@presentation/types/components/organisms'
import { AddModalProps } from '@presentation/types/components/templates'
import { FormContext } from '@presentation/contexts'

const AddModal: React.FC<AddModalProps> = ({
  path,
  formId,
  nameForTitle,
  validationSchema,
  mapRequest,
  onAdd,
  size,
  children
}) => {
  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const { isOpen, openModal, closeModal } = useModal()
  const [apiErrors, setApiErrors] = useState<string[]>([])

  const clearApiErrors = () => {
    setApiErrors([])
  }

  const onSuccess = () => {
    formMethods.reset()
    closeModal()
    if (onAdd) onAdd()

    Alert.callSuccess({
      title: 'Cadastrado com Sucesso'
    })
  }

  const onError = (error: Error) => {
    formMethods.reset()
    closeModal()
    Alert.callError({
      title: 'Falha de Cadastro',
      description: error.message
    })
  }

  const onSubmit: SubmitHandler<any> = async input => {
    try {
      await AdministracaoService.add({
        data: mapRequest ? mapRequest(input) : input,
        path
      })
      onSuccess()
    } catch (error) {
      if (error instanceof ValidationError) {
        setApiErrors(error.errors)
      }

      if (error instanceof UnexpectedError) {
        onError(error)
      }
    }
  }

  const buttonActions = [
    <SubmitButton
      form={formId}
      isValid={formMethods.formState.isValid}
      isSubmitting={formMethods.formState.isSubmitting}
    >
      Salvar
    </SubmitButton>
  ]
  const configs: ModalProps = {
    isOpen,
    icon: <AddIcon />,
    title: `Cadastro de ${nameForTitle}`,
    description: `Use o formulário abaixo para cadastrar um novo recurso em nosso banco de dados.`,
    actions: buttonActions,
    size: size,
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="add" onClick={openModal} />

      {isOpen && (
        <Modal {...configs}>
          {apiErrors.length > 0 && (
            <ErrorList errors={apiErrors} onHide={clearApiErrors} />
          )}
          <FormContext.Provider
            value={{
              formId,
              onSubmit: formMethods.handleSubmit(onSubmit),
              ...formMethods
            }}
          >
            {children}
          </FormContext.Provider>
        </Modal>
      )}
    </>
  )
}

export default AddModal
