import { useForm } from '@presentation/hooks'
import { TextInput } from '@presentation/components/molecules'

const MotivoInput: React.FC = () => {
  const { register, formState } = useForm()

  return (
    <TextInput
      label="Motivo"
      name="motivo"
      register={register}
      error={formState?.errors.motivo?.message}
      placeholder="Escreva o motivo da visita"
      required
    />
  )
}

export default MotivoInput
