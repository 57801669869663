import styled from 'styled-components'

export const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`

export const Text = styled.div`
  text-align: justify;

  ul {
    line-height: 1.5;
    display: block;
    list-style-type: disc;
    margin-block-start: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 2.188rem;

    li {
      margin-bottom: 0.5rem;
    }

    li:last-child {
      margin-bottom: 0rem;
    }

    ul {
      list-style-type: circle;
    }
  }
`
