import { ReactivateModal } from '@presentation/components/templates'
import { ReactivateModalProps } from '@presentation/types/components/templates'

type Props = {
  id: number
  onReactivate: () => void
}

const ReactivateGrupoAcessoModal: React.FC<Props> = ({ id, onReactivate }) => {
  const modalConfigs: ReactivateModalProps = {
    id,
    path: '/grupos-acesso',
    nameForTitle: 'Grupo de Acesso',
    onReactivate
  }

  return <ReactivateModal {...modalConfigs} />
}

export default ReactivateGrupoAcessoModal
