import { PessoaIdentificacao } from '@core/models/entities'

import * as S from './styles'

type Props = {
  items: Array<Omit<PessoaIdentificacao, 'pessoa'>>
}

const PessoaIdentificacoesCell: React.FC<Props> = ({ items }) => {
  const formattedIdentificacoes = items.map(item => {
    return `${item.pessoa_tipo?.descricao} - ${item.identificacao} - ${item.situacao?.descricao}`
  })

  return (
    <S.Container>
      {formattedIdentificacoes.map(formattedIdentificacao => (
        <div>{formattedIdentificacao}</div>
      ))}
    </S.Container>
  )
}

export default PessoaIdentificacoesCell
