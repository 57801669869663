import * as S from './styles'

type Props = {
  items: string[]
}

const RowsOnCell: React.FC<Props> = ({ items }) => {
  return (
    <S.Container>{items.map(item => item && <div>{item}</div>)}</S.Container>
  )
}

export default RowsOnCell
