import { LocalStorage } from '@core/gateways'
import { useState } from 'react'

export const useAccessToken = () => {
  const getAccessToken = () => {
    const accessToken = LocalStorage.get({
      key: 'accessToken'
    })
    return accessToken
  }

  const [accessToken, setAccessToken] = useState(getAccessToken())

  const saveAccessToken = (accessToken: string) => {
    LocalStorage.set({
      key: 'accessToken',
      value: accessToken
    })
    setAccessToken(accessToken)
  }

  return {
    setAccessToken: saveAccessToken,
    accessToken
  }
}
