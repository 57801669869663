import { Button, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap'

import { actionButtons } from '@presentation/configs'
import { ActionButtonProps } from '@presentation/types/components/molecules'

import * as S from './styles'

const ActionButton: React.FC<ActionButtonProps> = ({ type, onClick, size }) => {
  const actionInfo = actionButtons[type]

  const renderTooltip = (props: TooltipProps) => {
    return actionInfo.tooltipText ? (
      <Tooltip {...props}>{actionInfo.tooltipText}</Tooltip>
    ) : (
      <></>
    )
  }

  return (
    <S.Container>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <Button variant={actionInfo.variant} size={size} onClick={onClick}>
          {actionInfo.content}
        </Button>
      </OverlayTrigger>
    </S.Container>
  )
}

export default ActionButton
