import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'

export const Container = styled(Dropdown.Toggle)`
  font-size: 1.6rem;
  color: ${props => props.theme.colors.white};
  padding: 0rem 1rem;

  :after {
    display: none; /* remove o ícone de seta do dropdown */
  }

  :focus,
  :hover,
  :active {
    box-shadow: none !important;
    color: white;
  }
`
