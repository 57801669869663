import { ResponseType } from 'axios'

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  serverError = 500
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export interface HttpClient {
  request: (input: HttpClient.Input) => Promise<HttpClient.Output>
}

export namespace HttpClient {
  export type Input = {
    url: string
    method: HttpMethod
    params?: any
    body?: any
    headers?: any
    responseType?: ResponseType
  }

  export type Output<T = any> = {
    statusCode: HttpStatusCode
    body?: T
    headers?: any
  }
}
