import { Ratio } from 'react-bootstrap'

import { Pessoa } from '@core/models/entities'

import * as S from './styles'
import { ProfileDetailsItem } from './components'

export type ProfileDetailsProps = Partial<
  Pick<Pessoa, 'id' | 'nome' | 'email'> & {
    tipo: string
    identificacao: string
    photo: File
  }
>

const ProfileDetails: React.FC<ProfileDetailsProps> = pessoa => {
  console.log(pessoa)
  return (
    <>
      <S.PersonPicture>
        <Ratio aspectRatio={'1x1'} style={{ height: '100%' }}>
          <S.Picture
            src={
              pessoa.photo
                ? URL.createObjectURL(pessoa.photo)
                : 'https://imgur.com/vcZ08Ql.png'
            }
          ></S.Picture>
        </Ratio>
      </S.PersonPicture>

      <hr></hr>

      <>
        <ProfileDetailsItem
          label="Identificação"
          value={pessoa.identificacao}
        />
        <ProfileDetailsItem label="Nome" value={pessoa.nome} />
        <ProfileDetailsItem label="Tipo" value={pessoa.tipo} />
        <ProfileDetailsItem label="Email" value={pessoa.email} />
      </>
    </>
  )
}

export default ProfileDetails
