import { FaBell as BellIcon } from 'react-icons/fa'

import * as S from './styles'

const NotificationDropdownToggle: React.FC = () => {
  return (
    <S.Container variant="transparent">
      <BellIcon />
    </S.Container>
  )
}

export default NotificationDropdownToggle
