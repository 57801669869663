import { useForm } from '@presentation/hooks'
import { DateInput } from '@presentation/components/molecules'

const DataInicioInput: React.FC = () => {
  const { register, formState, watch } = useForm()

  const endDate = watch('dataFim')

  return (
    <DateInput
      label="Data de Início"
      name="dataInicio"
      register={register}
      error={formState?.errors.dataInicio?.message}
      placeholder="Escolha a data de início"
      max={endDate}
      required
    />
  )
}

export default DataInicioInput
