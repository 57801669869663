import { useContext, useState } from 'react'

import { UseModal } from '@presentation/types/hooks'
import { MultiModalContext } from '@presentation/contexts'

export const useModal: UseModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    openModal,
    closeModal
  }
}

export const useMultiModal = () => {
  const multiModal = useContext(MultiModalContext)

  return multiModal
}
