import { env } from '@main/configs'
import {
  IfceMaracanauEntradaImage,
  IfceMaracanauLogoImage
} from '@presentation/assets/images'
import { Title } from '@presentation/components/atoms'

import * as S from './styles'

type Props = {
  title: string
}

const AuthPage: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <Title title={title} />

      <S.Background
        src={IfceMaracanauEntradaImage}
        alt="Entrada do IFCE campus Maracanaú"
      />

      <S.Container>
        <S.Header>
          <S.IfceLogoWrapper>
            <S.IfceLogo src={IfceMaracanauLogoImage} />
          </S.IfceLogoWrapper>

          <S.SystemLogoWrapper>
            {env.app.logo.colorful}
            <S.SystemName>{process.env.REACT_APP_NAME}</S.SystemName>
          </S.SystemLogoWrapper>

          <S.Divider />
        </S.Header>

        <S.Title>{title}</S.Title>

        <S.Content>{children}</S.Content>
      </S.Container>
    </>
  )
}

export default AuthPage
