import { useResponsive, useDashboard } from '@presentation/hooks'
import { Profile } from '@presentation/components/molecules'

import * as S from './sidebar-header.styles'

const SidebarHeader = () => {
  const { isOpenSidebar } = useDashboard()
  const { isMobile } = useResponsive()

  return (
    <S.Container>
      {isMobile ? (
        <Profile variant="secondary" />
      ) : (
        <S.Brand variant={isOpenSidebar ? 'full' : 'icon'} />
      )}
    </S.Container>
  )
}

export default SidebarHeader
