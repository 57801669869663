import { Dropdown } from 'react-bootstrap'

import {
  NotificationDropdownContent,
  NotificationDropdownFooter,
  NotificationDropdownHeader,
  NotificationDropdownToggle
} from './components'
import * as S from './styles'

const NavbarDropdownNotification: React.FC = () => {
  const notifications: any[] = []

  return (
    <Dropdown>
      <NotificationDropdownToggle />
      <S.Menu variant="transparent">
        <NotificationDropdownHeader />
        <NotificationDropdownContent items={notifications} />
        <NotificationDropdownFooter />
      </S.Menu>
    </Dropdown>
  )
}

export default NavbarDropdownNotification
