import { FaMapMarkerAlt as LocaisIcon } from 'react-icons/fa'

import { Local, PermissaoTipo } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import {
  AddLocalModal,
  DeleteLocalModal,
  EditLocalModal,
  ReactivateLocalModal
} from './components'

const LocaisPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/locais',
    options: { sortBy: 'nome' },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <LocaisIcon />,
    title: 'Locais',
    description: 'Página de gerenciamento de locais do campus.',
    actions: hasPermission(PermissaoTipo.Escrita)
      ? [<AddLocalModal onAdd={fetchData} />]
      : []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'tipo',
        label: 'Tipo'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/tipos-local',
          isMulti: true
        }
      }
    },
    {
      type: {
        value: 'bloco_id',
        label: 'Bloco'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/blocos',
          isMulti: true
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      status: 1
    }
  }

  const columns: Array<TableColumn<Local>> = [
    {
      name: 'Nome',
      selector: local => local.nome,
      sortable: true,
      center: true
    },
    {
      name: 'Tipo',
      selector: local => local.tipo_local?.nome ?? '',
      sortable: true,
      center: true
    },
    {
      name: 'Bloco',
      selector: local => local.bloco?.nome ?? '',
      sortable: true,
      center: true
    },
    {
      name: 'Ações',
      cell: local => (
        <>
          {local.status ? (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <EditLocalModal id={local.id} onEdit={fetchData} />
              )}
              {hasPermission(PermissaoTipo.Remocao) && (
                <DeleteLocalModal id={local.id} onDelete={fetchData} />
              )}
            </>
          ) : (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <ReactivateLocalModal id={local.id} onReactivate={fetchData} />
              )}
            </>
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default LocaisPage
