import { ComponentType } from 'react'

import * as S from './styles'

type Props = {
  title?: string
  icon?: ComponentType
}

const Card: React.FC<Props> = ({ title, icon: Icon, children }) => {
  return (
    <S.Card>
      {title && (
        <S.CardHeader>
          <S.CardHeaderTitle>
            {Icon && (
              <S.CardHeaderIconWrapper>
                <Icon />
              </S.CardHeaderIconWrapper>
            )}
            {title}
          </S.CardHeaderTitle>
        </S.CardHeader>
      )}
      <S.CardBody>{children}</S.CardBody>
    </S.Card>
  )
}

export default Card
