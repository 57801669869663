import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ProtectedRoute } from '@main/proxies'

import {
  // HomePage
  ComprovantePage,
  NaoEnviadosPage,
  ValidacaoPage,
  AddPessoaPage,
  BlocosPage,
  CargosPage,
  CursosPage,
  EditPessoaPage,
  EixosPage,
  FeriadosPage,
  FuncoesPage,
  LocaisPage,
  LotacoesPage,
  PessoasPage,
  SetoresPage,
  TiposLocalPage,
  LoginPage,
  TermosUsoPage,
  NotFoundPage,
  GruposAcessoPage,
  HomePage,
  TransitoCatracaPage,
  GestaoQualidadePage
} from '@presentation/pages'
import { MainLayout } from '@presentation/components/templates'
import { EmailAcademicoPage } from '@presentation/pages/ajuda'
import { TransitoPage } from '@presentation/pages/restaurante'

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="termos-de-uso" element={<TermosUsoPage />} />
        <Route path="pagina-nao-encontrada" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />

        <Route path="/" element={<MainLayout />}>
          <Route index element={<ProtectedRoute component={HomePage} />} />

          <Route
            path="/pgq"
            element={<ProtectedRoute component={GestaoQualidadePage} />}
          />

          <Route path="vacina">
            <Route
              path="envio-comprovante"
              element={<ProtectedRoute component={ComprovantePage} />}
            />

            <Route
              path="validacao"
              element={<ProtectedRoute component={ValidacaoPage} />}
            />

            <Route
              path="nao-enviados"
              element={<ProtectedRoute component={NaoEnviadosPage} />}
            />
          </Route>

          <Route path="administracao">
            <Route path="pessoas">
              <Route
                index
                element={<ProtectedRoute component={PessoasPage} />}
              />

              <Route
                path="cadastro"
                element={<ProtectedRoute component={AddPessoaPage} />}
              />

              <Route
                path=":id"
                element={<ProtectedRoute component={EditPessoaPage} />}
              />
            </Route>

            <Route
              path="grupos-acesso"
              element={<ProtectedRoute component={GruposAcessoPage} />}
            />

            <Route
              path="blocos"
              element={<ProtectedRoute component={BlocosPage} />}
            />

            <Route
              path="cargos"
              element={<ProtectedRoute component={CargosPage} />}
            />

            <Route
              path="eixos"
              element={<ProtectedRoute component={EixosPage} />}
            />

            <Route
              path="lotacoes"
              element={<ProtectedRoute component={LotacoesPage} />}
            />

            <Route
              path="funcoes"
              element={<ProtectedRoute component={FuncoesPage} />}
            />

            <Route
              path="setores"
              element={<ProtectedRoute component={SetoresPage} />}
            />

            <Route
              path="locais"
              element={<ProtectedRoute component={LocaisPage} />}
            />

            <Route
              path="tipos-local"
              element={<ProtectedRoute component={TiposLocalPage} />}
            />

            <Route
              path="cursos"
              element={<ProtectedRoute component={CursosPage} />}
            />

            <Route
              path="feriados"
              element={<ProtectedRoute component={FeriadosPage} />}
            />
          </Route>

          <Route path="recepcao">
            <Route
              path="transito-catraca"
              element={<TransitoCatracaPage />}
            ></Route>
          </Route>
          <Route path="restaurante">
            <Route
              path="transito-lanche"
              element={<ProtectedRoute component={TransitoPage} />}
            ></Route>
          </Route>

          <Route path="ajuda">
            <Route
              path="email-academico"
              element={<ProtectedRoute component={EmailAcademicoPage} />}
            ></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
