import { useCallback, useRef, useState } from 'react'
import Webcam from 'react-webcam'

export const useCamera = () => {
  const webcamRef = useRef<Webcam>(null)
  const [imageUrl, setImageUrl] = useState<string>()

  const capturePicture = useCallback(() => {
    if (webcamRef.current) {
      const screenshot = webcamRef.current.getScreenshot()

      if (screenshot) {
        setImageUrl(screenshot)
      }
    }
  }, [webcamRef])

  return {
    webcamRef,
    imageUrl,
    capturePicture
  }
}
