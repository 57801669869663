import queryString from 'query-string'

export const toTitleCase = (value: string) => {
  return value
    .toLowerCase()
    .replace(/(^|\s)\S/g, firstLetter => firstLetter.toUpperCase()) // https://medium.com/free-code-camp/title-case-a-sentence-in-javascript-929012c88574
}

export const formatProfileName = (name: string) => {
  const splitOnSpace = name.split(' ')

  const firstName = splitOnSpace[0]
  const lastName = splitOnSpace[splitOnSpace.length - 1]

  return toTitleCase(`${firstName} ${lastName}`)
}

export const parseToRequest = (params: any) => {
  const queryUrl = new URLSearchParams(params).toString()
  return queryString.parse(queryUrl, { arrayFormat: 'comma' })
}

export const isNumeric = (str: string) => {
  if (typeof str !== 'string') return false // we only process strings!
  return !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
