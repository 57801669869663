import styled from 'styled-components'

export const ScrollBox = styled.div`
  display: flex;
  overflow-y: auto;
  width: 100%;
  height: 500px;
  margin-bottom: 1rem;

  background-color: ${props => props.theme.colors.background};
`
