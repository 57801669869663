import styled from 'styled-components'

export const Container = styled.ul`
  line-height: 1.5;
  display: block;
  list-style-type: disc;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 2.188rem;

  li {
    margin-bottom: 0.5rem;
  }

  li:last-child {
    margin-bottom: 0rem;
  }
`
