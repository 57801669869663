import { ThemeProvider } from 'styled-components'
import { SkeletonTheme } from 'react-loading-skeleton'

import Router from '@main/routes/router'
import { getCurrentAccount } from '@main/adapters'
import { useTheme } from '@presentation/hooks'
import { AuthContext, DashboardContextProvider } from '@presentation/contexts'
import { darkTheme, GlobalStyle, lightTheme } from '@presentation/styles'

const App: React.FC = () => {
  const { mode } = useTheme()

  const theme = mode === 'light' ? lightTheme : darkTheme

  return (
    <ThemeProvider theme={theme}>
      <SkeletonTheme
        baseColor={theme.colors.backgroundContent}
        highlightColor={theme.colors.background}
      >
        <GlobalStyle />
        <AuthContext.Provider value={{ getCurrentAccount: getCurrentAccount }}>
          <DashboardContextProvider>
            <Router />
          </DashboardContextProvider>
        </AuthContext.Provider>
      </SkeletonTheme>
    </ThemeProvider>
  )
}

export default App
