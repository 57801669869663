import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPen as EditIcon } from 'react-icons/fa'

import { Alert } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { UnexpectedError, ValidationError } from '@core/models/errors'
import { Modal } from '@presentation/components/organisms'
import {
  ErrorList,
  ActionButton,
  SubmitButton
} from '@presentation/components/molecules'
import { ModalProps } from '@presentation/types/components/organisms'
import { EditModalProps } from '@presentation/types/components/templates'
import { FormContext } from '@presentation/contexts'

const EditModal: React.FC<EditModalProps> = ({
  id,
  path,
  formId,
  nameForTitle,
  validationSchema,
  mapData,
  mapRequest,
  onEdit,
  size,
  children
}) => {
  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const [isOpen, setIsOpen] = useState(false)
  const [apiErrors, setApiErrors] = useState<string[]>([])
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (isOpen) {
      const loadData = async () => {
        try {
          const resourceData = await AdministracaoService.loadOne({ id, path })
          setData(resourceData)
        } catch (error) {
          onError(error as Error)
        }
      }

      void loadData()
    }
  }, [isOpen])

  useEffect(() => {
    if (data) {
      formMethods.reset(mapData ? mapData(data) : data)
    }
  }, [data])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const clearApiErrors = () => {
    setApiErrors([])
  }

  const onUpdate = () => {
    closeModal()
    if (onEdit) onEdit()

    Alert.callSuccess({
      title: 'Atualizado com Sucesso'
    })
  }

  const onError = (error: Error) => {
    closeModal()
    Alert.callError({
      title: 'Falha de Atualização',
      description: error.message
    })
  }

  const onSubmit: SubmitHandler<any> = async input => {
    try {
      await AdministracaoService.update({
        id,
        data: mapRequest ? mapRequest(input) : input,
        path
      })
      onUpdate()
    } catch (error) {
      if (error instanceof ValidationError) {
        setApiErrors(error.errors)
      }

      if (error instanceof UnexpectedError) {
        onError(error)
      }
    }
  }

  const buttonActions = [
    <SubmitButton
      form={formId}
      isValid={formMethods.formState.isValid}
      isSubmitting={formMethods.formState.isSubmitting}
    >
      Salvar
    </SubmitButton>
  ]
  const configs: ModalProps = {
    isOpen,
    icon: <EditIcon />,
    title: `Edição de ${nameForTitle}`,
    description: `Use o formulário abaixo para atualizar o recurso selecionado em nosso banco de dados.`,
    actions: buttonActions,
    size: size,
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="edit" onClick={openModal} />
      {isOpen && (
        <Modal {...configs}>
          {apiErrors.length > 0 && (
            <ErrorList errors={apiErrors} onHide={clearApiErrors} />
          )}
          <FormContext.Provider
            value={{
              formId,
              onSubmit: formMethods.handleSubmit(onSubmit),
              ...formMethods
            }}
          >
            {children}
          </FormContext.Provider>
        </Modal>
      )}
    </>
  )
}

export default EditModal
