import styled, { css } from 'styled-components'
import { FaAngleRight } from 'react-icons/fa'

type ArrowIconProps = {
  isOpen: boolean
}

export const ArrowIcon = styled(FaAngleRight)<ArrowIconProps>`
  ${({ isOpen, theme }) => css`
    font-size: 1.2rem;
    transform: ${isOpen && 'rotate(90deg)'};
    transition: ${theme.transitions.transform};
  `}
`
