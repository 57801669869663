import * as S from './styles'

const Quote: React.FC = ({ children }) => {
  return (
    <>
      <S.Blockquote>{children}</S.Blockquote>
    </>
  )
}

export default Quote
