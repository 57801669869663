import { sidebar } from '@presentation/configs'
import { SidebarProps } from '@presentation/types/shared'
import { useState, ReactNode, createContext } from 'react'

type Props = {
  children: ReactNode
}

type DashboardContextType = {
  isOpenSidebar: boolean
  toggleSidebar: () => void
  sidebar: SidebarProps
}

export const DashboardContext = createContext({} as DashboardContextType)

export const DashboardContextProvider = ({ children }: Props) => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const toggleSidebar = (): void => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  return (
    <DashboardContext.Provider
      value={{
        isOpenSidebar,
        toggleSidebar,
        sidebar
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}
