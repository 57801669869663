import { Col, Form, Row } from 'react-bootstrap'

import { Input, SelectApi, TextInput } from '@presentation/components/molecules'
import { useForm } from '@presentation/hooks'

const FeriadoForm: React.FC = () => {
  const { register, control, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Col>
          <Input
            label="Nome"
            name="nome"
            register={register}
            error={formState?.errors.nome?.message}
            required
            type="text"
            placeholder="Digite o nome do feriado"
            autoComplete="off"
          ></Input>
        </Col>
        <Col>
          <Input
            label="Data"
            name="data"
            register={register}
            error={formState?.errors.data?.message}
            required
            type="date"
            placeholder="Digite a data do feriado"
            autoComplete="off"
          ></Input>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <SelectApi
            name="tipo"
            path="/feriado-tipos"
            control={control}
            placeholder="Selecione aqui"
            label="Tipo"
            isRequired
            getOptionLabel={option => option.descricao}
            getOptionValue={option => option.id}
            searchKey="descricao"
          />
        </Col>
      </Row>
      <Row>
        <TextInput
          label="Descrição"
          name="descricao"
          register={register}
          error={formState?.errors.descricao?.message}
          required
          type="textarea"
          placeholder="Digite a descrição do feriado"
          autoComplete="off"
        />
      </Row>
    </Form>
  )
}

export default FeriadoForm
