import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { media } from '@presentation/styles'

type SidebarProps = {
  isOpen: boolean
}

export const Container = styled.div<SidebarProps>`
  ${({ theme, isOpen }) => css`
    position: fixed;
    top: 0;
    left: ${isOpen ? 0 : '-100%'};
    height: 100vh;
    width: ${theme.shared.sidebar.width};
    background-color: ${theme.colors.backgroundSidebar};
    z-index: ${theme.indexes.high};
    overflow-y: auto !important;
    overflow-x: hidden;
    transition: ${theme.transitions.default};

    ${media.greaterThan('mobile')`
      ${
        !isOpen &&
        css`
          left: 0 !important;
          width: ${theme.shared.sidebar.collapseWidth} !important;
        `
      }
    `}

    ${Divider} {
      &:first-of-type {
        margin-top: 0 !important;
      }
    }
  `}
`
export const Divider = styled.hr`
  width: 80%;
  margin: 1rem auto;
  color: ${props => rgba(props.theme.colors.white, 0.8)};
  height: 2px;
  opacity: 1;
`
