import { useResponsive } from '@presentation/hooks'
import { Section, Tabs } from '@presentation/components/molecules'
import { TabsProps } from '@presentation/types/components/molecules'

import { AppTabContent, InternetTabContent } from './components'

const ComprovanteGuide: React.FC = () => {
  const { isMobile } = useResponsive()

  const tabsConfig: TabsProps = {
    defaultActiveKey: isMobile ? 'app' : 'internet',
    items: [
      {
        eventKey: 'internet',
        title: 'Internet',
        component: <InternetTabContent />
      },
      {
        eventKey: 'app',
        title: 'Aplicativo',
        component: <AppTabContent />
      }
    ]
  }

  return (
    <Section title="Como emitir?">
      <p>
        O Certificado Nacional de Vacinação COVID-19 é um documento que comprova
        a vacinação do cidadão contra a COVID-19. O Ministério da Saúde
        disponibiliza, por meio do Conecte SUS Cidadão, a possibilidade de o
        cidadão visualizar, salvar e imprimir o seu certificado. É possível
        emitir não somente na internet mas também pelo aplicativo Conecte SUS,
        disponível nas lojas{' '}
        <a
          href="https://play.google.com/store/apps/details?id=br.gov.datasus.cnsdigital/"
          target="_blank"
        >
          Google Play
        </a>{' '}
        e{' '}
        <a
          href="https://apps.apple.com/br/app/conecte-sus/id1045754608/"
          target="_blank"
        >
          App Store
        </a>{' '}
        .
      </p>

      <Tabs {...tabsConfig} />
    </Section>
  )
}

export default ComprovanteGuide
