import { HttpClient } from '@core/gateways'
import {
  NotFoundError,
  UnexpectedError,
  ValidationError
} from '@core/models/errors'
import { setupIntranetApiClient } from '@core/proxies/apis'
import {
  HttpStatusCode,
  HttpClient as HttpClientInterface
} from '@core/types/gateways'
import {
  RemoveResource,
  ReactivateResource,
  AddResource,
  UpdateResource,
  LoadOneInput,
  LoadAllInput
} from '@core/types/services'

export const loadOne = async <T = any>({
  id,
  path
}: LoadOneInput): Promise<T> => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `${path}/${id}`,
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body.data
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const loadAll = async <T = any>({ path }: LoadAllInput): Promise<T> => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `${path}`,
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body.data
    default:
      throw new UnexpectedError()
  }
}

export const add: AddResource = async ({ data, path }) => {
  const request: HttpClientInterface.Input = {
    url: `${path}`,
    method: 'POST',
    body: data
  }

  if (data instanceof FormData) {
    Object.assign(request, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const response = await HttpClient.of(setupIntranetApiClient()).request(
    request
  )

  switch (response.statusCode) {
    case HttpStatusCode.created:
      return
    case HttpStatusCode.badRequest:
      throw new ValidationError(response.body.erros)
    default:
      throw new UnexpectedError()
  }
}

export const update: UpdateResource = async ({ id, data, path }) => {
  const isFormData = data instanceof FormData

  const request: HttpClientInterface.Input = {
    url: `${path}/${id}`,
    method: isFormData ? 'POST' : 'PUT',
    body: data
  }

  if (isFormData) {
    Object.assign(request, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  const response = await HttpClient.of(setupIntranetApiClient()).request(
    request
  )

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return
    case HttpStatusCode.badRequest:
      throw new ValidationError(response.body.erros)
    default:
      throw new UnexpectedError()
  }
}

export const remove: RemoveResource = async ({ id, path }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `${path}/${id}`,
    method: 'DELETE'
  })

  switch (response.statusCode) {
    case HttpStatusCode.noContent:
      return
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const reactivate: ReactivateResource = async ({ id, path }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `${path}/${id}/reativar`,
    method: 'PATCH'
  })

  switch (response.statusCode) {
    case HttpStatusCode.noContent:
      return
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}
