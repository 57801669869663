import { memo, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert as BsAlert } from 'react-bootstrap'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { env } from '@main/configs'
import { Alert } from '@core/gateways'
import {
  InvalidCredentialsError,
  NotAcceptTermsError
} from '@core/models/errors'
import { AuthenticationService } from '@core/services'
import { useAccessToken, useAuth, useModal } from '@presentation/hooks'
import { FormContext } from '@presentation/contexts'
import { AuthPage } from '@presentation/components/templates'
import { LoginForm } from '@presentation/components/organisms'

import { validationSchema } from './validation'
import { AcceptTermosUsoModal, CannotAccess } from './components'
import * as S from './styles'

type FormInput = {
  identificacao: string
  senha: string
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getCurrentAccount } = useAuth()
  const formMethods = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const { isOpen, openModal, closeModal } = useModal()
  const { setAccessToken } = useAccessToken()

  const [token, setToken] = useState<string>()
  const [apiError, setApiError] = useState<string | undefined>()

  useEffect(() => {
    if (getCurrentAccount()) {
      navigate(env.app.homepage)
    }
    formMethods.setFocus('identificacao')
  }, [])

  const handleInvalidCredentialsError = (error: InvalidCredentialsError) => {
    setApiError(error.message)
    formMethods.reset({ senha: '' })
    formMethods.setFocus('senha')
  }

  const handleNotAcceptTermsError = (error: NotAcceptTermsError) => {
    setToken(error.token)
    openModal()
    setApiError(error.message)
  }

  const onError = async (error: Error) => {
    formMethods.reset()
    await Alert.call({
      title: 'Falha de Autenticação',
      description: error.message,
      type: 'error'
    })
  }

  const onSuccess = (accessToken: string) => {
    setAccessToken(accessToken)

    if (location.state !== null) {
      navigate((location.state as any).from.pathname)
    } else {
      navigate(env.app.homepage)
    }
  }

  const onSubmit: SubmitHandler<FormInput> = async ({
    identificacao,
    senha
  }) => {
    try {
      const accessToken = await AuthenticationService.authenticate({
        identificacao,
        senha
      })
      onSuccess(accessToken)
    } catch (error) {
      if (error instanceof InvalidCredentialsError) {
        handleInvalidCredentialsError(error)
      } else if (error instanceof NotAcceptTermsError) {
        handleNotAcceptTermsError(error)
      } else {
        await onError(error as Error)
      }
    }
  }

  const onAcceptTerms = () => {
    onSubmit(formMethods.getValues())
  }

  return (
    <>
      <AuthPage title="Autenticação">
        {apiError && (
          <BsAlert
            variant="danger"
            onClose={() => setApiError(undefined)}
            dismissible
          >
            {apiError}
          </BsAlert>
        )}

        <FormContext.Provider
          value={{
            ...formMethods,
            onSubmit: formMethods.handleSubmit(onSubmit)
          }}
        >
          <LoginForm />
        </FormContext.Provider>

        <S.ForgotPasswordWrapper>
          <CannotAccess />
        </S.ForgotPasswordWrapper>
      </AuthPage>

      {token && (
        <AcceptTermosUsoModal
          isOpen={isOpen}
          closeModal={closeModal}
          token={token}
          onAccept={onAcceptTerms}
        />
      )}
    </>
  )
}

export default memo(LoginPage)
