import { memo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { env } from '@main/configs'

import * as S from './styles'

const Footer: React.FC = () => {
  return (
    <S.Container>
      <Row>
        <Col>
          <p>{env.app.name} © 2022 NDS</p>
          <p>
            <Link to="/termos-de-uso">Termos de Uso</Link>
          </p>
        </Col>

        <Col>{env.app.logo.colorful}</Col>

        <Col>
          <p>Coordenadoria de Tecnologia da Informação</p>
          <p>IFCE Campus Maracanaú</p>
        </Col>
      </Row>
    </S.Container>
  )
}

export default memo(Footer)
