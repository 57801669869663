import { FiUploadCloud as CloudIcon } from 'react-icons/fi'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const UploadIcon = styled(CloudIcon)`
  font-size: 5rem;
  color: ${props => props.theme.colors.secondary};
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
`

export const Info = styled.span`
  color: ${props => props.theme.colors.secondary};
`
