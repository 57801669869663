import * as Yup from 'yup'
import { cpf } from 'cpf-cnpj-validator'

Yup.addMethod(Yup.string, 'cpf', function (errorMessage) {
  return this.test({
    name: 'cpf',
    message: errorMessage,
    test: value => (value ? cpf.isValid(value) : true)
  })
})

Yup.addMethod(Yup.string, 'personName', function (errorMessage) {
  return this.test({
    name: 'personName',
    message: errorMessage,
    test: value => (value ? /^[ ]*(.+[ ]+)+.+[ ]*$/.test(value) : true)
  })
})

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test({
    name: 'phone',
    message: errorMessage,
    test: value => {
      const pattern =
        /^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$/

      return value ? pattern.test(value) : true
    }
  })
})

export default Yup
