import { Form } from 'react-bootstrap'

import { CheckboxProps } from '@presentation/types/components/molecules'

const Checkbox: React.FC<CheckboxProps> = ({
  name = '',
  register,
  error,
  ...rest
}) => {
  return (
    <Form.Check
      {...register?.(name)}
      {...rest}
      type="checkbox"
      isInvalid={error !== undefined}
    />
  )
}

export default Checkbox
