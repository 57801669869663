import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;

  video {
    object-fit: cover !important;
    max-width: 768px;
    height: auto;
  }
`
