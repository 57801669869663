import { FuncaoForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  id: string
  onEdit: () => void
}

const EditFuncaoModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    path: '/funcoes',
    formId: 'edit-funcao',
    nameForTitle: 'Função',
    validationSchema,
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <FuncaoForm />
    </EditModal>
  )
}

export default EditFuncaoModal
