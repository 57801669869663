import styled from 'styled-components'

export const Container = styled.div`
  button {
    margin-right: 0.25rem;

    svg {
      fill: white;
    }
  }
`
