import { BsBuilding as BlocosIcon } from 'react-icons/bs'

import { Bloco, PermissaoTipo } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import {
  AddBlocoModal,
  DeleteBlocoModal,
  EditBlocoModal,
  ReactivateBlocoModal
} from './components'

const BlocosPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/blocos',
    options: { sortBy: 'nome' },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <BlocosIcon />,
    title: 'Blocos',
    description: 'Página de gerenciamento de blocos do campus.',
    actions: hasPermission(PermissaoTipo.Escrita)
      ? [<AddBlocoModal onAdd={fetchData} />]
      : []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'eixo_id',
        label: 'Eixo'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/eixos',
          isMulti: true
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      status: 1
    }
  }

  const columns: Array<TableColumn<Bloco>> = [
    {
      name: 'Nome',
      selector: bloco => bloco.nome,
      sortable: true,
      center: true
    },
    {
      name: 'Eixo',
      selector: bloco => bloco.eixo?.nome ?? '',
      sortable: true,
      center: true
    },
    {
      name: 'Ações',
      cell: bloco => (
        <>
          {bloco.status ? (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <EditBlocoModal id={bloco.id} onEdit={fetchData} />
              )}
              {hasPermission(PermissaoTipo.Remocao) && (
                <DeleteBlocoModal id={bloco.id} onDelete={fetchData} />
              )}
            </>
          ) : (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <ReactivateBlocoModal id={bloco.id} onReactivate={fetchData} />
              )}
            </>
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default BlocosPage
