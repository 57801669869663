import { memo } from 'react'

import { useProfile } from '@presentation/hooks'
import { formatProfileName } from '@presentation/utils'

import * as S from './styles'

const NotificationDropdownToggle: React.FC = () => {
  const { currentAccount, photo } = useProfile()

  return (
    <S.Container variant="transparent">
      <S.ProfileImage src={photo} alt="Foto de Perfil" />
      <S.ProfileInfoWrapper>
        <S.ProfileName>
          {currentAccount && formatProfileName(currentAccount.nome)}
        </S.ProfileName>
        <S.ProfileGroup>{currentAccount?.pessoa_tipo}</S.ProfileGroup>
      </S.ProfileInfoWrapper>
    </S.Container>
  )
}

export default memo(NotificationDropdownToggle)
