import { Spinner } from 'react-bootstrap'

import * as S from './styles'

const Loading: React.FC = () => {
  return (
    <S.Container>
      <Spinner animation="border" style={{ width: '3rem', height: '3rem' }} />
    </S.Container>
  )
}

export default Loading
