import { HttpStatusCode } from '@core/types/gateways'
import {
  AcceptComprovante,
  CancelEnvio,
  ComprovanteStatus,
  DenyComprovante,
  GetComprovanteInfo,
  LoadComprovanteFile,
  LoadComprovantes,
  ReleaseComprovante,
  SendComprovante
} from '@core/types/services'
import { HttpClient } from '@core/gateways'
import {
  AccessDeniedError,
  NotFoundError,
  UnexpectedError,
  ValidationError
} from '@core/models/errors'
import { setupIntranetApiClient } from '@core/proxies/apis'

export const sendComprovante: SendComprovante = async ({ file }) => {
  const body = new FormData()
  body.append('comprovante', file)

  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: '/comprovantes-covid19',
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  switch (response.statusCode) {
    case HttpStatusCode.created:
      return
    case HttpStatusCode.badRequest:
      throw new ValidationError(response.body.erros)
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    default:
      throw new UnexpectedError()
  }
}

export const loadComprovantes: LoadComprovantes = async () => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: '/comprovantes-covid19',
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body.data
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    default:
      throw new UnexpectedError()
  }
}

export const getComprovanteInfo: GetComprovanteInfo = async () => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: '/comprovantes-covid19',
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      const files = response.body.data as any[]

      if (files.length === 0) {
        return {
          status: ComprovanteStatus.NaoEnviado
        }
      }

      const underAnalysisFiles = files.filter(file => file.deferido === null)

      if (underAnalysisFiles.length > 0) {
        return {
          status: ComprovanteStatus.Aguardando,
          file: underAnalysisFiles[0]
        }
      }

      const wasAccepted = files.some(file => file.deferido === 1)

      if (wasAccepted) {
        return {
          status: ComprovanteStatus.Deferido
        }
      } else {
        const filesNotAccepted = files.filter(file => file.deferido === 0)
        const lastFileNotAccepted = filesNotAccepted[0]

        return {
          status: ComprovanteStatus.Indeferido,
          motivo: lastFileNotAccepted.motivo_indeferimento
        }
      }

    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()

    default:
      throw new UnexpectedError()
  }
}

export const cancelEnvio: CancelEnvio = async ({ id }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/comprovantes-covid19/${id}`,
    method: 'DELETE'
  })

  switch (response.statusCode) {
    case HttpStatusCode.noContent:
      return
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const loadComprovanteFile: LoadComprovanteFile = async ({
  id,
  isAdmin = false
}) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: isAdmin
      ? `/gerenciar-comprovantes-covid19/${id}/visualizar`
      : `/comprovantes-covid19/${id}/visualizar`,
    method: 'GET',
    responseType: 'blob'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      const blob = new Blob([response.body])
      const file = new File([blob], `${id}`, {
        type: response.headers['content-type']
      })
      return file
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const acceptComprovante: AcceptComprovante = async ({ id }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/gerenciar-comprovantes-covid19/${id}/deferir`,
    method: 'PATCH'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const denyComprovante: DenyComprovante = async ({ id, motivo }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/gerenciar-comprovantes-covid19/${id}/indeferir`,
    method: 'PATCH',
    body: {
      motivo_indeferimento: motivo
    }
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return
    case HttpStatusCode.badRequest:
      throw new ValidationError(response.body.erros)
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const releaseComprovante: ReleaseComprovante = async ({ id }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/pessoas/${id}/liberar-comprovante`,
    method: 'PATCH'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return
    case HttpStatusCode.badRequest:
      throw new ValidationError(response.body.erros)
    case HttpStatusCode.forbidden:
      throw new AccessDeniedError()
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}
