import { useNavigate } from 'react-router-dom'
import { Dropdown, Form } from 'react-bootstrap'
import { FaRegMoon as ThemeIcon } from 'react-icons/fa'
import {
  BiUserCircle as UserIcon,
  BiHelpCircle as HelpIcon,
  BiLogOut as ExitIcon
} from 'react-icons/bi'
import { FiSettings as SettingsIcon } from 'react-icons/fi'

import { AuthenticationService } from '@core/services'
import { useTheme } from '@presentation/hooks'

import * as S from './styles'

const ProfileDropdownContent: React.FC = () => {
  const navigate = useNavigate()
  const { mode, toggleTheme } = useTheme()

  const onLogout = () => {
    AuthenticationService.logout()
    navigate('/login')
  }

  return (
    <>
      <S.ItemWrapper>
        <S.Item as="div" style={{ color: 'gray' }}>
          <UserIcon size="21px" className="me-2" />
          Conta
        </S.Item>
        <S.Item as="div" style={{ color: 'gray' }}>
          <SettingsIcon size="21px" className="me-2" />
          Configurações
        </S.Item>
        <S.Item as="div" style={{ color: 'gray' }}>
          <HelpIcon size="21px" className="me-2" />
          Ajuda
        </S.Item>
        <S.Item onClick={() => onLogout()} as="div">
          <ExitIcon size="21px" className="me-2" />
          Sair
        </S.Item>
      </S.ItemWrapper>

      <Dropdown.Divider />

      <Form onClick={toggleTheme}>
        <S.ItemWrapper>
          <S.Item as="div">
            <ThemeIcon className="me-2" />
            Mudar Tema
            <Form.Switch
              className="float-end"
              checked={mode === 'dark'}
              onChange={() => toggleTheme()}
            />
          </S.Item>
        </S.ItemWrapper>
      </Form>
    </>
  )
}

export default ProfileDropdownContent
