import { DateTime } from 'luxon'

import { FormatDate } from '@core/types/gateways'

export const format: FormatDate = ({ date, pattern }) => {
  const formattedDate = DateTime.fromISO(date)
    .setLocale('pt-br')
    .toFormat(pattern)
    .toLocaleString()

  return formattedDate
}
