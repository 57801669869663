import DataTable from 'react-data-table-component'

import { BasicTableProps } from '@presentation/types/components/organisms'

import { Loading, NoData } from './components'
import * as S from './styles'

export const BasicTable: React.FC<BasicTableProps> = ({
  striped = true,
  pagination = true,
  paginationPerPage = 20,
  ...rest
}) => {
  return (
    <S.Container>
      <DataTable
        {...rest}
        striped
        pagination={pagination}
        paginationPerPage={paginationPerPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Resultados por página',
          rangeSeparatorText: 'de'
        }}
        noDataComponent={<NoData />}
        progressComponent={<Loading />}
      />
    </S.Container>
  )
}

export default BasicTable
