import { Col, Form, Row } from 'react-bootstrap'

import { useForm } from '@presentation/hooks'
import { Input, Select, SelectApi } from '@presentation/components/molecules'

const CursoForm: React.FC = () => {
  const { formId, onSubmit, register, control, formState } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Col>
          <Input
            label="Nome"
            name="nome"
            register={register}
            error={formState?.errors.nome?.message}
            required
            placeholder="Digite o nome do curso"
          />
        </Col>
        <Col>
          <SelectApi
            label="Tipo de Curso"
            name="cursoTipo"
            path="/curso-tipos"
            control={control}
            error={formState?.errors.cursoTipo?.message}
            isPaginate={false}
            isRequired
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectApi
            label="Eixo"
            name="eixo"
            path="/eixos"
            control={control}
            error={formState?.errors.eixo?.message}
            isRequired
          />
        </Col>
        <Col>
          <Select
            label="Externo"
            name="externo"
            control={control}
            options={[
              { label: 'Sim', value: '1' },
              { label: 'Não', value: '0' }
            ]}
            error={formState?.errors.externo?.message}
            isRequired
          />
        </Col>
      </Row>
    </Form>
  )
}

export default CursoForm
