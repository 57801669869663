import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup
    .string()
    .required('O campo é obrigatório.')
    .max(100, 'O campo atingiu o limite máximo de 100 caracteres.'),
  email: yup
    .string()
    .email('Digite um e-mail válido.')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.'),
  observacoes: yup
    .string()
    .nullable()
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.'),
  lotacao: yup.object().required('O campo é obrigatório'),
  local: yup.object().required('O campo é obrigatório')
})
