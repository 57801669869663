import styled from 'styled-components'
import { Dropdown, Image } from 'react-bootstrap'

export const Container = styled(Dropdown.Toggle)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;

  font-size: 1.6rem;
  color: ${props => props.theme.colors.white};

  :after {
    display: none; /* remove o ícone de seta do dropdown */
  }

  :focus,
  :hover,
  :active {
    box-shadow: none !important;
    color: white;
  }
`

export const ProfileImage = styled(Image)`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 1.5px solid ${props => props.theme.colors.white};
  padding: 1px;
`

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  align-items: self-start;

  &::before {
    position: absolute;
    left: -1px;
    top: 50%;
    background: ${props => props.theme.colors.white};
    width: 1.5px;
    height: 30px;
    margin-top: -15px;
    content: '';
  }
`

export const ProfileName = styled.span`
  font-size: 1rem;
  font-weight: 700;
`
export const ProfileGroup = styled.span`
  font-size: 0.8rem;
  font-weight: 300;
  margin-top: -7px;
  opacity: 0.8;
`
