import styled from 'styled-components'
import { rgba } from 'polished'

export const PersonPicture = styled.div`
  border: 1px solid ${props => rgba(props.theme.colors.textPrimary, 0.125)};
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.background};
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
`

export const Picture = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
`
