import { ReactNode } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

type Props = ButtonProps & {
  icon: ReactNode
}

const IconButton: React.FC<Props> = ({ icon, children, ...buttonProps }) => {
  return (
    <Button {...buttonProps} className="d-flex align-items-center">
      {icon}
      <span className="ms-1">{children}</span>
    </Button>
  )
}

export default IconButton
