import { Dropdown } from 'react-bootstrap'

import {
  ProfileDropdownContent,
  ProfileDropdownHeader,
  ProfileDropdownToggle
} from './components'
import * as S from './styles'

const NavbarDropdownProfile: React.FC = () => {
  return (
    <Dropdown>
      <ProfileDropdownToggle />
      <S.Menu variant="transparent">
        <ProfileDropdownHeader />
        <ProfileDropdownContent />
      </S.Menu>
    </Dropdown>
  )
}

export default NavbarDropdownProfile
