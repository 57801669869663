import { DeleteModal } from '@presentation/components/templates'
import { DeleteModalProps } from '@presentation/types/components/templates'

type Props = {
  id: string
  onDelete: () => void
}

const DeleteBlocoModal: React.FC<Props> = ({ id, onDelete }) => {
  const modalConfigs: DeleteModalProps = {
    id,
    path: '/blocos',
    nameForTitle: 'Bloco',
    onDelete
  }

  return <DeleteModal {...modalConfigs} />
}

export default DeleteBlocoModal
