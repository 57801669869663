import { useEffect, useState, memo } from 'react'
import { Form } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaSyringe as ComprovanteIcon } from 'react-icons/fa'

import { Comprovante } from '@core/models/entities'
import { VacinaService } from '@core/services'
import { ValidationError } from '@core/models/errors'
import { Alert } from '@core/gateways'
import { ComprovanteStatus } from '@core/types/services'
import { useAuth, useErrors } from '@presentation/hooks'
import {
  ErrorList,
  Message,
  SubmitButton
} from '@presentation/components/molecules'
import { Dropzone } from '@presentation/components/organisms'
import { Page } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'

import { validationSchema, maxFileSize, supportedFormats } from './shared'
import {
  canSendComprovante,
  canViewEnvioHistory,
  canCancelEnvio,
  useStatus
} from './utils'
import {
  About,
  CancelEnvioModal,
  ShowEnvioHistoryModal,
  StatusTag
} from './components'
import * as S from './styles'
import { WindowUtils } from '@presentation/utils'

type FormInput = {
  file: File
}

const ComprovantePage: React.FC = () => {
  const { formState, handleSubmit, setValue, reset } = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const { getCurrentAccount } = useAuth()
  const { errors, setErrors, clearErrors } = useErrors({
    shouldScrollTop: true
  })
  const { status, message, variant, setStatus, setMotivoRecusa } = useStatus()

  const [file, setFile] = useState<File>()

  const [comprovanteUnderReview, setComprovanteUnderReview] =
    useState<Comprovante>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    reset()
    setFile(undefined)

    if (wasDeferidoManualmente()) {
      setStatus(ComprovanteStatus.DeferidoManualmente)
      setIsLoading(false)
    } else {
      void handleComprovanteInfo()
    }
  }, [status])

  const wasDeferidoManualmente = () => {
    const account = getCurrentAccount()

    return (
      account?.comprovante_covid19 && account.url_comprovante_covid19 === null
    )
  }

  const handleComprovanteInfo = async () => {
    try {
      const comprovanteInfo = await VacinaService.getComprovanteInfo()

      if (comprovanteInfo.file) {
        setComprovanteUnderReview(comprovanteInfo.file)
      }

      if (comprovanteInfo.motivo) {
        setMotivoRecusa(comprovanteInfo.motivo)
      }

      setStatus(comprovanteInfo.status)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (file) {
      setValue('file', file, { shouldValidate: true })
    }
  }, [file])

  const onSuccess = () => {
    WindowUtils.scrollToTop()
    setStatus(ComprovanteStatus.Aguardando)
  }

  const onError = (error: Error) => {
    WindowUtils.scrollToTop()
    Alert.call({
      title: 'Falha de Envio',
      description: error.message,
      type: 'error'
    })
  }

  const onSubmit: SubmitHandler<FormInput> = async input => {
    try {
      await VacinaService.sendComprovante({ file: input.file })
      onSuccess()
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
      } else {
        onError(error as Error)
      }
    }
  }

  const onCancelEnvio = () => {
    setStatus(ComprovanteStatus.NaoEnviado)
  }

  const pageConfigs: PageProps = {
    icon: <ComprovanteIcon />,
    title: 'Comprovante de Vacina',
    description:
      'Página para envio do comprovante de vacina contra a COVID-19.',
    isLoading: isLoading,
    actions: [<StatusTag status={status} variant={variant} />]
  }

  return (
    <Page {...pageConfigs}>
      {status !== ComprovanteStatus.NaoEnviado && (
        <Message variant={variant}>{message}</Message>
      )}

      {errors.length > 0 && <ErrorList errors={errors} onHide={clearErrors} />}
      <About isGuideVisible={canSendComprovante(status)} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        {canSendComprovante(status) && (
          <Dropzone
            file={file}
            onFileChange={setFile}
            maxSize={maxFileSize}
            supportedFormats={supportedFormats}
          />
        )}

        <S.Footer>
          <S.ButtonGroup>
            <>
              {canViewEnvioHistory(status) && <ShowEnvioHistoryModal />}

              {canSendComprovante(status) && (
                <SubmitButton
                  isValid={formState.isValid}
                  isSubmitting={formState.isSubmitting}
                >
                  Enviar
                </SubmitButton>
              )}

              {comprovanteUnderReview && canCancelEnvio(status) && (
                <CancelEnvioModal
                  comprovanteId={comprovanteUnderReview.id}
                  onCancel={onCancelEnvio}
                />
              )}
            </>
          </S.ButtonGroup>
        </S.Footer>
      </Form>
    </Page>
  )
}

export default memo(ComprovantePage)
