import { memo } from 'react'

import { env } from '@main/configs'
import { useResponsive, useDashboard } from '@presentation/hooks'
import { Brand } from '@presentation/components/molecules'
import {
  NotificationDropdown,
  ProfileDropdown
} from '@presentation/components/organisms'

import * as S from './styles'

const Navbar: React.FC = () => {
  const { isMobile } = useResponsive()
  const { toggleSidebar } = useDashboard()

  const campusName = env.app.campusName

  return (
    <S.Container>
      <S.ToggleIcon onClick={() => toggleSidebar()} />

      {isMobile ? <Brand variant="name" /> : null}

      <S.CampusName>Campus {campusName}</S.CampusName>

      <S.RightIcons>
        <NotificationDropdown />
        <ProfileDropdown />
      </S.RightIcons>
    </S.Container>
  )
}

export default memo(Navbar)
