import { CargoForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  id: string
  onEdit: () => void
}

const EditCargoModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    path: '/cargos',
    formId: 'edit-cargo',
    nameForTitle: 'Cargo',
    validationSchema,
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <CargoForm />
    </EditModal>
  )
}

export default EditCargoModal
