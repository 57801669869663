import { Col, Row } from 'react-bootstrap'
import { Control } from 'react-hook-form'

import { Permissao } from '@core/models/entities'
import { Select } from '@presentation/components/molecules'

import * as S from './styles'

type Props = {
  items: Permissao[]
  control: Control<any>
}

const PermissoesCategoriaTab: React.FC<Props> = ({ items, control }) => {
  const selectOptions = [
    { label: 'Leitura', value: 'R' },
    { label: 'Escrita', value: 'W' },
    { label: 'Exclusão', value: 'D' },
    { label: 'Leitura e Escrita', value: 'RW' },
    { label: 'Leitura e Exclusão', value: 'RD' },
    { label: 'Escrita e Exclusão', value: 'WD' },
    { label: 'Controle Total', value: 'RWD' }
  ]

  return (
    <S.Container>
      {items.map(permissao => (
        <Row>
          <Col md="8" className="d-flex align-items-center">
            <span>
              <>
                <strong>{permissao.nome}: </strong>
                {permissao.descricao}
              </>
            </span>
          </Col>
          <Col md="4">
            <Select
              key={permissao.id}
              control={control}
              name={`permissoes.${permissao.id}.value`}
              options={selectOptions}
              isClearable
            />
          </Col>
        </Row>
      ))}
    </S.Container>
  )
}

export default PermissoesCategoriaTab
