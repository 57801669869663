import { Dispatch, SetStateAction } from 'react'
import { Ratio } from 'react-bootstrap'

import { TakePictureMultiModal } from './components'

import * as S from './styles'

type Props = {
  file: File | undefined
  onFileChange: Dispatch<SetStateAction<File | undefined>>
}

const PersonPicture: React.FC<Props> = ({ file, onFileChange }) => {
  return (
    <S.Container>
      <Ratio aspectRatio={'1x1'} style={{ height: '100%' }}>
        <S.Picture
          src={
            file ? URL.createObjectURL(file) : 'https://imgur.com/vcZ08Ql.png'
          }
        ></S.Picture>
      </Ratio>
      <S.PictureOverlay>
        <TakePictureMultiModal onPictureChange={onFileChange} />
      </S.PictureOverlay>
    </S.Container>
  )
}

export default PersonPicture
