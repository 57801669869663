import { Section } from '@presentation/components/molecules'

const DeveresSection: React.FC = () => {
  return (
    <>
      <Section title="Deveres do Usuário" />

      <p>
        Assim como possui direitos, você também tem obrigações ao utilizar os
        serviços da Intranet.
      </p>

      <ul>
        <li>
          O usuário é responsável pela atualização de suas informações pessoais
          e consequências na omissão ou erros nas informações pessoais
          informadas;
        </li>
        <li>
          O usuário é responsável pela reparação de todos e quaisquer danos,
          diretos ou indiretos (inclusive decorrentes de violação de quaisquer
          direitos de outros usuários, de terceiros, inclusive direitos de
          propriedade intelectual, de sigilo e de personalidade), que sejam
          causados à instituição, a qualquer outro usuário, ou, ainda, a
          qualquer terceiro, inclusive em virtude do descumprimento do disposto
          nestes Termos de Uso e Política de Privacidade ou de qualquer ato
          praticado a partir de seu acesso ao serviço.
        </li>
        <li>
          <p>
            A instituição não poderá ser responsabilizado pelos seguintes fatos:
          </p>
          <ul>
            <li>
              Equipamento infectado ou invadido por pessoas mal intencionadas;
            </li>
            <li>Equipamento avariado no momento de consumo dos serviços;</li>
            <li>Proteção do computador;</li>
            <li>
              Proteção das informações baseadas nos computadores dos usuários;
            </li>
            <li>Abuso de uso dos computadores dos usuários;</li>
            <li>Monitoração clandestina do computador dos usuários</li>
            <li>
              Vulnerabilidades ou instabilidades existentes nos sistemas dos
              usuários;
            </li>
            <li>Perímetro inseguro.</li>
          </ul>
        </li>
        <li>
          Em nenhuma hipótese, a instituição será responsável pela instalação no
          equipamento do usuário ou de terceiros, de códigos maliciosos (vírus,
          trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de
          quaisquer outros que venham a ser criados), em decorrência da
          navegação na internet pelo usuário.
        </li>
        <li>
          Zelar pela propriedade intelectual dos serviços da Intranet: todo
          material disponibilizado na plataforma, como marcas, logotipos,
          imagens, arquivos, textos, gráficos, sons, são de propriedade
          exclusiva do serviço, ou de terceiros que concederam autorização para
          tal utilização, sob pena de serem aplicadas as sanções da Lei nº
          9.610, de 1998, que protege os direitos autorais no Brasil.
        </li>
        <li>
          Não realizar qualquer tipo de ação que comprometa a estrutura do
          serviço, tais como, tentativa de invasão e hacker. Além disso, uma vez
          que, o sistema lida com dados pessoais, é proibido o uso de robôs que
          faça a varredura e coleta de tais informações na plataforma, como
          "scrapers".
        </li>
      </ul>

      <p>
        Segundo as políticas do campus, confere ao usuário os seguintes deveres:
      </p>

      <ul>
        <li>
          O cartão é para uso exclusivo do usuário. Em caso de uso por
          terceiros, o cartão poderá ser apreendido e o usuário deverá responder
          pelo fato.
        </li>
        <li>
          Para os casos de perda, furto, roubo ou extravio do cartão, o usuário
          deve comunicar imediatamente o fato ao Setor de Informática, para
          cancelamento imediato.
        </li>
        <li>
          A Coordenação do Restaurante Acadêmico não se responsabiliza por
          refeições (créditos) consumidas antes da comunicação de perda do
          cartão.
        </li>
      </ul>
    </>
  )
}

export default DeveresSection
