import { Section } from '@presentation/components/molecules'

const AcessoPlataformaSection: React.FC = () => {
  return (
    <>
      <Section title="Acesso a Plataforma" />

      <ul>
        <li>
          Acesso a plataforma é restrito apenas aos usuários previamente
          cadastrados no sistema (alunos, servidores e visitantes).
        </li>
        <li>
          A plataforma deverá estar disponível durante as vinte e quatro horas
          do dia, em pleno funcionamento. Todavia, podem ocorrer pequenas
          interrupções de forma temporária para ajustes, manutenção, mudança de
          servidores, falha técnicas ou por ordem de força maior, que podem
          deixar o serviço indisponível por tempo limitado.
        </li>
        <li>
          A instituição não se responsabiliza por nenhuma perda de oportunidade
          ou prejuízos causados pelo período de indisponibilidade do sistema.
        </li>
        <li>
          Em caso de manutenção que exigirem um tempo maior, a instituição irá
          informar previamente aos usuários da necessidade e do tempo previsto
          em que o serviço ficará offline.
        </li>
      </ul>
    </>
  )
}

export default AcessoPlataformaSection
