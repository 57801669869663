import {
  AcessoPlataformaSection,
  ConsideracoesSection,
  DefinicoesSection,
  DescricaoServicoSection,
  DeveresSection,
  DireitosSection,
  Header,
  Introduction,
  LegislacaoSection
} from './components'
import * as S from './styles'

const TermoUsoPage: React.FC = () => {
  return (
    <S.Container>
      <Header />
      <Introduction />
      <DefinicoesSection />
      <DescricaoServicoSection />
      <LegislacaoSection />
      <AcessoPlataformaSection />
      <DireitosSection />
      <DeveresSection />
      <ConsideracoesSection />
    </S.Container>
  )
}

export default TermoUsoPage
