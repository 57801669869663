import styled from 'styled-components'
import { Modal as BootstrapModal } from 'react-bootstrap'

export const Modal = styled(BootstrapModal)`
  .modal-content {
    background-color: ${props => props.theme.colors.background};

    .modal-title {
      display: flex;
      align-items: center;
      font-size: 1.42rem;
      color: ${props => props.theme.colors.primary};

      svg {
        margin-right: 0.625rem;
      }
    }
  }
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`

export const Content = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.backgroundContent};
  padding: 1rem;
`

export const ButtonGroup = styled.div`
  * {
    margin-left: 1.2rem;
  }
`
