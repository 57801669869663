import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup
    .string()
    .required('O campo é obrigatório')
    .max(100, 'O campo atingiu o limite máximo de 100 caracteres'),
  cursoTipo: yup.mixed().required('O campo é obrigatório'),
  eixo: yup.mixed().required('O campo é obrigatório'),
  externo: yup.mixed().required('O campo é obrigatório')
})
