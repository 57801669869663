import { lightTheme } from '@presentation/styles'
import styled from 'styled-components'

export const Container = styled.a`
  position: relative;
  height: 9.375rem;
  padding: 0.625rem;
  font-weight: 500;
  border-radius: 3px;
  color: ${props => props.theme.colors.white};

  background-color: ${lightTheme.colors.primary};
  text-align: center;

  &:hover {
    color: ${props => props.theme.colors.white};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 200ms ease-in;
    transform: scale(1.05);
  }
`

export const Image = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 1.25rem;
  margin: 0 auto;
  height: 4.375rem;
  width: 4.375rem;
`

export const Title = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.9375rem;
  margin: 0 auto;
  max-width: 130px;
`
