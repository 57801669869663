import { useState } from 'react'
import { useLocation } from 'react-router'

import { useAuth, useDashboard, useResponsive } from '@presentation/hooks'
import { SidebarMenuProps } from '@presentation/types/shared'
import SidebarNavigationMenuSubmenu from './submenu/sidebar-navigation-menu-submenu'

import * as S from './sidebar-navigation-menu.styles'

type MenuProps = {
  menu: SidebarMenuProps
}

type MenuDropdownProps = {
  menu: SidebarMenuProps
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const SidebarNavigationMenu: React.FC<MenuProps> = ({ menu }) => {
  const { isOpenSidebar } = useDashboard()
  const [isHover, setIsHover] = useState(false)

  return (
    <S.Container
      isCollapsible={!isOpenSidebar}
      isHover={isHover}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <S.NavLink
        to={{ pathname: menu.route?.path }}
        className={navData => (navData.isActive ? 'active' : '')}
      >
        <S.Icon>{menu.icon}</S.Icon>
        <S.Title>{menu.title}</S.Title>
      </S.NavLink>
    </S.Container>
  )
}

export const SidebarNavigationMenuDropdown: React.FC<MenuDropdownProps> = ({
  menu,
  isOpen,
  onOpen,
  onClose
}) => {
  const { canAccessRoute } = useAuth()
  const { isOpenSidebar, sidebar } = useDashboard()
  const { isMobile } = useResponsive()
  const [isHover, setIsHover] = useState(false)
  const { pathname } = useLocation()

  // Valida se o menu está ativo a partir do pathname
  const isActive = menu.items
    ? menu.items.some(item => pathname.startsWith(item.route.path))
    : false

  const handleClick = () => (isOpen ? onClose() : onOpen()) // toggle menu

  // Trata hover do menu lateral quando o sidebar estiver minimizado em tela desktop
  const handleHover = (hover = true) => {
    const hoverCondition = !isOpenSidebar && !isMobile
    if (hover) {
      hoverCondition && onOpen()
      setIsHover(true)
    } else {
      hoverCondition && onClose()
      setIsHover(false)
    }
  }

  return (
    <S.Container
      isCollapsible={!isOpenSidebar}
      isHover={isHover}
      isActive={isActive}
      isOpen={isOpen}
      onMouseOver={() => handleHover()}
      onMouseOut={() => handleHover(false)}
    >
      <S.Link onClick={() => handleClick()}>
        <S.Icon>{menu.icon}</S.Icon>
        <S.Title>{menu.title}</S.Title>

        <S.DropdownIcon>{sidebar.dropdownIcon}</S.DropdownIcon>
      </S.Link>

      {menu.items && (
        <S.Dropdown>
          {menu.items.map(
            (submenu, index) =>
              canAccessRoute(submenu.route.path) && (
                <SidebarNavigationMenuSubmenu submenu={submenu} key={index} />
              )
          )}
        </S.Dropdown>
      )}
    </S.Container>
  )
}
