import { DefaultTheme } from 'styled-components'
import { rgba } from 'polished'

import { Theme } from 'react-select'

export const customReactSelectStyles = (currentTheme: DefaultTheme) => {
  return {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: state.isFocused
        ? `0 0 0 0.25rem ${rgba(currentTheme.colors.primary, 0.25)}`
        : false
    })
  }
}

export const customReactSelectTheme = (
  currentTheme: DefaultTheme,
  theme: Theme
) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary25: rgba(currentTheme.colors.primary, 0.25), // option hover,
      primary50: rgba(currentTheme.colors.primary, 0.25), // option hover active,
      primary: currentTheme.colors.primary, // border focus e selected option
      neutral0: currentTheme.colors.backgroundInput, // background: ;
      neutral5: currentTheme.colors.background, // disabled background
      neutral10: currentTheme.colors.borderDisabledInput, // disabled border
      neutral20: rgba(currentTheme.colors.borderInput, 0.75), // border
      neutral30: currentTheme.colors.borderInput, // border hover
      neutral40: currentTheme.colors.textPrimary, // icon hover
      neutral50: rgba(currentTheme.colors.textPrimary, 0.75), // placeholder
      neutral80: currentTheme.colors.textPrimary // input text
    }
  }
}
