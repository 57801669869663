import { Form, Row } from 'react-bootstrap'

import { useForm } from '@presentation/hooks'
import { Input } from '@presentation/components/molecules'

const IndeferimentoForm: React.FC = () => {
  const { register, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Input
          as="textarea"
          label="Motivo da Recusa"
          name="motivo"
          register={register}
          error={formState.errors.motivo?.message}
          required
          placeholder="Escreva o motivo da recusa do comprovante"
        />
      </Row>
    </Form>
  )
}

export default IndeferimentoForm
