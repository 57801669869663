import { TipoLocalForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  id: string
  onEdit: () => void
}

const EditTipoLocalModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    path: '/tipos-local',
    formId: 'edit-tipo-local',
    nameForTitle: 'Tipo de Local',
    validationSchema,
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <TipoLocalForm />
    </EditModal>
  )
}

export default EditTipoLocalModal
