import { LinksRapidosService } from '@core/services'
import { LinksRapidosProvider } from '@presentation/contexts'
import { ComponentType, memo } from 'react'

export const withLinksRapidos = (Component: ComponentType) => {
  const service = new LinksRapidosService()

  return memo(() => {
    return (
      <LinksRapidosProvider service={service}>
        <Component />
      </LinksRapidosProvider>
    )
  })
}
