import ReactDOM from 'react-dom'
import { pdfjs } from 'react-pdf'

import App from '@main/app'
import { ThemeManagerProvider } from '@presentation/contexts'

import './presentation/styles/overrides/custom-bootstrap.scss'
import 'rc-slider/assets/index.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

ReactDOM.render(
  <ThemeManagerProvider>
    <App />
  </ThemeManagerProvider>,
  document.getElementById('root')
)
