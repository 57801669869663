import { LegacyRef, useRef } from 'react'
import { Document, Page } from 'react-pdf'

type Props = {
  file: File
}

const PDFThumbnail: React.FC<Props> = ({ file }) => {
  const canvasRef = useRef<HTMLCanvasElement>()

  return (
    <Document
      file={file}
      loading={'Carregando PDF...'}
      error={'Não foi possível carregar o PDF.'}
      noData={'Nenhum PDF encontrado.'}
    >
      <Page
        canvasRef={canvasRef as LegacyRef<HTMLCanvasElement>}
        pageNumber={1}
        renderAnnotationLayer={false}
      />
    </Document>
  )
}

export default PDFThumbnail
