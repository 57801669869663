import axios, { AxiosInstance } from 'axios'

import { LocalStorage } from '@core/gateways'
import { env } from '@main/configs'

export const setupIntranetApiClient = (): AxiosInstance => {
  const accessToken = LocalStorage.get({ key: 'accessToken' })

  const api = axios.create({
    baseURL: env.api.baseUri,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  return api
}
