import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

const EmpresaSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Empresa"
      name="empresa"
      path={`/empresas?sort_by=razao`}
      control={control}
      error={formState?.errors.empresa?.message}
      placeholder="Selecione aqui"
      isRequired
      getOptionLabel={option => option.razao}
      getOptionValue={option => option.id}
      searchKey="razao"
    />
  )
}

export default EmpresaSelectApi
