import {
  PessoaCategoriaAttr,
  PessoaSituacaoAttr,
  PessoaTipoAttr
} from '@core/models/entities'

export const getSituacaoCategoriaByTipoAttr = (tipoAttr: PessoaTipoAttr) => {
  switch (tipoAttr) {
    case 'tecnico':
    case 'integrado':
    case 'graduacao':
      return {
        situacao: PessoaSituacaoAttr.Aluno,
        categoria: PessoaCategoriaAttr.Bolsista
      }

    case 'mestrado':
    case 'extensao':
      return {
        situacao: PessoaSituacaoAttr.Aluno,
        categoria: undefined
      }

    case 'terceirizado':
      return {
        situacao: PessoaSituacaoAttr.Other,
        categoria: PessoaCategoriaAttr.Terceirizado
      }

    case 'servidor':
      return {
        situacao: PessoaSituacaoAttr.Other,
        categoria: PessoaCategoriaAttr.Servidor
      }

    case 'estagiario':
      return {
        situacao: PessoaSituacaoAttr.Other,
        categoria: PessoaCategoriaAttr.Estagiario
      }

    default:
      return {
        situacao: undefined,
        categoria: undefined
      }
  }
}
