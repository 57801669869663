import { media } from '@presentation/styles'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin: 1rem 0;

  button {
    margin: 0 auto;
    padding: 0.5rem 5.2rem;
  }

  ${media.greaterThan('mobile')`
    display: none;
  `}
`
