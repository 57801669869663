import { memo } from 'react'
import { Helmet } from 'react-helmet'

import { env } from '@main/configs'
import { TitleProps } from '@presentation/types/components/atoms'

const Title: React.FC<TitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>
        {title} | {env.app.name}
      </title>
    </Helmet>
  )
}

export default memo(Title)
