import Swal from 'sweetalert2'

import { Audio } from '@core/gateways'
import {
  CallAlert,
  CallErrorAlert,
  CallSuccessAlert
} from '@core/types/gateways'
import { SuccessAlertAudio, ErrorAlertAudio } from '@presentation/assets/audio'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary'
  },
  buttonsStyling: false
})

export const call: CallAlert = ({
  type,
  title,
  description,
  disableAudio,
  onConfirm
}) => {
  void swalWithBootstrapButtons
    .fire({
      icon: type,
      title,
      text: description,
      allowOutsideClick: false,
      didOpen: async () => {
        if (!disableAudio) {
          if (type === 'success') {
            await Audio.play({ filename: SuccessAlertAudio })
          } else {
            await Audio.play({ filename: ErrorAlertAudio })
          }
        }
      }
    })
    .then(isConfirm => {
      if (isConfirm) {
        if (onConfirm) {
          onConfirm()
        }
      }
    })
}

export const callSuccess: CallSuccessAlert = input => {
  return call({ type: 'success', ...input })
}

export const callError: CallErrorAlert = input => {
  return call({ type: 'error', ...input })
}
