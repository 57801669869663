import { Tab } from 'react-bootstrap'

import { TabsProps } from '@presentation/types/components/molecules'

import * as S from './styles'

const Tabs: React.FC<TabsProps> = ({ items, ...tabsProps }) => {
  return (
    <S.Container {...tabsProps}>
      {items.map(({ component, ...tabProps }) => (
        <Tab {...tabProps}>{component}</Tab>
      ))}
    </S.Container>
  )
}

export default Tabs
