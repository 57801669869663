import styled from 'styled-components'
import { rgba } from 'polished'

export const Container = styled.div`
  border: 1px solid ${props => rgba(props.theme.colors.textPrimary, 0.125)};
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.background};
  height: 100%;
  width: 85%;
  margin: 0 auto;
  position: relative;
`

export const Picture = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
`

export const PictureWrapper = styled.div`
  position: relative;
`

export const PictureOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  opacity: 0;
  transition: opacity 0.25s;

  & > * {
    transform: translateY(20px);
    transition: transform 0.25s;
  }

  &:hover {
    opacity: 1;
    backdrop-filter: blur(5px);

    & > * {
      transform: translateY(0);
    }
  }
`
