import { useProfile } from '@presentation/hooks'
import { formatProfileName } from '@presentation/utils'

import * as S from './styles'

export type ProfileProps = {
  variant?: 'primary' | 'secondary'
}

const Profile: React.FC<ProfileProps> = ({ variant = 'primary' }) => {
  const { currentAccount, photo } = useProfile()

  return (
    <S.Container variant={variant}>
      <S.ProfileImage src={photo} alt="Foto de Perfil" />
      <S.ProfileInfo>
        <S.ProfileName>
          {currentAccount && formatProfileName(currentAccount.nome)}
        </S.ProfileName>
        <S.ProfileRole>{currentAccount?.pessoa_tipo}</S.ProfileRole>
      </S.ProfileInfo>
    </S.Container>
  )
}

export default Profile
