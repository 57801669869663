import { useContext } from 'react'

import { AuthContext } from '@presentation/contexts'
import { useLocation } from 'react-router-dom'
import { PermissaoTipo } from '@core/models/entities'

export const useAuth = () => {
  const location = useLocation()
  const { getCurrentAccount } = useContext(AuthContext)

  const currentAccount = getCurrentAccount()

  const hasPermission = (permissionType: PermissaoTipo) => {
    if (currentAccount) {
      const matchPermissions = currentAccount.permissoes.filter(permissao => {
        const hasAccessRoute = location.pathname.startsWith(permissao.nome)

        return hasAccessRoute
      })

      if (matchPermissions.length > 0) {
        const routePermission = matchPermissions[0]
        const routePermissionTypes = routePermission.tipo.split('')

        if (routePermissionTypes.includes(permissionType)) {
          return true
        }
      }
    }

    return false
  }

  const canAccessRoute = (path: string) => {
    if (currentAccount) {
      const canAccess = currentAccount.permissoes.some(
        permissao => permissao.nome === path
      )

      return canAccess
    }

    return false
  }

  return {
    getCurrentAccount,
    hasPermission,
    canAccessRoute
  }
}
