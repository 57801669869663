import { media } from '@presentation/styles'
import { Col } from 'react-bootstrap'
import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.25rem;
  padding: 0.75rem 0 0.75rem 0.75rem;
`

export const ButtonGroup = styled.div`
  * {
    margin-left: 1.2rem;
  }
`

export const PictureCol = styled(Col)`
  margin-bottom: 1.5rem;

  ${media.greaterThan('tablet')`
    margin-bottom: 0rem;
  `}
`
