import { rgba } from 'polished'
import { Card as BsCard } from 'react-bootstrap'
import styled from 'styled-components'

export const Card = styled(BsCard)`
  background-color: ${props => props.theme.colors.backgroundContent};
`

export const CardHeader = styled(BsCard.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1rem;
  height: 3.5rem;

  border-bottom-width: 1px;
  margin-bottom: 0px;

  background-color: ${props => props.theme.colors.backgroundContent};
  border-bottom: 1px solid
    ${props => rgba(props.theme.colors.textPrimary, 0.125)};
`

export const CardHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 1.1rem;
  font-weight: 400;
  text-transform: capitalize;
`

export const CardHeaderIconWrapper = styled.div`
  color: ${props => props.theme.colors.primary};
  font-size: 1.2rem;
  margin-right: 0.8rem;

  * {
    margin: 0 auto;
  }
`

export const CardBody = styled(BsCard.Body)`
  padding: 1rem 1rem;
`
