import ReactPlayer from 'react-player/lazy'
import { AiOutlineMail as EmailIcon } from 'react-icons/ai'

import { Page } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import { Section } from '@presentation/components/molecules'

import * as S from './styles'

const EmailAcademicoPage: React.FC = () => {
  const pageConfigs: PageProps = {
    icon: <EmailIcon />,
    title: 'E-mail Acadêmico',
    description: 'Página de ajuda sobre o e-mail acadêmico.'
  }

  return (
    <Page {...pageConfigs}>
      <S.Container>
        <p>
          O e-mail acadêmico é um serviço gratuito, oferecido pela DGTI -
          Diretoria de Tecnologia da Informação, em parceria com a Google, para
          fornecer e-mail com domínio "@ifce.edu.br" para aos estudantes e para
          professores do IFCE que desejam utilizar os serviços do Google
          Classroom.
        </p>
        <Section title="Como criar ?">
          <p>
            O aluno deve seguir as orientações desse vídeo para criar/cadastrar
            tanto seu email acadêmico como o e-mail secundário (exigido para
            troca de senha):
          </p>

          <S.PlayerWrapper>
            <ReactPlayer
              url="https://youtu.be/qFGdHjwSu1k"
              controls
              width="100%"
              height="100%"
            />
          </S.PlayerWrapper>

          <p>
            Após criado/cadastrado seus e-mails, o aluno deverá redefinir sua
            senha, seguindo as orientações desse vídeo:
          </p>

          <S.PlayerWrapper>
            <ReactPlayer
              url="https://youtu.be/JSBXF8r-BDQ"
              controls
              width="100%"
              height="100%"
            />
          </S.PlayerWrapper>

          <p>
            <strong>Obs.:</strong> O login padrão do e-mail será aquele definido
            pelo aluno na hora da criação do e-mail acadêmico pelo SUAP. Da
            mesma forma é a senha: será a mesma que acabou de ser redefinida
            pelo aluno no SUAP.
          </p>
        </Section>
        <Section title="Como acessar ?">
          <p>Aprenda a acessar o e-mail institucional pelo celular:</p>

          <S.PlayerWrapper>
            <ReactPlayer
              url="https://youtu.be/k2CVVYzJ03c"
              controls
              width="100%"
              height="100%"
            />
          </S.PlayerWrapper>

          <p>Aprenda a acessar o e-mail institucional pelo computador:</p>

          <S.PlayerWrapper>
            <ReactPlayer
              url="https://youtu.be/Ds0IAIk42_s"
              controls
              width="100%"
              height="100%"
            />
          </S.PlayerWrapper>
        </Section>
      </S.Container>
    </Page>
  )
}

export default EmailAcademicoPage
