import styled from 'styled-components'

import { media } from '@presentation/styles'
import { rgba } from 'polished'

export const Container = styled.footer`
  border-top: 1px solid ${props => rgba(props.theme.colors.textPrimary, 0.4)};

  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;

  padding: 0.75rem 1.5rem;

  ${media.lessThan('mobile')`
    padding: 0.75rem 0;
  `}

  .row {
    flex-direction: column;

    .col {
      text-align: center;

      &:nth-child(2) {
        display: none;
      }
    }

    ${media.greaterThan('mobile')`
      flex-direction: row;

      .col {
        &:nth-child(1) {
          text-align: left;
        }

        &:nth-child(2) {
          display: block;

          svg {
            width: 2rem;
          }
        }

        &:nth-child(3) {
          text-align: right;
        }
      }
    }
    `}
  }
`
