import { media } from '@presentation/styles'
import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 1rem;

  p,
  li {
    line-height: 1.5;
    text-align: justify;
  }

  p:last-child {
    margin-bottom: 0rem;
  }

  ${media.greaterThan('mobile')`
    margin-bottom: 2.25rem;
  `}
`
