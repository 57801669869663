import React, { ComponentType } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '@presentation/hooks'

type Props = {
  component: ComponentType
}

const ProtectedRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const location = useLocation()
  const { getCurrentAccount } = useAuth()

  const currentAccount = getCurrentAccount()

  if (currentAccount === undefined) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  const permissoes = currentAccount.permissoes

  const canAccess = permissoes.some(permissao => {
    const hasAccessRoute = location.pathname.startsWith(permissao.nome)

    return hasAccessRoute
  })

  return canAccess ? (
    <RouteComponent />
  ) : (
    <Navigate replace to="/pagina-nao-encontrada" />
  )
}

export default ProtectedRoute
