import { PessoaTipo } from '@core/models/entities'
import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

type Props = {
  selectedTipo: PessoaTipo
}

const CursoSelectApi: React.FC<Props> = ({ selectedTipo }) => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      key={JSON.stringify(selectedTipo)}
      label="Curso"
      name="curso"
      path={`/cursos?curso_tipo_slug=${selectedTipo.attr}&sort_by=nome`}
      control={control}
      error={formState?.errors.curso?.message}
      isRequired
    />
  )
}

export default CursoSelectApi
