import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1.7rem;
    fill: ${props => props.theme.colors.white};
  }
`

export const BrandText = styled.span`
  font-family: 'roboto', 'Karla', Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;

  margin-left: 0.4rem;
  color: ${props => props.theme.colors.white};
`
