import { useState, useEffect, useCallback } from 'react'
import {
  FaCalendar as CalendarIcon,
  FaUsers as UsersIcon
} from 'react-icons/fa'
import { GiTurnstile as TransitoIcon } from 'react-icons/gi'
import { Col, Row } from 'react-bootstrap'
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder
} from '@microsoft/signalr'

import { AdministracaoService } from '@core/services'
import { Alert, Audio, LocalStorage } from '@core/gateways'
import { PageProps } from '@presentation/types/components/templates'
import { StatisticWidget } from '@presentation/components/templates'
import { useAccessToken } from '@presentation/hooks'
import { formatDate, toTitleCase } from '@presentation/utils'
import { ErrorAlertAudio } from '@presentation/assets/audio'
import { Header } from '@presentation/components/templates/pages/Page/components'
import { BasicTable, Card } from '@presentation/components/organisms'
import { TableColumn } from '@presentation/types/components/organisms'
import { env } from '@main/configs'

import {
  MessageStatus,
  ProfileDetails,
  ProfileDetailsModal
} from './components'
import {
  PessoaData,
  TransitoAutorizadoMessage,
  TransitoNaoAutorizadoMessage,
  TransitoData,
  TransitoStatus,
  UltimosTransitosMessage
} from './types'
import * as S from './styles'
import { Title } from '@presentation/components/atoms'

const MAX_ULTIMOS_TRANSITOS_QUANTITY = 6

const TransitoCatracaPage: React.FC = () => {
  const { accessToken } = useAccessToken()
  const [connection, setConnection] = useState<null | HubConnection>(null)
  const [profilePhoto, setProfilePhoto] = useState<File>()
  const [pessoaData, setPessoaData] = useState<PessoaData>()
  const [transitoStatus, setTransitoStatus] = useState<TransitoStatus>(
    TransitoStatus.NenhumTransito
  )
  const [transitoData, setTransitoData] = useState<TransitoData>()
  const [errors, setErrors] = useState<string[]>([])
  const [ultimosTransitos, setUltimosTransitos] = useState<PessoaData[]>([])

  useEffect(() => {
    const transitoConnection = new HubConnectionBuilder()
      .withUrl(`${env.hubs.transitoCatraca}`, {
        accessTokenFactory: () => accessToken,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build()

    setConnection(transitoConnection)
  }, [])

  useEffect(() => {
    const handleConnection = async () => {
      if (connection) {
        try {
          await connection.start()

          void invokeUltimosTransitos()

          connection.on(
            'TransitoAutorizado',
            (message: TransitoAutorizadoMessage) => {
              void handleProfilePhoto(message.id)
              handlePessoaData(message)
              handleTransitoData(message)
              setTransitoStatus(TransitoStatus.Autorizado)

              setUltimosTransitos(ultimosTransitos => [
                message as PessoaData,
                ...ultimosTransitos
              ])
            }
          )

          connection.on(
            'TransitoNaoAutorizado',
            (message: TransitoNaoAutorizadoMessage) => {
              void handleProfilePhoto(message.pessoa.id)
              handlePessoaData(message.pessoa)
              setErrors(message.erros)
              setTransitoStatus(TransitoStatus.NaoAutorizado)

              void Audio.play({ filename: ErrorAlertAudio })
            }
          )

          connection.on(
            'UltimosTransitos',
            (message: UltimosTransitosMessage) => {
              console.log(message)
              handleTransitoData({
                data: message.quantidadeTransito.data,
                totalTransitos: message.quantidadeTransito.quantidade
              })
              setUltimosTransitos(message.transitos)
            }
          )
        } catch (e) {
          Alert.callError({
            title: 'Erro Inesperado',
            description:
              'Tente novamente, caso o problema persista, contate o administrador do sistema.'
          })
        }
      }
    }

    void handleConnection()
  }, [connection])

  const invokeUltimosTransitos = useCallback(() => {
    if (connection) {
      void connection.invoke('UltimosTransitos', MAX_ULTIMOS_TRANSITOS_QUANTITY)
    }
  }, [connection])

  const handleProfilePhoto = async (id: string) => {
    setProfilePhoto(undefined)
    const profilePhoto = await AdministracaoService.getProfilePhoto({
      id,
      token: LocalStorage.get({ key: 'accessToken' })
    })

    setProfilePhoto(profilePhoto)
  }

  const handlePessoaData = (pessoa: PessoaData) => {
    setPessoaData({
      ...pessoa,
      nome: toTitleCase(pessoa.nome)
    })
  }

  const handleTransitoData = (transito: TransitoData) => {
    setTransitoData({ ...transito, data: formatDate(transito.data) })
  }

  const ultimosTransitosColumns: Array<TableColumn<PessoaData>> = [
    {
      name: 'Identificação',
      selector: pessoa => pessoa.identificacao,
      center: true,
      sortable: false
    },
    {
      name: 'Nome',
      selector: pessoa => toTitleCase(pessoa.nome),
      center: true,
      sortable: false
    },
    {
      name: 'Tipo',
      selector: pessoa => pessoa.tipo,
      center: true,
      sortable: false
    },
    {
      name: 'Ações',
      cell: pessoa => <ProfileDetailsModal {...pessoa} />,
      center: true,
      grow: 0.5
    }
  ]

  const pageConfigs: PageProps = {
    title: 'Trânsito da Catraca',
    description: 'Página de controle do trânsito da catraca das pessoas.',
    icon: <TransitoIcon />
  }

  return (
    <>
      <Title title="Trânsito da Catraca" />
      <Header
        isLoading={false}
        title={pageConfigs.title}
        description={pageConfigs.description}
        icon={pageConfigs.icon}
      />

      <S.Content>
        <Row>
          <Col lg={6} xl={4}>
            <Card>
              <ProfileDetails {...pessoaData} photo={profilePhoto} />
            </Card>
          </Col>
          <Col lg={6} xl={8}>
            <Row>
              <Col>
                <MessageStatus status={transitoStatus} errors={errors} />
              </Col>
            </Row>
            <Row>
              <Col xl={6} className="mb-3">
                <StatisticWidget
                  icon={UsersIcon}
                  label="Número de Trânsitos"
                  value={
                    transitoData
                      ? transitoData.totalTransitos.toString()
                      : 'N/A'
                  }
                />
              </Col>
              <Col xl={6} className="mb-3">
                <StatisticWidget
                  icon={CalendarIcon}
                  label="Data do Trânsito"
                  value={transitoData ? transitoData.data : 'N/A'}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card title="Últimos Trânsitos">
                  <BasicTable
                    columns={ultimosTransitosColumns}
                    data={ultimosTransitos.slice(
                      0,
                      MAX_ULTIMOS_TRANSITOS_QUANTITY - 1
                    )}
                    pagination={false}
                    keyField={'data'}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </S.Content>
    </>
  )
}

export default TransitoCatracaPage
