import * as yup from 'yup'

export const validationSchema = yup.object({
  responsavel: yup.object().required('O campo é obrigatório'),
  nome: yup
    .string()
    .required('O campo é obrigatório')
    .max(100, 'O campo atingiu o limite máximo de 100 caracteres'),
  email: yup
    .string()
    .email('Digite um e-mail válido')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres'),
  observacoes: yup
    .string()
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres')
})
