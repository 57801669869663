import { useCallback, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'

import { TableProps } from '@presentation/types/components/organisms'

import { Loading, NoData } from './components'
import * as S from './styles'
import { useSearchParams } from 'react-router-dom'

export const Table: React.FC<TableProps> = ({
  data,
  columns,
  apiConfigs,
  isPaginationVisible = true
}) => {
  const [perPage, setPerPage] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchParams] = useSearchParams()

  const mapQueries = useCallback(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])

  const handlePageChange = async (page: number) => {
    if (apiConfigs) {
      await apiConfigs.fetchData({ options: { page: page, ...mapQueries() } })
    }
    setCurrentPage(page)
  }

  const handlePerPageChange = async (rowsPerPage: number, page: number) => {
    if (apiConfigs) {
      await apiConfigs.fetchData({
        options: { page, perPage: rowsPerPage, ...mapQueries() }
      })
    }
    setPerPage(rowsPerPage)
  }

  const handleSortChange = (
    selectedColumn: TableColumn<any>,
    direction: string
  ) => {
    apiConfigs?.fetchData({
      options: {
        page: currentPage,
        perPage: perPage,
        sortBy: selectedColumn.name?.toString().toLowerCase(),
        sortDir: direction,
        ...searchParams
      }
    })
  }

  return (
    <S.Container>
      <DataTable
        data={data}
        columns={columns}
        striped
        pagination={isPaginationVisible}
        paginationServer={apiConfigs !== undefined}
        paginationTotalRows={apiConfigs?.totalItems}
        paginationDefaultPage={currentPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        paginationComponentOptions={{
          rowsPerPageText: 'Resultados por página',
          rangeSeparatorText: 'de'
        }}
        paginationPerPage={20}
        sortServer={apiConfigs !== undefined}
        onSort={apiConfigs && handleSortChange}
        noDataComponent={<NoData />}
        progressComponent={<Loading />}
        progressPending={apiConfigs?.isFetching}
      />
    </S.Container>
  )
}

export default Table
