import { useDashboard } from '@presentation/hooks'
import { SidebarSubmenuProps } from '@presentation/types/shared'

import * as S from './sidebar-navigation-menu-submenu.styles'

type Props = {
  submenu: SidebarSubmenuProps
}

const SidebarNavigationMenuSubmenu: React.FC<Props> = ({ submenu }) => {
  const { isOpenSidebar } = useDashboard()

  return (
    <S.NavLink
      to={{ pathname: submenu.route.path }}
      className={navData =>
        navData.isActive ? 'active' : !isOpenSidebar ? 'collapsible' : ''
      }
    >
      {submenu.title}
    </S.NavLink>
  )
}

export default SidebarNavigationMenuSubmenu
