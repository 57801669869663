import { Control } from 'react-hook-form'

import { Select } from '@presentation/components/molecules'
import { SelectOption } from '@presentation/types/components/molecules'

type Props = {
  name: string
  options: SelectOption[]
  control: Control<any>
}

const DescriptionSelect: React.FC<Props> = props => {
  return <Select {...props} />
}

export default DescriptionSelect
