import { useCallback, useState } from 'react'

import { Alert, HttpClient } from '@core/gateways'
import { HttpStatusCode } from '@core/types/gateways'
import { setupIntranetApiClient } from '@core/proxies/apis'
import { FetchProps, UseFetch } from '@presentation/types/hooks'
import { parseToRequest } from '@presentation/utils'

export const useFetch: UseFetch = ({ path, options, queries }) => {
  const [data, setData] = useState<any[]>([])
  const [isFetching, setIsFetching] = useState(true)
  const [totalItems, setTotalItems] = useState(0)

  const getInitialParams = () => {
    return {
      ...options,
      perPage: options?.perPage ?? 20,
      ...queries
    }
  }

  const mapParamsToRequest = (params: any) => {
    const { sortBy, sortDir, perPage, ...rest } = params

    const mergedParams = Object.assign(
      {},
      {
        per_page: perPage ?? 20,
        sort_by: sortBy,
        sort_dir: sortDir
      },
      queries,
      { ...rest }
    )

    return parseToRequest(mergedParams)
  }

  const handleRequest = async (params: any) => {
    setIsFetching(true)

    const response = await HttpClient.of(setupIntranetApiClient()).request({
      url: `${path}`,
      method: 'GET',
      params: mapParamsToRequest(params)
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        setData(response.body.data.items)
        setTotalItems(response.body.data.pagination.total)
        break

      default:
        Alert.call({
          type: 'error',
          title: 'Falha ao Carregar Dados',
          description:
            'Tente novamente mais tarde. Caso o problema persista, contate alguém do suporte técnico.'
        })
    }

    setIsFetching(false)
  }

  const fetchData = useCallback(async (input?: FetchProps) => {
    console.log(input)
    const params = Object.assign(
      {},
      getInitialParams(),
      options,
      queries,
      input?.options,
      input?.queries
    )

    await handleRequest(params)
  }, [])

  return {
    data,
    isFetching,
    totalItems,
    fetchData
  }
}
