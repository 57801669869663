import React from 'react'
import { Button, ButtonProps, Spinner } from 'react-bootstrap'

type Props = ButtonProps & {
  isValid?: boolean
  isSubmitting?: boolean
}

const SubmitButton: React.FC<Props> = ({
  isValid = true,
  isSubmitting,
  children,
  ...rest
}) => {
  return (
    <Button type="submit" disabled={!isValid || isSubmitting} {...rest}>
      {isSubmitting ? (
        <Spinner
          animation="border"
          style={{ width: '1rem', height: '1rem', margin: 0 }}
        />
      ) : (
        children
      )}
    </Button>
  )
}

export default SubmitButton
