import { useEffect, useState } from 'react'
import { ActionMeta, OnChangeValue } from 'react-select'
import { useSearchParams } from 'react-router-dom'

import { UseFilter } from '@presentation/types/hooks'
import { SelectOption } from '@presentation/types/components/molecules'
import { Filter } from '@presentation/types/components/organisms'

export const useFilter: UseFilter = ({
  filters,
  fetchData,
  defaultQueries
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getTypeOptions = () => {
    return filters.map(filter => filter.type)
  }

  const [typeOptions, setTypeOptions] = useState<SelectOption[]>(
    getTypeOptions()
  )
  const [selectedFilter, setSelectedFilter] = useState<Filter>()
  const [appliedOptions, setAppliedOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    const newTypeOptions: SelectOption[] = []
    const newAppliedOptions: SelectOption[] = []

    const queries = Object.fromEntries(searchParams)
    const params = Object.assign({}, defaultQueries, queries)

    const typeOptions = getTypeOptions()
    typeOptions.forEach(typeOption => {
      if (Object.keys(queries).includes(typeOption.value)) {
        newAppliedOptions.push(typeOption)
      } else {
        newTypeOptions.push(typeOption)
      }
    })

    setTypeOptions(newTypeOptions)
    setAppliedOptions(newAppliedOptions)

    setSelectedFilter(undefined)

    void fetchData({ queries: params })
  }, [searchParams])

  /**
   *
   * Utility functions
   *
   */

  const findFilterByValue = (value: string) => {
    const locatedFilter = filters
      .filter(filter => {
        return filter.type.value === value
      })
      .shift()

    if (locatedFilter) return locatedFilter
  }

  /**
   *
   * Export functions
   *
   */

  const onTypeOptionChange = (
    option: OnChangeValue<SelectOption, false>,
    triggeredAction: ActionMeta<SelectOption>
  ) => {
    if (triggeredAction.action === 'clear') {
      setSelectedFilter(undefined)
    } else {
      if (option) {
        const filter = findFilterByValue(option.value)

        if (filter) {
          setSelectedFilter(filter)
        }
      }
    }
  }

  const applyFilter = (data: any) => {
    if (selectedFilter) {
      const filterKey = selectedFilter.type.value
      let filterValue = data[filterKey]

      if (filterValue instanceof Array) {
        filterValue = filterValue.map(value => value.id)
      }

      searchParams.set(filterKey, filterValue)
      setSearchParams(searchParams)
    }
  }

  const removeFilter = (value: string) => {
    searchParams.delete(value)
    setSearchParams(searchParams)
  }

  const removeAllFilters = () => {
    const appliedValues = appliedOptions.map(typeOption => typeOption.value)

    appliedValues.forEach(appliedValue => searchParams.delete(appliedValue))
    setSearchParams(searchParams)
  }

  return {
    typeOptions,
    selectedFilter,
    appliedOptions,
    isAllFiltersHaveBeenApplied: typeOptions.length === 0,
    applyFilter,
    removeFilter,
    removeAllFilters,
    onTypeChange: onTypeOptionChange
  }
}
