import { ReactivateModal } from '@presentation/components/templates'

type Props = {
  id: string
  onReactivate: () => void
}

const ReactivateSetorModal: React.FC<Props> = ({ id, onReactivate }) => {
  const modalConfigs = {
    id,
    path: '/setores',
    nameForTitle: 'Setor',
    onReactivate
  }

  return <ReactivateModal {...modalConfigs} />
}

export default ReactivateSetorModal
