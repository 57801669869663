import { RiUserSearchFill as PersonSearchIcon } from 'react-icons/ri'

import { PermissaoTipo, PessoaNaoEnviado } from '@core/models/entities'
import { ComprovanteStatus } from '@core/types/services'
import { useAuth, useFetch } from '@presentation/hooks'
import { toTitleCase } from '@presentation/utils'
import { Tag } from '@presentation/components/atoms'
import {
  PessoaIdentificacoesHead,
  PessoaIdentificacoesCell
} from '@presentation/components/shared'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import { LiberarComprovanteModal } from './components'

const NaoEnviadosPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/gerenciar-comprovantes-covid19/nao-enviados',
    options: { sortBy: 'nome' },
    queries: { comprovante_covid19: 0 }
  })

  const pageConfigs: PageProps = {
    icon: <PersonSearchIcon />,
    title: 'Não Enviados',
    description:
      'Página para busca de pessoas que não enviaram o comprovante de vacina.',
    actions: []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'identificacao',
        label: 'Identificação'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'comprovante_covid19',
        label: 'Situação'
      },
      description: {
        type: 'select',
        configs: {
          options: [
            {
              label: 'Não Enviado',
              value: 0
            },
            {
              label: 'Deferido Manualmente',
              value: 1
            }
          ]
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {},
    hasStatus: false
  }

  const columns: Array<TableColumn<PessoaNaoEnviado>> = [
    {
      name: 'Nome',
      selector: data => toTitleCase(data.nome),
      center: true,
      grow: 1.5
    },
    {
      name: <PessoaIdentificacoesHead />,
      cell: data => <PessoaIdentificacoesCell items={data.identificacoes} />,
      center: true,
      grow: 1.5
    },
    {
      name: 'Situação',
      cell: data => {
        return data.comprovante_covid19 ? (
          <Tag bg="success">{ComprovanteStatus.DeferidoManualmente}</Tag>
        ) : (
          <Tag bg="neutral">{ComprovanteStatus.NaoEnviado}</Tag>
        )
      },
      center: true
    },
    {
      name: 'Ações',
      cell: data =>
        hasPermission(PermissaoTipo.Escrita) &&
        !data.comprovante_covid19 && (
          <LiberarComprovanteModal
            pessoaId={data.id}
            pessoaName={toTitleCase(data.nome)}
            onRelease={fetchData}
          />
        ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default NaoEnviadosPage
