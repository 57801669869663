import { useEffect, useState } from 'react'
import { Area, Point } from 'react-easy-crop/types'

import { UseCrop } from '@presentation/types/hooks'
import { getCroppedImage } from '@presentation/utils'

export const useCrop: UseCrop = ({ image }) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
  const [croppedImage, setCroppedImage] = useState<File>()
  const [isTriggered, setIsTriggered] = useState(false)

  useEffect(() => {
    if (isTriggered) {
      const handleCropImage = async () => {
        try {
          if (croppedAreaPixels) {
            const { file } = await getCroppedImage({
              url: image,
              croppedArea: croppedAreaPixels
            })
            setCroppedImage(file)
          }
        } catch (error) {
          console.error(error)
        }
      }

      void handleCropImage()

      setIsTriggered(false)
    }
  }, [isTriggered])

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const cropImage = () => {
    setIsTriggered(true)
  }

  return {
    crop,
    zoom,
    croppedImage,
    cropImage,
    onCropChange: setCrop,
    onZoomChange: setZoom,
    onCropComplete
  }
}
