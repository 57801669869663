import styled from 'styled-components'
import { rgba } from 'polished'

export const Container = styled.div`
  margin-bottom: 1.5rem;
`

export const Header = styled.h3`
  font-weight: 600;
  font-size: 1.3rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid ${props => rgba('gray', 0.15)};
`
