import styled from 'styled-components'

import { Brand as BrandComponent } from '@presentation/components/molecules'
import { media } from '@presentation/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.4rem;
  min-height: ${props => props.theme.shared.navbar.height};

  ${media.lessThan('mobile')`
    padding: 1.5rem 0 0.8rem 0;
  `}
`

export const Brand = styled(BrandComponent)`
  position: relative;
  margin-right: auto;
`
