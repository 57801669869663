import { Account } from '@core/models/entities'
import { HttpStatusCode } from '@core/types/gateways'
import { Authenticate, Logout } from '@core/types/services'
import { Cryptography, HttpClient, LocalStorage } from '@core/gateways'
import {
  InvalidCredentialsError,
  NotAcceptTermsError,
  UnexpectedError
} from '@core/models/errors'
import { setupIntranetApiClient } from '@core/proxies/apis'

export const authenticate: Authenticate = async ({ identificacao, senha }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/auth/login`,
    method: 'POST',
    body: {
      identificacao,
      senha
    }
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      const accessToken = response.body.data.token

      const decodedToken = Cryptography.decodeToken({
        token: accessToken
      }) as Account

      if (!decodedToken.aceite_termos_uso) {
        throw new NotAcceptTermsError(accessToken)
      }

      return accessToken
    case HttpStatusCode.unauthorized:
      throw new InvalidCredentialsError()
    default:
      throw new UnexpectedError()
  }
}

export const logout: Logout = () => {
  LocalStorage.remove({ key: 'accessToken' })
}
