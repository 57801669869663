import * as S from './styles'

export const InternetTabContent: React.FC = () => {
  return (
    <S.Container>
      <li>
        Acesse o portal{' '}
        <a href="https://conectesus-paciente.saude.gov.br/" target="_blank">
          Conecte SUS
        </a>
        ;
      </li>
      <li>
        Será preciso informar seu CPF para fazer login no portal{' '}
        <strong>gov.br</strong>. Caso você nunca tenha utilizado a plataforma,
        realize as próximas etapas para cadastro de sua conta;
      </li>
      <li>
        Na página inicial da plataforma, vá em Vacinas na seção Ações rápidas ou
        clique no menu Histórico na barra lateral. Logo após, clique no nome do
        fabricante da vacina, que aparece acima das datas em que você recebeu as
        doses;
      </li>
      <li>
        Clique no botão <strong>Certificado de Vacinação Covid-19</strong> e
        gere o PDF pressionando o ícone no canto superior direito da
        pré-visualização do documento.
      </li>
    </S.Container>
  )
}

export const AppTabContent: React.FC = () => {
  return (
    <S.Container>
      <li>
        Abra o aplicativo Conecte SUS, após ter baixado na loja de seu celular;
      </li>
      <li>
        Clique em "Entrar com Gov.br". Será preciso informar seu CPF para fazer
        login no portal <strong>gov.br</strong>. Caso você nunca tenha utilizado
        a plataforma, realize as próximas etapas para cadastro de sua conta
      </li>
      <li>
        Em Ações rápidas, clique em Vacinas. Logo após, clique no nome do
        fabricante da vacina, que aparece acima das datas em que você recebeu as
        doses
      </li>
      <li>
        Clique no botão <strong>Certificado de Vacinação Covid-19</strong> e
        gere o PDF pressionando o ícone no canto superior direito da
        pré-visualização do documento
      </li>
    </S.Container>
  )
}
