import { ReactNode, useState } from 'react'

import { MultiModalContext } from '@presentation/contexts'
import { Modal } from '@presentation/components/organisms'
import { MultiModalProps } from '@presentation/types/components/organisms'

const MultiModal: React.FC<MultiModalProps> = ({
  children,
  step,
  onStepChange,
  ...modalProps
}) => {
  const [actions, setActions] = useState<ReactNode[]>([])

  const previousStep = () => {
    if (step > 1) {
      onStepChange(step - 1)
    }
  }

  const nextStep = () => {
    onStepChange(step + 1)
  }

  const resetSteps = () => {
    onStepChange(1)
  }

  return (
    <Modal {...modalProps} actions={actions}>
      <MultiModalContext.Provider
        value={{
          onActionsChange: setActions,
          previousStep,
          nextStep,
          resetSteps
        }}
      >
        {children}
      </MultiModalContext.Provider>
    </Modal>
  )
}

export default MultiModal
