import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

export const GruposAcessoSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Grupos de Acesso"
      name="gruposAcesso"
      path="/grupos-acesso?sort_by=nome"
      control={control}
      error={formState?.errors.gruposAcesso?.message}
      placeholder="Selecione aqui"
      isClearable
      isMulti
    />
  )
}

export default GruposAcessoSelectApi
