import styled, { css } from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { rgba } from 'polished'

import { media } from '@presentation/styles'

/** Mixins */
export const menuHighlight = css`
  ${({ theme }) => css`
    color: ${theme.colors.backgroundSidebar};
    background-color: ${theme.colors.white};
    font-weight: 500;

    border-left: thick solid ${theme.colors.secondary};

    box-shadow: 0px 0px 3px 2px ${rgba(theme.colors.secondary, 0.5)};
    transition: ${theme.transitions.color};

    ${Icon} {
      svg {
        fill: ${theme.colors.backgroundSidebar};
      }
    }
  `}
`

export const menuHover = css`
  ${menuHighlight}
  box-shadow: none;
`

const baseLink = css`
  display: flex;
  padding: 0.8rem 1.25rem;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  white-space: nowrap;
  border-left: thick solid transparent;
  font-weight: 500;
  align-items: center;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${menuHover};
    }
  }
`

const submenuHover = css`
  position: fixed;
  left: ${props => props.theme.shared.sidebar.collapseWidth};
  width: 10.625rem;
  z-index: ${props => props.theme.indexes.high};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.backgroundSidebarDropdown};
  border-left: none;
  transition: none;
`

type Props = {
  isCollapsible: boolean
  isHover: boolean
  isActive?: boolean
  isOpen?: boolean
}

/** Styled components */
export const Container = styled.li<Props>`
  cursor: pointer;

  ${({ isCollapsible, isHover, isActive, isOpen }) => css`
    ${media.greaterThan('mobile')`
      ${
        isCollapsible && // Se o sidebar estiver recolhido
        css`
          ${Link}, ${NavLink} {
            border-left: none !important;

            ${Icon} {
              margin: 0;
            }

            ${Title} {
              display: none;
            }

            ${DropdownIcon} {
              display: none;
            }
          }

          /* Menu lateral dos submenus */
          ${Dropdown} {
            ${submenuHover}
          }

          ${isHover && // Se estiver com o mouse em cima - estilos apenas o título do menu
          css`
            ${Link}, ${NavLink} {
              ${Title} {
                display: block !important;
                ${submenuHover}
                padding: 0.6rem 1.4rem;
                line-height: 1.8;
              }
            }
          `}
        `
      }
    `}

    ${Link} {
      ${isActive &&
      css`
        ${menuHighlight};
      `}
    }

    ${DropdownIcon} {
      transform: ${isOpen && 'rotate(90deg)'};
    }

    ${Dropdown} {
      max-height: ${isOpen ? '40rem' : 0};
      visibility: ${isOpen ? 'visible' : 'hidden'};
    }
  `}
`

export const NavLink = styled(RouterNavLink)`
  ${baseLink}

  &.active {
    ${menuHighlight};
  }
`

export const Link = styled.div`
  ${baseLink}
`

export const Icon = styled.span`
  display: flex;
  margin: 0 0.625rem 0 0.3rem;

  svg {
    width: 1.375rem;
    font-size: 1rem;
    fill: ${props => props.theme.colors.white};
  }
`

export const Title = styled.span`
  font-size: 1rem;
  align-self: center;
`

export const DropdownIcon = styled.span`
  display: flex;
  position: absolute;
  right: 1.75rem;
  line-height: 1.5rem;

  transition: ${props => props.theme.transitions.transform};
`

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;

  background-color: inherit;
  border-left: thick solid ${props => props.theme.colors.secondary};

  overflow: hidden;

  transition: max-height 0.5s ease-in-out, visibility 0.5s ease-in-out;
`
