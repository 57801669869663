import { MouseEvent } from 'react'
import { Button } from 'react-bootstrap'
import { FaTrash as RemoveIcon } from 'react-icons/fa'

import { useModal } from '@presentation/hooks'
import { ActionButton } from '@presentation/components/molecules'
import { Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'

type Props = {
  id: number
  identificacao: string
  remove: (index: number) => void
}

const DeleteModal: React.FC<Props> = ({ id, identificacao, remove }) => {
  const { isOpen, openModal, closeModal } = useModal()

  const onSuccess = () => {
    closeModal()
  }

  const onConfirm = async (event: MouseEvent<HTMLButtonElement>) => {
    remove(id)
    onSuccess()
  }

  const buttonActions = [
    <Button variant="success" onClick={onConfirm}>
      Confirmar
    </Button>
  ]
  const configs: ModalProps = {
    isOpen,
    icon: <RemoveIcon />,
    title: 'Remoção de Identificação',
    description: `Tem certeza de que deseja excluir a identificação '${identificacao}' ?`,
    actions: buttonActions,
    size: 'sm',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="delete" onClick={openModal} />
      <Modal {...configs} />
    </>
  )
}

export default DeleteModal
