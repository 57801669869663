import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup
    .string()
    .required('O campo é obrigatório.')
    .max(100, 'O campo atingiu o limite máximo de 100 caracteres.'),
  bloco: yup.object().required('O campo é obrigatório'),
  tipoLocal: yup.object().required('O campo é obrigatório')
})
