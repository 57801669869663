import { HttpClient } from '@core/gateways'
import { NotFoundError, UnexpectedError } from '@core/models/errors'
import { setupIntranetApiClient } from '@core/proxies/apis'
import { HttpStatusCode } from '@core/types/gateways'
import {
  AcceptTerms,
  GetProfilePhoto,
  LoadPessoaById,
  LoadPessoaByNumeroCartao,
  ResetPassword
} from '@core/types/services'

export const getProfilePhoto: GetProfilePhoto = async ({ id, token }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/pessoas/${id}/foto?token=${token}`,
    method: 'GET',
    responseType: 'blob'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      const blob = new Blob([response.body])
      const file = new File([blob], `${id}`, {
        type: response.headers['content-type']
      })
      return file
    case HttpStatusCode.notFound:
      return undefined
    default:
      throw new UnexpectedError()
  }
}

export const acceptTerms: AcceptTerms = async ({ token }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/pessoa-identificacoes/aceite-termo`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return
    default:
      throw new UnexpectedError()
  }
}

export const loadPessoaById: LoadPessoaById = async ({ id }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/pessoas/${id}`,
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body.data
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const loadPessoaByNumeroCartao: LoadPessoaByNumeroCartao = async ({
  numeroCartao
}) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/pessoas/${numeroCartao}/cartao`,
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body.data
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}

export const resetPassword: ResetPassword = async ({ id }) => {
  const response = await HttpClient.of(setupIntranetApiClient()).request({
    url: `/pessoas/${id}/resetar-senha`,
    method: 'PATCH'
  })

  switch (response.statusCode) {
    case HttpStatusCode.noContent:
      return
    case HttpStatusCode.notFound:
      throw new NotFoundError()
    default:
      throw new UnexpectedError()
  }
}
