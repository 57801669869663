import { useEffect, useState } from 'react'

import { LocalStorage } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { useAuth } from '@presentation/hooks'
import { DefaultProfileImage } from '@presentation/assets/images'

export const useProfile = () => {
  const { getCurrentAccount } = useAuth()
  const [photo, setPhoto] = useState<string>()

  const currentAccount = getCurrentAccount()

  useEffect(() => {
    const handleProfilePhoto = async () => {
      const accessToken = LocalStorage.get({ key: 'accessToken' })

      if (currentAccount && accessToken) {
        try {
          const profilePhoto = await AdministracaoService.getProfilePhoto({
            id: currentAccount.id,
            token: accessToken
          })
          if (profilePhoto) {
            const profilePhotoUrl = URL.createObjectURL(profilePhoto)
            setPhoto(profilePhotoUrl)
          }
        } catch (error) {
          setPhoto(undefined)
        }
      }
    }

    void handleProfilePhoto()
  }, [])
  return {
    currentAccount,
    photo: photo ?? DefaultProfileImage
  }
}
