import { useState } from 'react'
import { FaArrowUp as ArrowIcon } from 'react-icons/fa'

import * as S from './styles'

const BackToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisible = () => {
    const scrollTop = document.documentElement.scrollTop

    if (scrollTop > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <>
      {isVisible && (
        <S.Button size="sm">
          <ArrowIcon onClick={scrollToTop} />
        </S.Button>
      )}
    </>
  )
}

export default BackToTopButton
