import { Alert } from 'react-bootstrap'

import * as S from './styles'

type Props = {
  errors: string[]
  onHide?: Function
}

const ErrorList: React.FC<Props> = ({ errors, onHide }) => {
  return (
    <Alert
      id="error-list"
      variant="danger"
      onClose={() => onHide?.()}
      dismissible
    >
      <S.List>
        {errors.map((error, index) => {
          return <S.Item key={index}>{error}</S.Item>
        })}
      </S.List>
    </Alert>
  )
}

export default ErrorList
