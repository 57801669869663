import { Form, Row } from 'react-bootstrap'

import { Input, SelectApi } from '@presentation/components/molecules'
import { useForm } from '@presentation/hooks'

const BlocoForm: React.FC = () => {
  const { register, control, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Input
          label="Nome"
          name="nome"
          register={register}
          error={formState?.errors.nome?.message}
          required
          type="text"
          placeholder="Digite o nome do setor"
          autoComplete="off"
        ></Input>
      </Row>

      <Row className="mb-3">
        <SelectApi
          name="eixo"
          path="/eixos"
          control={control}
          placeholder="Selecione aqui"
          label="Eixo"
          isRequired
        />
      </Row>
    </Form>
  )
}

export default BlocoForm
