const Introduction: React.FC = () => {
  return (
    <>
      <p>
        Seja bem-vindo ao sistema integrado Intranet do IFCE campus Maracanaú.
        Por favor, leia com atenção os termos legais de utilização apresentados
        aqui.
      </p>

      <p>
        Este documento, e todo o conteúdo da plataforma é oferecido por IFCE
        campus Maracanaú, disponível em{' '}
        <a href="https://intranet.maracanau.ifce.edu.br" target="_blank">
          https://intranet.maracanau.ifce.edu.br
        </a>
        , situado na Avenida Parque Central, 1315 - Distrito Industrial I,
        Maracanaú - CE, 61939-140, contato eletrônico:{' '}
        <a href="mailto:cti.maracanau@ifce.edu.br">cti.maracanau@ifce.edu.br</a>{' '}
        que regulamenta todos os direitos e obrigações com todos que acessam o
        site, resguardado todos os direitos previstos na legislação.
      </p>

      <p>
        Ao utilizar os serviços da Intranet você confirma que leu, compreendeu e
        concorda em cumprir os termos e condições estabelecidas no presente
        instrumento particular.
      </p>
    </>
  )
}

export default Introduction
