import { Section } from '@presentation/components/molecules'

const ConsideracoesSection: React.FC = () => {
  return (
    <>
      <Section title="Considerações Finais" />

      <ul>
        <li>
          O editor se reserva o direito de modificar, a qualquer momento as
          presentes normas, especialmente para adaptá-las às evoluções do
          serviço Intranet, seja pela disponibilização de novas funcionalidades,
          seja pela supressão ou modificação daquelas já existentes.
        </li>
        <li>
          Qualquer alteração e/ou atualização destes Termos de Uso e Política de
          Privacidade passará a vigorar a partir da data de sua publicação.
        </li>
        <li>
          O acesso ao sistema integrado Intranet representa a aceitação expressa
          e irrestrita dos Termos de Uso acima descritos. Ao concordar com esses
          termos você concede uma licença perpétua, isenta de royalties, licença
          incondicional para a plataforma Intranet.
        </li>
      </ul>
    </>
  )
}

export default ConsideracoesSection
