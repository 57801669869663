import { useForm } from '@presentation/hooks'
import { Input } from '@presentation/components/molecules'

type Props = {
  hasDuplicate: boolean
}

const IdentificacaoInput: React.FC<Props> = ({ hasDuplicate }) => {
  const { register, formState } = useForm()

  return (
    <Input
      label="Identificação"
      name="identificacao"
      register={register}
      error={
        hasDuplicate
          ? 'A identificação já está em uso.'
          : formState?.errors.identificacao?.message
      }
      type="text"
      placeholder="Digite a identificação da pessoa"
      autoComplete="off"
      required
    />
  )
}

export default IdentificacaoInput
