import { LocalStorage } from '@core/gateways'
import React, { useState, createContext, useEffect } from 'react'

type ThemeContextType = {
  mode: string
  toggleTheme: () => void
}

const defaultMode = 'light'

export const ThemeManagerContext: React.Context<ThemeContextType> =
  createContext({} as ThemeContextType)

export const ThemeManagerProvider: React.FC = ({ children }) => {
  const [mode, setMode] = useState(defaultMode)

  const handleMode = (mode: string) => {
    // faz o toggle do tema salvando no local storage
    LocalStorage.set({ key: 'theme', value: mode })
    setMode(mode)
  }

  const toggleTheme = (): void => {
    handleMode(mode === 'light' ? 'dark' : 'light')
  }

  useEffect(() => {
    // lógica para priorizar o valor de tema salvo no local storage
    const localTheme = LocalStorage.get({ key: 'theme' })
    localTheme && setMode(localTheme)
  })

  return (
    <ThemeManagerContext.Provider
      value={{
        mode,
        toggleTheme
      }}
    >
      {children}
    </ThemeManagerContext.Provider>
  )
}
