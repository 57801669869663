import { MouseEvent, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaUnlock as ResetIcon } from 'react-icons/fa'

import { Alert } from '@core/gateways'
import { Pessoa } from '@core/models/entities'
import { NotFoundError, UnexpectedError } from '@core/models/errors'
import { resetPassword } from '@core/services/administracao'
import { Modal } from '@presentation/components/organisms'
import { useModal } from '@presentation/hooks'
import { ModalProps } from '@presentation/types/components/organisms'
import { ActionButton } from '@presentation/components/molecules'

type Props = {
  pessoa: Pessoa
  onReset?: () => void
}

const ResetPasswordModal: React.FC<Props> = ({ pessoa, onReset }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSuccess = () => {
    closeModal()
    if (onReset) {
      onReset()
    }
    Alert.callSuccess({
      title: 'Redefinido com Sucesso'
    })
  }

  const onError = (error: Error) => {
    closeModal()
    Alert.callError({
      title: 'Falha na Redefinição',
      description: error.message
    })
  }

  const onConfirm = async (event: MouseEvent<HTMLButtonElement>) => {
    try {
      setIsSubmitting(false)
      await resetPassword({ id: pessoa.id })
      onSuccess()
      setIsSubmitting(true)
    } catch (error) {
      if (error instanceof UnexpectedError || error instanceof NotFoundError) {
        onError(error)
      }
    }
  }

  const buttonActions = [
    <Button variant="success" onClick={onConfirm} disabled={isSubmitting}>
      Confirmar
    </Button>
  ]

  const configs: ModalProps = {
    isOpen,
    icon: <ResetIcon />,
    title: `Redefinição de Senha`,
    description: `Tem certeza de que deseja redefinir a senha de '${pessoa.nome}' ?`,
    actions: buttonActions,
    size: 'sm',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="resetPassword" onClick={openModal} />
      <Modal {...configs} />
    </>
  )
}

export default ResetPasswordModal
