import { Link } from 'react-router-dom'

import * as S from './styles'

const NotificationDropdownHeader: React.FC = () => {
  return (
    <S.Container>
      Notificações
      <span>
        <Link to="#">
          <small>Limpar Tudo</small>
        </Link>
      </span>
    </S.Container>
  )
}

export default NotificationDropdownHeader
