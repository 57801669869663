import { rgba } from 'polished'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    .rdt_Table {
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-top: 0.1rem solid ${theme.colors.primary};
      border-radius: 0.28571429rem;
      background-color: transparent;
    }

    .rdt_TableHeadRow {
      background-color: ${theme.colors.background};
      font-weight: 600;
      min-height: 45px;
      font-size: 0.875rem;
      color: ${theme.colors.textPrimary};
    }

    .rdt_TableRow {
      font-size: 0.875rem;
      color: ${theme.colors.textPrimary};
      background-color: ${theme.colors.backgroundContent};

      &:nth-child(odd) {
        background-color: ${rgba(theme.colors.background, 0.3)};
      }

      &:hover {
        background-color: ${rgba(theme.colors.primary, 0.2)};
      }
    }

    .rdt_Pagination {
      background-color: transparent;

      * {
        color: ${theme.colors.textPrimary};

        button {
          fill: ${rgba(theme.colors.textPrimary, 0.8)};
        }

        &:disabled {
          fill: ${rgba(theme.colors.textPrimary, 0.2)};
        }
      }
    }
  `}
`
