import { Col, Form, Row } from 'react-bootstrap'

import { Input, SelectApi } from '@presentation/components/molecules'
import { useForm } from '@presentation/hooks'

const SetorForm: React.FC = () => {
  const { register, control, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Col md>
          <Input
            label="Nome"
            name="nome"
            register={register}
            error={formState?.errors.nome?.message}
            required
            type="text"
            placeholder="Digite o nome do local"
            autoComplete="off"
          />
        </Col>

        <Col md>
          <SelectApi
            name="tipoLocal"
            control={control}
            path="tipos-local"
            placeholder="Selecione aqui"
            label="Tipo de Local"
            isRequired
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <SelectApi
            name="bloco"
            control={control}
            path="/blocos"
            placeholder="Selecione aqui"
            label="Bloco"
            isRequired
          />
        </Col>
      </Row>
    </Form>
  )
}

export default SetorForm
