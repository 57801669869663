import styled, { css } from 'styled-components'
import { FormControl, InputGroup } from 'react-bootstrap'
import { rgba } from 'polished'

import { SubmitButton } from '@presentation/components/molecules'
import { lightTheme } from '@presentation/styles'

type InputProps = {
  isInvalid: boolean
}

export const Input = styled(InputGroup)<InputProps>`
  ${({ isInvalid }) => css`
    transition: box-shadow 0.2s ease 0s, border 0.2s ease 0s;
    border-radius: 0.25rem;
    border: 1px solid ${isInvalid ? lightTheme.colors.danger : 'transparent'};

    &:focus-within {
      border: 1px solid transparent;
      box-shadow: 0 0 0 0.25rem
        ${rgba(
          isInvalid ? lightTheme.colors.danger : lightTheme.colors.primary,
          0.25
        )};
      svg {
        color: ${rgba(
          isInvalid ? lightTheme.colors.danger : lightTheme.colors.primary,
          0.6
        )};
      }
    }
  `}
`

export const InputIcon = styled(InputGroup.Text)`
  background-color: ${lightTheme.colors.backgroundContent};
  border: none;
  font-size: 1.2rem;
  padding: 0.375rem 0.35rem 0.375rem 0.75rem;
  color: ${rgba(lightTheme.colors.textPrimary, 0.4)};

  &:focus {
    border: 1px solid ${lightTheme.colors.primary};
    background-color: ${lightTheme.colors.backgroundContent};
  }
`

export const InputField = styled(FormControl)`
  background-color: ${lightTheme.colors.backgroundContent};

  padding-left: 0.4rem;
  color: ${lightTheme.colors.textPrimary};
  border: none;

  &:focus {
    box-shadow: none;
    background-color: ${lightTheme.colors.backgroundContent};
  }
`

export const CustomSubmitButton = styled(SubmitButton)`
  width: 100%;
  margin: 1.25rem 0;
  font-size: 500;
`
