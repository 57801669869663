import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

const FuncaoSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Função"
      name="funcao"
      path={`/funcoes?sort_by=nome`}
      control={control}
      error={formState?.errors.funcao?.message}
      placeholder="Selecione aqui"
      isClearable
    />
  )
}

export default FuncaoSelectApi
