import { DeleteModal } from '@presentation/components/templates'

type Props = {
  id: string
  onDelete: () => void
}

const DeleteFuncaoModal: React.FC<Props> = ({ id, onDelete }) => {
  const modalConfigs = {
    id,
    path: '/funcoes',
    nameForTitle: 'Funcao',
    onDelete
  }

  return <DeleteModal {...modalConfigs} />
}

export default DeleteFuncaoModal
