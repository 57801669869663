import { MouseEvent } from 'react'
import { Button } from 'react-bootstrap'
import { FaTelegramPlane as SendIcon } from 'react-icons/fa'

import { VacinaService } from '@core/services'
import { Alert } from '@core/gateways'
import { useModal } from '@presentation/hooks'
import { Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'
import { ActionButton } from '@presentation/components/molecules'

type Props = {
  pessoaId: string
  pessoaName: string
  onRelease: () => void
}

const LiberarComprovanteModal: React.FC<Props> = ({
  pessoaId,
  pessoaName,
  onRelease
}) => {
  const { isOpen, openModal, closeModal } = useModal()

  const onSuccess = async () => {
    closeModal()
    if (onRelease) {
      onRelease()
    }
    await Alert.call({
      title: 'Liberado com Sucesso',
      type: 'success'
    })
  }

  const onError = async (error: Error) => {
    closeModal()
    await Alert.call({
      title: 'Falha na Liberação',
      description: error.message,
      type: 'error'
    })
  }

  const onConfirm = async (event: MouseEvent<HTMLButtonElement>) => {
    try {
      await VacinaService.releaseComprovante({
        id: pessoaId
      })
      await onSuccess()
    } catch (error) {
      await onError(error as Error)
    }
  }

  const buttonActions = [
    <Button variant="success" onClick={onConfirm}>
      Confirmar
    </Button>
  ]
  const configs: ModalProps = {
    isOpen,
    icon: <SendIcon />,
    title: `Liberação de Comprovante`,
    description: `Tem certeza de que deseja liberar o comprovante de ${pessoaName}?`,
    actions: buttonActions,
    size: 'sm',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="send" onClick={openModal} />
      <Modal {...configs}></Modal>
    </>
  )
}

export default LiberarComprovanteModal
