import { serialize } from 'object-to-formdata'

export const serializeToFormData = (object: Object) => {
  return serialize(object, { indices: true })
}

export const removeEmptyString = (object: any) => {
  for (const key in object) {
    if (object[key] === '' || object[key] === null) {
      object[key] = undefined
    }
  }
}
