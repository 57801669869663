import styled from 'styled-components'

export const Container = styled.div`
  background: #ddd;

  &.text {
    width: 100%;
    height: 17px;
  }

  &.title {
    width: 250px;
    height: 26.875px;
    margin-bottom: 0.3rem;
  }

  &.square {
    width: 100%;
    height: 100%;
  }

  &.button {
    width: 100px;
    height: 22px;
  }
`
