import styled from 'styled-components'

export const CropperWrapper = styled.div`
  position: relative;
  width: auto;
  height: 400px;
`

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;

  & > * {
    width: 75%;
  }
`
