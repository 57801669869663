import { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { FaEye as ViewIcon } from 'react-icons/fa'

import { VacinaService } from '@core/services'
import { Alert } from '@core/gateways'
import { useModal } from '@presentation/hooks'
import { ActionButton } from '@presentation/components/molecules'
import { Modal, PDFViewer } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'

type Props = {
  comprovanteId: string
  pessoaName: string
  isAdmin?: boolean
}

const ViewComprovanteModal: React.FC<Props> = ({
  comprovanteId,
  pessoaName,
  isAdmin = false
}) => {
  const { isOpen, openModal, closeModal } = useModal()
  const [file, setFile] = useState<File>()

  useEffect(() => {
    if (isOpen) {
      const handleFile = async () => {
        try {
          const comprovanteFile = await VacinaService.loadComprovanteFile({
            id: comprovanteId,
            isAdmin
          })

          setFile(comprovanteFile)
        } catch (error) {
          await onError(error as Error)
        }
      }

      void handleFile()
    }
  }, [isOpen])

  const onError = async (error: Error) => {
    closeModal()
    await Alert.call({
      title: 'Falha na Visualização',
      description: error.message,
      type: 'error'
    })
  }

  const modalConfigs: ModalProps = {
    isOpen,
    icon: <ViewIcon />,
    title: 'Visualização do Documento',
    description: `Confira abaixo o comprovante enviado por ${pessoaName}.`,
    size: 'xl',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="view" onClick={openModal} />
      <Modal {...modalConfigs}>
        {file && file.type === 'application/pdf' ? (
          <PDFViewer file={file} />
        ) : (
          file && <Image fluid src={URL.createObjectURL(file)} />
        )}
      </Modal>
    </>
  )
}

export default ViewComprovanteModal
