import { DeleteModal } from '@presentation/components/templates'

type Props = {
  id: string
  onDelete: () => void
}

const DeleteEixoModal: React.FC<Props> = ({ id, onDelete }) => {
  const modalConfigs = {
    id,
    path: '/eixos',
    nameForTitle: 'Eixo',
    onDelete
  }

  return <DeleteModal {...modalConfigs} />
}

export default DeleteEixoModal
