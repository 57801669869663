import { FaUsers as GruposIcon } from 'react-icons/fa'

import { GrupoAcesso, PermissaoTipo, PessoaTipo } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { RowsOnCell } from '@presentation/components/molecules'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import {
  AddGrupoAcessoModal,
  DeleteGrupoAcessoModal,
  EditGrupoAcessoModal,
  ReactivateGrupoAcessoModal
} from './components'

const GruposAcessoPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/grupos-acesso',
    options: { sortBy: 'nome' },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <GruposIcon />,
    title: 'Grupos de Acesso',
    description: 'Página de gerenciamento de grupos de acesso do campus.',
    actions: hasPermission(PermissaoTipo.Escrita)
      ? [<AddGrupoAcessoModal onAdd={fetchData} />]
      : []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'tipo',
        label: 'Tipo de Pessoa'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/pessoa-tipos',
          getOptionLabel: (pessoaTipo: PessoaTipo) => pessoaTipo.descricao,
          isMulti: true
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      status: 1
    }
  }

  const columns: Array<TableColumn<GrupoAcesso>> = [
    {
      name: 'Nome',
      selector: data => data.nome,
      sortable: true,
      center: true
    },
    {
      name: 'Descrição',
      selector: data => data.descricao,
      center: true,
      grow: 2
    },
    {
      name: 'Tipo de Pessoa',
      cell: data => {
        const items =
          data.pessoas_tipo?.map(
            (pessoaTipo: PessoaTipo) => pessoaTipo.descricao
          ) ?? []

        if (items) {
          return <RowsOnCell items={items} />
        }
      },
      center: true
    },
    {
      name: 'Ações',
      cell: data => (
        <>
          {data.status ? (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <EditGrupoAcessoModal id={data.id} onEdit={fetchData} />
              )}
              {hasPermission(PermissaoTipo.Remocao) && (
                <DeleteGrupoAcessoModal id={data.id} onDelete={fetchData} />
              )}
            </>
          ) : (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <ReactivateGrupoAcessoModal
                  id={data.id}
                  onReactivate={fetchData}
                />
              )}
            </>
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default GruposAcessoPage
