import { useCallback, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import {
  PessoaCategoriaAttr,
  PessoaSituacaoAttr,
  PessoaTipoAttr
} from '@core/models/entities'
import { useForm } from '@presentation/hooks'

import * as Field from './components'
import { getSituacaoCategoriaByTipoAttr } from './utils'

type Props = {
  fields: any[]
  hasDuplicate: boolean
  ignoreId?: number
}

const PessoaIdentificacaoForm: React.FC<Props> = ({
  fields,
  hasDuplicate,
  ignoreId
}) => {
  const { watch, setValue } = useForm()
  const selectedTipo = watch('tipo')
  const selectedCategoria = watch('categoria')

  const [situacaoAttr, setSituacaoAttr] = useState<PessoaSituacaoAttr>()
  const [categoriaAttr, setCategoriaAttr] = useState<PessoaCategoriaAttr>()

  useEffect(() => {
    if (selectedTipo) {
      handleTipoAttr(selectedTipo.attr)
      console.log(ignoreId)
      if (ignoreId === undefined) {
        setValue('curso', null)
      }
    }
  }, [selectedTipo])

  const isTipoAttrIn = useCallback(
    (attrs: string[], wasCategorySelected = false) => {
      if (selectedTipo) {
        return wasCategorySelected
          ? selectedCategoria && attrs.includes(selectedTipo.attr)
          : attrs.includes(selectedTipo.attr)
      }
    },
    [selectedTipo, selectedCategoria]
  )

  const handleSituacaoAttrChange = (
    nextSituacaoAttr: PessoaSituacaoAttr | undefined
  ) => {
    if (situacaoAttr) {
      if (nextSituacaoAttr !== situacaoAttr) {
        setValue('situacao', null)
      }
    }

    setSituacaoAttr(nextSituacaoAttr)
  }

  const handleTipoAttr = (tipoAttr: PessoaTipoAttr) => {
    const states = getSituacaoCategoriaByTipoAttr(tipoAttr)

    handleSituacaoAttrChange(states.situacao)
    setCategoriaAttr(states.categoria)
  }

  return (
    <Form>
      <Row className="mb-3">
        <Col md>
          <Field.TipoSelectApi fields={fields} ignoreId={ignoreId} />
        </Col>

        <Col md>
          <Field.IdentificacaoInput hasDuplicate={hasDuplicate} />
        </Col>

        <Col md>
          {isTipoAttrIn(['visitante']) ? (
            <Field.ResponsavelSelectApi />
          ) : (
            <Field.SituacaoSelectApi
              selectedTipo={selectedTipo}
              situacaoAttr={situacaoAttr}
            />
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          {isTipoAttrIn(['visitante']) ? (
            <Field.DataInicioInput />
          ) : (
            <Field.GruposAcessoSelectApi />
          )}
        </Col>

        <Col>
          {isTipoAttrIn([
            'tecnico',
            'integrado',
            'graduacao',
            'mestrado',
            'extensao'
          ]) && <Field.CursoSelectApi selectedTipo={selectedTipo} />}
          {isTipoAttrIn(['servidor', 'estagiario', 'terceirizado']) && (
            <Field.CategoriaSelectApi
              selectedTipo={selectedTipo}
              categoriaAttr={categoriaAttr}
            />
          )}
          {isTipoAttrIn(['visitante']) && <Field.DataFimInput />}{' '}
        </Col>

        <Col>
          {isTipoAttrIn(['tecnico', 'integrado', 'graduacao']) && (
            <Field.CategoriaSelectApi
              selectedTipo={selectedTipo}
              categoriaAttr={categoriaAttr}
            />
          )}

          {isTipoAttrIn(['mestrado', 'extensao']) && (
            <Field.ResponsavelSelectApi />
          )}

          {isTipoAttrIn(['servidor'], true) && selectedCategoria.id === 11 && (
            <Field.EixoSelectApi />
          )}

          {isTipoAttrIn(['terceirizado'], true) && <Field.CargoSelectApi />}

          {isTipoAttrIn(['servidor'], true) && selectedCategoria.id === 12 && (
            <Field.CargoSelectApi />
          )}

          {isTipoAttrIn(['estagiario'], true) && <Field.SetorSelectApi />}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          {isTipoAttrIn(['servidor', 'terceirizado'], true) && (
            <Field.SetorSelectApi />
          )}

          {isTipoAttrIn(['graduacao', 'tecnico', 'integrado'], true) &&
            selectedCategoria.id !== 1 && <Field.SetorSelectApi />}

          {isTipoAttrIn(['mestrado']) && <Field.LocalSelectApi />}
          {isTipoAttrIn(['estagiario'], true) && <Field.LocalSelectApi />}

          {isTipoAttrIn(['extensao']) && <Field.DataInicioInput />}
        </Col>

        <Col>
          {isTipoAttrIn(['estagiario'], true) && <Field.DataInicioInput />}
          {isTipoAttrIn(['graduacao', 'tecnico', 'integrado'], true) &&
            selectedCategoria.id !== 1 && <Field.DataInicioInput />}

          {isTipoAttrIn(['extensao']) && <Field.DataFimInput />}

          {isTipoAttrIn(['servidor', 'terceirizado'], true) && (
            <Field.LocalSelectApi />
          )}
        </Col>

        <Col>
          {isTipoAttrIn(['estagiario'], true) && <Field.DataFimInput />}
          {isTipoAttrIn(['graduacao', 'tecnico', 'integrado'], true) &&
            selectedCategoria &&
            selectedCategoria.id !== 1 && <Field.DataFimInput />}

          {isTipoAttrIn(['servidor'], true) && <Field.FuncaoSelectApi />}

          {isTipoAttrIn(['terceirizado'], true) && <Field.EmpresaSelectApi />}
        </Col>

        {isTipoAttrIn(['visitante']) && <Field.MotivoInput />}
      </Row>

      <Row>
        <Col>
          {isTipoAttrIn(['terceirizado'], true) && (
            <Field.ResponsavelSelectApi />
          )}
        </Col>

        <Col>
          {isTipoAttrIn(['terceirizado'], true) && (
            <Field.TelefoneResponsavelInput />
          )}
        </Col>

        <Col></Col>
      </Row>
    </Form>
  )
}

export default PessoaIdentificacaoForm
