import { FileUtils } from '@presentation/utils'
import { GetCroppedImage } from '@presentation/types/hooks'

const createImage = async (url: string) => {
  return await new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', () => resolve(image))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })
}

export const getCroppedImage: GetCroppedImage = async ({
  url,
  croppedArea
}) => {
  const image = await createImage(url)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = croppedArea.width
  canvas.height = croppedArea.height

  if (ctx) {
    ctx.drawImage(
      image as CanvasImageSource,
      croppedArea.x,
      croppedArea.y,
      croppedArea.width,
      croppedArea.height,
      0,
      0,
      canvas.width,
      canvas.height
    )
  }

  return await new Promise(resolve => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve({
          file: FileUtils.blobToFile(blob, 'any_filename'),
          url: URL.createObjectURL(blob)
        })
      }
    }, 'image/jpeg')
  })
}
