import { AiOutlineFileAdd as AddFileIcon } from 'react-icons/ai'

import { useTheme } from '@presentation/hooks'
import { IconButton } from '@presentation/components/molecules'

import * as S from './styles'

const UploadFile: React.FC = () => {
  const theme = useTheme()

  return (
    <S.Container>
      <IconButton
        icon={<AddFileIcon />}
        variant={theme.mode === 'light' ? 'outline-primary' : 'outline-light'}
      >
        Escolher Arquivo
      </IconButton>
      <S.Info>ou solte o arquivo PDF, PNG, JPG ou GIF aqui</S.Info>
    </S.Container>
  )
}

export default UploadFile
