import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-bottom: 0.75rem;
  }
`

export const Info = styled.small`
  color: ${props => props.theme.colors.primary};
`
