import { ComprovanteGuide } from './components'
import * as S from './styles'

type Props = {
  isGuideVisible?: boolean
}

const About: React.FC<Props> = ({ isGuideVisible = true }) => {
  return (
    <S.Container>
      <section>
        <p>
          O acesso às dependências do Instituto Federal de Educação, Ciência e
          Tecnologia do Ceará (IFCE) será liberado somente mediante a
          apresentação do "passaporte de vacinação" contra COVID-19.
        </p>
        <p>
          O ingresso será permitido a partir da comprovação da primeira dose,
          mas a instituição acompanhará o cumprimento de todo esquema vacinal,
          dentro dos prazos estabelecidos pelas autoridades de saúde.
        </p>
        <p>
          Servidores, alunos e terceirizados deverão apresentar o documento, bem
          como a comunidade e pessoas da sociedade que precisem entrar nas
          dependências de qualquer unidade do Instituto Federal.
        </p>
        <p>
          A cobrança do comprovante para desenvolvimento de atividades no IFCE
          foi aprovada, por unanimidade, pelo Conselho Superior da Instituição,
          durante reunião extraordinária realizada no dia 26 de Janeiro de 2022.
        </p>
        <p>
          Os <strong>alunos veterados que ainda não cartão de acesso</strong>{' '}
          deverão procurar do setor de TI, de 14 a 18 de março, em frente ao
          auditório para procedimentos de recebimento do cartão.
        </p>
        <p>
          Os <strong>alunos</strong> novatos deverão fazer o mesmo procedimento
          de 21 a 25 de março.
        </p>
        <p>
          Os alunos que, por alguma condição médica não podem tomar a vacina,
          deverão enviar o atestado médico.
        </p>
      </section>

      {isGuideVisible && <ComprovanteGuide />}
    </S.Container>
  )
}

export default About
