type handleLinksProps = {
  link: string
  title: string
}

export const handleLinks = ({ link, title }: handleLinksProps) => {
  return (
    <a href={link} target="_blank">
      {title}
    </a>
  )
}
