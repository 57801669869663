import { Col, Form, Row } from 'react-bootstrap'

import { Permissao, PessoaTipo } from '@core/models/entities'
import {
  Input,
  Section,
  SelectApi,
  Tabs,
  TextInput
} from '@presentation/components/molecules'
import { useForm } from '@presentation/hooks'
import { TabConfig } from '@presentation/types/components/molecules'
import { useEffect, useState } from 'react'
import { AdministracaoService } from '@core/services'

import { PermissoesCategoriaTab } from './components'

const GrupoAcessoForm: React.FC = () => {
  const { register, control, formState, formId, onSubmit } = useForm()

  const [permissoes, setPermissoes] = useState<Permissao[]>()
  const [tabs, setTabs] = useState<TabConfig[]>()

  useEffect(() => {
    const loadPermissoes = async () => {
      const permissoes = await AdministracaoService.loadAll<Permissao[]>({
        path: '/permissoes'
      })
      setPermissoes(permissoes)
    }

    void loadPermissoes()
  }, [])

  useEffect(() => {
    if (permissoes) {
      const categorias = permissoes.map(permissao => permissao.categoria)
      const uniqueCategorias = Array.from(new Set(categorias))

      const tabs: TabConfig[] = uniqueCategorias.map(categoria => {
        return {
          eventKey: categoria,
          title: categoria,
          component: (
            <PermissoesCategoriaTab
              items={permissoes.filter(
                permissao => permissao.categoria === categoria
              )}
              control={control}
            />
          )
        }
      })

      setTabs(tabs)
    }
  }, [permissoes])

  return (
    <Form id={formId} key={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Col md="4">
          <Input
            label="Nome"
            name="nome"
            register={register}
            error={formState?.errors.nome?.message}
            required
            type="text"
            placeholder="Digite o nome do grupo de acesso"
          />
        </Col>
        <Col md="4">
          <SelectApi
            name="pessoaTipos"
            control={control}
            path="/pessoa-tipos"
            label="Tipos de Pessoa"
            getOptionLabel={(pessoaTipo: PessoaTipo) => pessoaTipo.descricao}
            isMulti
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <TextInput
            label="Descrição"
            name="descricao"
            register={register}
            error={formState?.errors.descricao?.message}
            required
            placeholder="Escreva uma descrição para o grupo de acesso"
            rows={3}
          />
        </Col>
      </Row>

      <Section title="Permissões">{tabs && <Tabs items={tabs}></Tabs>}</Section>
    </Form>
  )
}

export default GrupoAcessoForm
