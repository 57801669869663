import { ComponentType } from 'react'

import { Card } from '@presentation/components/organisms'

import * as S from './styles'

type Props = {
  icon: ComponentType
  label: string
  value: string
}

const StatisticWidget: React.FC<Props> = ({ icon: Icon, label, value }) => {
  return (
    <Card>
      <S.CardBody>
        <S.CardIconWrapper>
          <Icon />
        </S.CardIconWrapper>
        <S.CardValue>{value}</S.CardValue>
        <S.CardLabel>{label}</S.CardLabel>
      </S.CardBody>
    </Card>
  )
}

export default StatisticWidget
