import { useForm } from '@presentation/hooks'
import { NumberInput } from '@presentation/components/molecules'

const TelefoneResponsavelInput: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <NumberInput
      label="Telefone do Responsável"
      name="telefoneResponsavel"
      control={control}
      error={formState?.errors.telefoneResponsavel?.message}
      format={'(##) #####-####'}
      placeholder="Digite o telefone do responsável"
      required
    />
  )
}

export default TelefoneResponsavelInput
