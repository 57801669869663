import styled, { css } from 'styled-components'
import { FaBars as HamburgerIcon } from 'react-icons/fa'

import { media } from '@presentation/styles'

export const Container = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${props => props.theme.indexes.medium};

  width: inherit;
  height: ${props => props.theme.shared.navbar.height};

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 calc(${props => props.theme.shared.dashboard.spacing} - 0.75rem);

  background-color: ${props => props.theme.colors.backgroundNavbar};
`

const Icon = css`
  color: ${props => props.theme.colors.white};
  font-size: 1.6rem;
  cursor: pointer;
`

export const ToggleIcon = styled(HamburgerIcon)`
  margin: 0 0.75rem;
  ${Icon}
`

export const CampusName = styled.span`
  display: none;
  color: ${props => props.theme.colors.white};
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;

  * {
    display: flex;
  }
`

export const RightIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    display: none;
    ${Icon}

    &:nth-child(1) {
      display: flex;
    }

    ${media.greaterThan('mobile')`
      &:nth-child(2) {
        display: flex;
      }
    `}
  }
`
