import { AiOutlineFileProtect as ValidationIcon } from 'react-icons/ai'

import { Comprovante, PermissaoTipo } from '@core/models/entities'
import { ComprovanteStatus } from '@core/types/services'
import { useAuth, useFetch } from '@presentation/hooks'
import { toTitleCase } from '@presentation/utils'
import {
  PessoaIdentificacoesCell,
  PessoaIdentificacoesHead,
  ViewComprovanteModal
} from '@presentation/components/shared'
import { Tag } from '@presentation/components/atoms'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import {
  DeferirComprovanteModal,
  IndeferirComprovanteModal
} from './components'

const ValidacaoPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/gerenciar-comprovantes-covid19',
    options: { sortBy: 'deferido' },
    queries: { deferido: '-1' }
  })

  const pageConfigs: PageProps = {
    icon: <ValidationIcon />,
    title: 'Validação de Comprovantes',
    description: 'Página para busca e validação de documentos enviados.',
    actions: []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'identificacao',
        label: 'Identificação'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'deferido',
        label: 'Situação'
      },
      description: {
        type: 'select',
        configs: {
          options: [
            { label: 'Aguardando Análise', value: 'null' },
            { label: 'Deferido', value: '1' },
            { label: 'Indeferido', value: '0' }
          ]
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      deferido: '-1'
    }
  }

  const columns: Array<TableColumn<Comprovante>> = [
    {
      name: 'Nome',
      selector: data => toTitleCase(data.pessoa.nome),
      center: true,
      grow: 1.5
    },
    {
      name: <PessoaIdentificacoesHead />,
      cell: data => (
        <PessoaIdentificacoesCell items={data.pessoa.identificacoes} />
      ),
      center: true,
      grow: 1.5
    },
    {
      name: 'Situação',
      cell: data => {
        switch (data.deferido) {
          case null:
            return <Tag bg="warning">{ComprovanteStatus.Aguardando}</Tag>
          case 0:
            return (
              <Tag bg="danger" tooltipText={data.motivo_indeferimento}>
                {ComprovanteStatus.Indeferido}
              </Tag>
            )
          case 1:
            return <Tag bg="success">{ComprovanteStatus.Deferido}</Tag>
        }
      },
      center: true
    },
    {
      name: 'Ações',
      cell: data => (
        <>
          {hasPermission(PermissaoTipo.Leitura) && (
            <ViewComprovanteModal
              comprovanteId={data.id}
              pessoaName={toTitleCase(data.pessoa.nome)}
              isAdmin
            />
          )}
          {data.deferido === null && (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <DeferirComprovanteModal
                  comprovanteId={data.id}
                  pessoaName={toTitleCase(data.pessoa.nome)}
                  onAccept={fetchData}
                />
              )}

              {hasPermission(PermissaoTipo.Remocao) && (
                <IndeferirComprovanteModal
                  comprovanteId={data.id}
                  pessoaName={toTitleCase(data.pessoa.nome)}
                  onDeny={fetchData}
                />
              )}
            </>
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default ValidacaoPage
