import { Outlet } from 'react-router-dom'

import { useDashboard } from '@presentation/hooks'
import { BackToTopButton } from '@presentation/components/molecules'
import { Footer, Navbar, Sidebar } from '@presentation/components/organisms'

import * as S from './styles'

const Dashboard: React.FC = () => {
  const { isOpenSidebar } = useDashboard()

  return (
    <>
      <S.Container isCollapsible={isOpenSidebar}>
        <Navbar />

        <S.MainWrapper>
          <Outlet />
          <Footer />
        </S.MainWrapper>
      </S.Container>

      <Sidebar />
      <BackToTopButton />
    </>
  )
}

export default Dashboard
