import { ColorfulIntranetIcon, IntranetIcon } from '@presentation/assets/icons'

export default {
  app: {
    name: process.env.REACT_APP_NAME ?? 'Intranet',
    logo: {
      normal: <IntranetIcon />,
      colorful: <ColorfulIntranetIcon />
    },
    campusName: process.env.REACT_APP_CAMPUS_NAME ?? 'Maracanaú',
    homepage: '/'
  },
  api: {
    baseUri: process.env.REACT_APP_API_BASE_URI,
    baseUriV2: process.env.REACT_APP_API_V2_BASE_URI
  },
  hubs: {
    transitoCatraca: process.env.REACT_APP_HUB_TRANSITO_CATRACA
  }
}
