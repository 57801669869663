import { Form } from 'react-bootstrap'

import { Label } from '@presentation/components/molecules'
import { InputProps } from '@presentation/types/components/molecules'

import * as S from '../styles'

const Input: React.FC<InputProps> = ({
  name = '',
  label,
  register,
  required,
  error,
  onChange,
  ...rest
}) => {
  const inputRegister = register?.(name)

  const onInputChange = async (e: any) => {
    if (onChange) {
      onChange(e)
    }
    if (inputRegister) {
      await inputRegister.onChange(e)
    }
  }

  return (
    <Form.Group>
      {label && <Label isRequired={required}>{label}</Label>}

      <S.Input
        {...inputRegister}
        onChange={onInputChange}
        isInvalid={error !== undefined}
        className={`form-control ${error !== undefined && 'is-invalid'}`}
        autoComplete="off"
        {...rest}
      />

      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  )
}

export default Input
