import { Pessoa, GrupoAcesso, Curso } from '@core/models/entities'

export type PessoaIdentificacao = {
  id: string
  identificacao: number
  pessoa_tipo_id: number
  situacao_id: number
  categoria_id: number

  pessoa_tipo: PessoaTipo
  situacao: PessoaSituacao
  grupos_acesso: GrupoAcesso[]
  categoria: PessoaCategoria
  pessoa?: Pessoa

  aluno?: AlunoIdentificacao
  mestrado?: MestradoIdentificacao
  extensao?: ExtensaoIdentificacao
  estagiario?: EstagiarioIdentificacao
  servidor?: ServidorIdentificacao
  terceirizado?: TerceirizadoIdentificacao
  visitante?: VisitanteIdentificacao
}

export type PessoaTipoAttr =
  | 'tecnico'
  | 'integrado'
  | 'graduacao'
  | 'mestrado'
  | 'extensao'
  | 'servidor'
  | 'estagiario'
  | 'terceirizado'
  | 'visitante'

export type PessoaTipo = {
  id: string
  descricao: string
  attr: PessoaTipoAttr
}

export enum AvailablePessoaTipo {
  AlunoGraduacao = 1,
  Estagiario = 2,
  Servidor = 3,
  Terceirizado = 4,
  Visitante = 5,
  Mestrado = 6,
  AlunoTecnico = 7,
  AlunoIntegrado = 8,
  AlunoExtensao = 9
}

export enum PessoaSituacaoTipo {
  Matriculado = 1,
  Abandono = 2,
  Trancado = 3,
  Concludente = 4,
  Ativo = 5,
  Inativo = 6,
  Afastado = 7,
  CancelamentoVoluntario = 8,
  Reingresso = 9
}

export enum PessoaSituacaoAttr {
  Aluno = 1,
  Other = 2
}

export type PessoaSituacao = {
  id: string
  descricao: string
  attr: PessoaSituacaoAttr
}

export enum PessoaCategoriaAttr {
  Bolsista = 1,
  Estagiario = 2,
  Servidor = 3,
  Terceirizado = 4
}

export type PessoaCategoria = {
  id: number
  descricao: string
  attr_id: PessoaCategoriaAttr
  carga_min: number
  carga_max: number
}

type AlunoIdentificacao = {
  id: string
  curso: Curso
  curso_id: number
  categoria_id: number
  setor_id: number
  data_inicial: string
  data_final: string
}

type MestradoIdentificacao = {
  id: string
  curso_id: number
  responsavel_id: number
  local_id: number
}

type ExtensaoIdentificacao = {
  id: string
  curso_id: number
  responsavel_id: number
  data_inicial: string
  data_final: string
}

type EstagiarioIdentificacao = {
  id: string
  local_id: number
  setor_id: number
  data_inicial: string
  data_final: string
}

type ServidorIdentificacao = {
  id: string
  local_id: number
  cargo_id: number
  setor_id: number
  eixo_id: number
  funcao_id: number
}

type TerceirizadoIdentificacao = {
  id: string
  responsavel_id: number
  contato_responsavel: string
  empresa_id: number
  cargo_id: number
  setor_id: number
  local_id: number
}

type VisitanteIdentificacao = {
  id: string
  responsavel_id: number
  motivo: string
  data_inicial: string
  data_final: string
}
