import { DateTime } from 'luxon'

import * as S from './styles'

type Props = {
  items: any[]
}

const DropdownContent: React.FC<Props> = ({ items }) => {
  return (
    <S.Container>
      {items.length > 0 ? (
        items.map((item, index) => (
          <S.Item key={index}>
            <S.Image src={item.image} />
            <S.Details>
              <S.Subject>{item.subject}</S.Subject>
              <S.Date>{DateTime.fromISO(item.date).toRelative()}</S.Date>
            </S.Details>
          </S.Item>
        ))
      ) : (
        <S.NoData>Por enquanto, não há nada aqui.</S.NoData>
      )}
    </S.Container>
  )
}

export default DropdownContent
