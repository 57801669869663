import styled from 'styled-components'

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 0px;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }
`

export const MessageTitle = styled.h2`
  font-weight: 600;
`

export const MessageErrorItem = styled.p`
  margin-bottom: 0;
`
