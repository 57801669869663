import { PessoaSituacaoAttr, PessoaTipo } from '@core/models/entities'
import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

type Props = {
  selectedTipo: PessoaTipo
  situacaoAttr?: PessoaSituacaoAttr
}

export const SituacaoSelectApi: React.FC<Props> = ({
  selectedTipo,
  situacaoAttr
}) => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      key={situacaoAttr}
      label="Situação"
      name="situacao"
      path={`/situacoes?attr=${situacaoAttr}?sort_by=descricao`}
      control={control}
      error={formState?.errors.situacao?.message}
      placeholder="Selecione aqui"
      isRequired
      isPaginate={false}
      isDisabled={selectedTipo === undefined || selectedTipo === null}
      getOptionLabel={option => option.descricao}
      getOptionValue={option => option.id}
      searchKey="descricao"
    />
  )
}

export default SituacaoSelectApi
