import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useFieldArray } from 'react-hook-form'

import { GrupoAcesso } from '@core/models/entities'
import { useForm } from '@presentation/hooks'
import {
  DateInput,
  Input,
  NumberInput,
  RowsOnCell,
  Section,
  SubmitButton,
  TextInput
} from '@presentation/components/molecules'
import { Table } from '@presentation/components/organisms'
import {
  PessoaFormInput,
  PessoaIdentificacaoFormInput,
  TableColumn
} from '@presentation/types/components/organisms'

import {
  AddIdentificacaoModal,
  CatracaStatusTag,
  DeleteIdentificacaoModal,
  EditIdentificacaoModal,
  PersonPicture
} from './components'
import * as S from './styles'

type Props = {
  defaultProfilePhoto?: File
  canTransit?: boolean
}

const PessoaForm: React.FC<Props> = ({ defaultProfilePhoto, canTransit }) => {
  const { control, register, formState, getValues, setValue, onSubmit } =
    useForm()
  const { fields, append, update, remove } = useFieldArray<PessoaFormInput>({
    name: 'identificacoes',
    control
  })

  const [identificacoes, setIdentificacoes] = useState<
    PessoaIdentificacaoFormInput[]
  >([])
  const [profilePhoto, setProfilePhoto] = useState<File>()

  useEffect(() => {
    const values = getValues()

    if (values.identificacoes) {
      setIdentificacoes(values.identificacoes)
    }
  }, [getValues()])

  useEffect(() => {
    if (defaultProfilePhoto) {
      setProfilePhoto(defaultProfilePhoto)
    }
  }, [defaultProfilePhoto])

  useEffect(() => {
    if (profilePhoto) {
      setValue('foto', profilePhoto)
    }
  }, [profilePhoto])

  const columns: Array<TableColumn<PessoaIdentificacaoFormInput>> = [
    { name: 'Tipo', selector: data => data.tipo.descricao, center: true },
    {
      name: 'Identificação',
      selector: data => data.identificacao,
      center: true
    },
    {
      name: 'Situação',
      selector: data => {
        if (data.situacao) return data.situacao.descricao
        if (data.tipo.attr === 'visitante') return 'Ativo'

        return ''
      },
      center: true
    },
    {
      name: 'Grupos de Acesso',
      cell: data => {
        const items =
          data.gruposAcesso?.map((grupo: GrupoAcesso) => grupo.nome) ?? []

        if (items) {
          return <RowsOnCell items={items} />
        }
      },
      center: true
    },
    {
      name: 'Ações',
      cell: (data, index) => (
        <>
          <EditIdentificacaoModal id={index} fields={fields} update={update} />
          {data.identificacaoId ? null : (
            <DeleteIdentificacaoModal
              id={index}
              identificacao={data.identificacao}
              remove={remove}
            />
          )}
        </>
      ),
      center: true
    }
  ]

  return (
    <Form onSubmit={onSubmit}>
      <Section
        title="Dados da Pessoa"
        actions={
          canTransit !== undefined
            ? [
                <CatracaStatusTag
                  status={
                    canTransit
                      ? 'Trânsito autorizado'
                      : 'Trânsito não autorizado'
                  }
                  variant={canTransit ? 'primary' : 'warning'}
                />
              ]
            : []
        }
      />

      <Row className="mb-3">
        <S.PictureCol lg="5" xl="4">
          <PersonPicture file={profilePhoto} onFileChange={setProfilePhoto} />
        </S.PictureCol>
        <Col>
          <Row>
            <Col sm="6" className="mb-3">
              <Input
                label="Nome"
                name="nome"
                register={register}
                error={formState?.errors.nome?.message}
                required
                placeholder="Digite o nome da pessoa"
              />
            </Col>

            <Col sm="6" className="mb-3">
              <Input
                label="Nome Social"
                name="nomeSocial"
                register={register}
                error={formState?.errors.nomeSocial?.message}
                placeholder="Digite o nome social da pessoa"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="mb-3">
              <DateInput
                label="Data de Nascimento"
                name="dataNascimento"
                register={register}
                error={formState?.errors.dataNascimento?.message}
                required
                placeholder="Escolha a data de nascimento da pessoa"
              />
            </Col>
            <Col sm="6" className="mb-3">
              <NumberInput
                label="RG"
                name="rg"
                control={control}
                error={formState?.errors.rg?.message}
                placeholder="Digite o RG da pessoa"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="mb-3">
              <NumberInput
                label="CPF"
                name="cpf"
                control={control}
                error={formState?.errors.cpf?.message}
                required
                type="text"
                placeholder="Digite o CPF da pessoa"
                format="###.###.###-##"
              />
            </Col>
            <Col sm="6" className="mb-3">
              <Input
                label="Email Institucional"
                name="emailInstitucional"
                register={register}
                error={formState?.errors.emailInstitucional?.message}
                type="text"
                placeholder="Digite o email institucional da pessoa"
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6" className="mb-3">
              <Input
                label="Email Pessoal"
                name="emailPessoal"
                register={register}
                error={formState?.errors.emailPessoal?.message}
                type="text"
                placeholder="Digite o email pessoal da pessoa"
              />
            </Col>
            <Col sm="6" className="mb-3">
              <NumberInput
                label="Telefone"
                name="telefone"
                control={control}
                error={formState?.errors.telefone?.message}
                placeholder="Digite o telefone da pessoa"
                format={'(##) #####-####'}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <NumberInput
                label="Número do Cartão"
                name="numeroCartao"
                control={control}
                error={formState?.errors.numeroCartao?.message}
                placeholder="Digite o número do cartão da pessoa"
                allowLeadingZeros={true}
                onKeyPress={(
                  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-3">
        <TextInput
          label="Observações"
          name="observacoes"
          register={register}
          error={formState?.errors.observacoes?.message}
          placeholder="Escreva aqui as observações sobre a pessoa"
        />
      </Row>

      <Section
        title="Identificações"
        actions={[<AddIdentificacaoModal append={append} fields={fields} />]}
      />

      <Row className="mb-3">
        {identificacoes.length > 0 && (
          <Table data={identificacoes} columns={columns}></Table>
        )}
      </Row>

      <S.Footer>
        <S.ButtonGroup>
          <SubmitButton
            isValid={formState.isValid}
            isSubmitting={formState.isSubmitting}
          >
            Salvar
          </SubmitButton>
        </S.ButtonGroup>
      </S.Footer>
    </Form>
  )
}

export default PessoaForm
