import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPen as EditIcon } from 'react-icons/fa'

import { FormContext } from '@presentation/contexts'
import { useModal } from '@presentation/hooks'
import { ActionButton } from '@presentation/components/molecules'
import {
  Modal,
  PessoaIdentificacaoForm
} from '@presentation/components/organisms'
import {
  ModalProps,
  PessoaIdentificacaoFormInput
} from '@presentation/types/components/organisms'

import { hasIdentificacaoDuplicate, validationSchema } from './shared'

type Props = {
  id: number
  update: (index: number, value: any) => void
  fields: any[]
}

const EditIdentificacaoModal: React.FC<Props> = ({ id, fields, update }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const formMethods = useForm<PessoaIdentificacaoFormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const identificacao = formMethods.watch('identificacao')

  const [hasDuplicate, setHasDuplicate] = useState<boolean>(false)
  const [data, setData] = useState<PessoaIdentificacaoFormInput>()

  useEffect(() => {
    if (isOpen) {
      const data = fields[id]
      setData(data)
    }
  }, [isOpen])

  useEffect(() => {
    if (data) {
      formMethods.reset(data)
      setData(undefined)
      void forceValidation()
    }
  }, [data])

  const forceValidation = async () => {
    await formMethods.trigger()
  }

  useEffect(() => {
    const hasDuplicate = hasIdentificacaoDuplicate(identificacao, fields, id)
    if (hasDuplicate) {
      setHasDuplicate(true)
    } else {
      setHasDuplicate(false)
    }
  }, [identificacao])

  const onSuccess = () => {
    formMethods.reset()
    closeModal()
  }

  const onSubmit = () => {
    const input = formMethods.getValues()
    update(id, input)
    onSuccess()
  }

  const buttonActions = [
    <Button
      variant="success"
      disabled={!formMethods.formState.isValid || hasDuplicate}
      onClick={onSubmit}
    >
      Salvar
    </Button>
  ]

  const modalConfigs: ModalProps = {
    isOpen,
    icon: <EditIcon />,
    title: `Edição de Identificação`,
    description: `Use o formulário abaixo para atualizar a identificação selecionada.`,
    actions: buttonActions,
    size: 'lg',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="edit" onClick={openModal} />

      <Modal {...modalConfigs}>
        <FormContext.Provider value={formMethods}>
          <PessoaIdentificacaoForm
            fields={fields}
            ignoreId={id}
            hasDuplicate={hasDuplicate}
          />
        </FormContext.Provider>
      </Modal>
    </>
  )
}

export default EditIdentificacaoModal
