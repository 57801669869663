import { ReactNode, useEffect, useState } from 'react'
import { ButtonVariant } from 'react-bootstrap/esm/types'

import { ComprovanteStatus } from '@core/types/services'

export const useStatus = () => {
  const [status, setStatus] = useState<ComprovanteStatus>(
    ComprovanteStatus.NaoEnviado
  )
  const [variant, setVariant] = useState<ButtonVariant>('neutral')
  const [message, setMessage] = useState<string | ReactNode>()
  const [motivoRecusa, setMotivoRecusa] = useState<string>()

  useEffect(() => {
    switch (status) {
      case ComprovanteStatus.NaoEnviado:
        setVariant('neutral')
        break
      case ComprovanteStatus.Aguardando:
        setVariant('warning')
        setMessage(
          'Seu comprovante de vacinação foi enviado e passará por um processo de validação.'
        )
        break
      case ComprovanteStatus.Deferido:
        setVariant('success')
        setMessage(
          'Seu comprovante de vacinação foi aceito, sua entrada ao campus foi liberada!'
        )
        break

      case ComprovanteStatus.DeferidoManualmente:
        setVariant('success')
        setMessage(
          'Seu comprovante de vacinação foi aceito manualmente, sua entrada ao campus foi liberada!'
        )
        break

      case ComprovanteStatus.Indeferido:
        setVariant('danger')
        setMessage(
          <>
            <p>
              Atenção! Seu comprovante de vacinação foi negado, por favor envie
              novamente!
            </p>
            <span>
              <strong>Motivo:</strong> {motivoRecusa}
              {}
            </span>
          </>
        )
        break
    }
  }, [status])

  return {
    status,
    variant,
    message,
    setStatus,
    setMotivoRecusa
  }
}

export const canSendComprovante = (status: ComprovanteStatus) => {
  return (
    status === ComprovanteStatus.NaoEnviado ||
    status === ComprovanteStatus.Indeferido
  )
}

export const canViewEnvioHistory = (status: ComprovanteStatus) => {
  return status !== ComprovanteStatus.NaoEnviado
}

export const canCancelEnvio = (status: ComprovanteStatus) => {
  return status === ComprovanteStatus.Aguardando
}
