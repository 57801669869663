import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup
    .string()
    .required('O campo é obrigatório')
    .max(150, 'O campo atingiu o limite máximo de 150 caracteres'),
  descricao: yup
    .string()
    .required('O campo é obrigatório')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres')
})
