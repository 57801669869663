import { SetorForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  onAdd: () => void
}

const AddSetorModal: React.FC<Props> = ({ onAdd }) => {
  const modalConfigs: AddModalProps = {
    path: '/setores',
    formId: 'add-setor',
    nameForTitle: 'Setor',
    validationSchema: validationSchema,
    mapRequest: data => ({
      ...data,
      lotacao_id: data.lotacao.id,
      local_id: data.local.id
    }),
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <SetorForm />
    </AddModal>
  )
}

export default AddSetorModal
