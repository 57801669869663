import { useEffect, useState } from 'react'
import { FaEye as ViewIcon } from 'react-icons/fa'

import { ActionButton } from '@presentation/components/molecules'
import { Modal } from '@presentation/components/organisms'
import { useModal } from '@presentation/hooks'
import { ModalProps } from '@presentation/types/components/organisms'

import ProfileDetails, { ProfileDetailsProps } from '../ProfileDetails'
import { AdministracaoService } from '@core/services'
import { LocalStorage } from '@core/gateways'

const ProfileDetailsModal: React.FC<ProfileDetailsProps> = pessoa => {
  const { isOpen, closeModal, openModal } = useModal()
  const [profilePhoto, setProfilePhoto] = useState<File>()

  useEffect(() => {
    if (isOpen && pessoa.id) {
      void handleProfilePhoto(pessoa.id)
    }
  }, [isOpen])

  const handleProfilePhoto = async (id: string) => {
    setProfilePhoto(undefined)
    const profilePhoto = await AdministracaoService.getProfilePhoto({
      id,
      token: LocalStorage.get({ key: 'accessToken' })
    })

    setProfilePhoto(profilePhoto)
  }

  const configs: ModalProps = {
    isOpen,
    icon: <ViewIcon />,
    title: `Visualização da Pessoa`,
    description: `Confira abaixo os detalhes da pessoa selecionada.`,
    size: 'sm',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="view" onClick={openModal} />
      {isOpen && (
        <Modal {...configs}>
          <ProfileDetails {...pessoa} photo={profilePhoto} />
        </Modal>
      )}
    </>
  )
}

export default ProfileDetailsModal
