import { HiAcademicCap as CursosIcon } from 'react-icons/hi'

import { Curso, PermissaoTipo } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import { AddCursoModal, EditCursoModal, DeleteCursoModal } from './components'
import { Label } from '@presentation/components/molecules'

const CursosPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, fetchData, isFetching, totalItems } = useFetch({
    path: '/cursos',
    options: {
      sortBy: 'nome'
    },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <CursosIcon />,
    title: 'Cursos',
    description: 'Pagina de gerenciamento de cursos do campus.',
    actions: [<AddCursoModal onAdd={fetchData} />]
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        value: 'curso_tipo',
        label: 'Tipo de Curso'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/curso-tipos',
          isMulti: true,
          isPaginate: false
        }
      }
    },
    {
      type: {
        value: 'eixo',
        label: 'Eixo'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/eixos',
          isMulti: true
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    hasStatus: false
  }

  const columns: Array<TableColumn<Curso>> = [
    {
      name: 'Nome',
      cell: curso => (
        <Label tooltipText={curso.externo ? 'Externo' : undefined}>
          {curso.nome}
        </Label>
      ),
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: 'Tipo',
      selector: curso => curso.curso_tipo.nome,
      center: true
    },
    {
      name: 'Eixo',
      selector: curso => curso.eixo?.nome,
      center: true
    },
    {
      name: 'Ações',
      cell: curso => {
        return (
          <>
            {hasPermission(PermissaoTipo.Escrita) && (
              <EditCursoModal id={curso.id} onEdit={fetchData} />
            )}
            {hasPermission(PermissaoTipo.Remocao) && (
              <DeleteCursoModal id={curso.id} onDelete={fetchData} />
            )}
          </>
        )
      },
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      fetchData,
      isFetching,
      totalItems
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default CursosPage
