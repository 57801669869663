import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { Input } from '@presentation/components/molecules'
import { NumberInputProps } from '@presentation/types/components/molecules'

const CustomInput: React.FC<Object> = props => {
  return <Input {...props} />
}

const NumberInput: React.FC<NumberInputProps> = ({
  name = '',
  allowNegative = false,
  control,
  ...rest
}) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value }, ...field }) => (
        <NumberFormat
          customInput={CustomInput}
          value={value}
          onValueChange={({ value }) => {
            onChange(value)
            onBlur()
          }}
          allowNegative={allowNegative}
          mask="_"
          {...field}
          {...rest}
        />
      )}
      name={name}
      control={control}
    />
  )
}

export default NumberInput
