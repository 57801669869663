import { ReactivateModal } from '@presentation/components/templates'

type Props = {
  id: string
  onReactivate: () => void
}

const ReactivateCargoModal: React.FC<Props> = ({ id, onReactivate }) => {
  const modalConfigs = {
    id,
    path: '/cargos',
    nameForTitle: 'Cargo',
    onReactivate
  }

  return <ReactivateModal {...modalConfigs} />
}

export default ReactivateCargoModal
