import { Dispatch, SetStateAction, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Webcam from 'react-webcam'

import { useCamera, useMultiModal } from '@presentation/hooks'

import * as S from './styles'

type Props = {
  onImageChange: Dispatch<SetStateAction<string | undefined>>
}

const CameraStepModal: React.FC<Props> = ({ onImageChange }) => {
  const { nextStep, onActionsChange } = useMultiModal()
  const { webcamRef, imageUrl, capturePicture } = useCamera()

  useEffect(() => {
    onActionsChange([<Button onClick={capturePicture}>Capturar</Button>])
  }, [])

  useEffect(() => {
    if (imageUrl) {
      onImageChange(imageUrl)
      nextStep()
    }
  }, [imageUrl])

  return (
    <S.Container>
      <Webcam
        ref={webcamRef}
        audio={false}
        screenshotFormat="image/jpeg"
        width={'100%'}
      />
    </S.Container>
  )
}

export default CameraStepModal
