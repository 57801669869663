import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaPlus as AddPessoaIcon } from 'react-icons/fa'

import { Alert } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { UnexpectedError, ValidationError } from '@core/models/errors'
import { ObjectUtils } from '@presentation/utils'
import { useErrors } from '@presentation/hooks'
import { FormContext } from '@presentation/contexts'
import { Page } from '@presentation/components/templates'
import { PessoaForm } from '@presentation/components/organisms'
import { ActionButton, ErrorList } from '@presentation/components/molecules'
import { PessoaFormInput } from '@presentation/types/components/organisms'
import { PageProps } from '@presentation/types/components/templates'

import { mapRequest } from '../utils'
import { validationSchema } from '../validation'

const AddPessoaPage: React.FC = () => {
  const navigate = useNavigate()
  const formMethods = useForm<PessoaFormInput>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  })
  const { errors, setErrors, clearErrors } = useErrors({
    shouldScrollTop: true
  })

  const redirectToPessoasPage = () => {
    navigate('/administracao/pessoas')
  }

  const onSuccess = () => {
    Alert.callSuccess({
      title: 'Cadastrado com Sucesso',
      onConfirm: redirectToPessoasPage
    })
  }

  const onError = (error: Error) => {
    Alert.callError({
      title: 'Falha de Cadastro',
      description: error.message
    })
  }

  const onSubmit: SubmitHandler<PessoaFormInput> = async input => {
    try {
      const mappedInput = mapRequest(input)
      const formData = ObjectUtils.serializeToFormData(mappedInput)
      await AdministracaoService.add({
        data: formData,
        path: '/pessoas'
      })
      onSuccess()
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
      }

      if (error instanceof UnexpectedError) {
        onError(error)
      }
    }
  }

  const pageConfigs: PageProps = {
    icon: <AddPessoaIcon />,
    title: 'Cadastro de Pessoa',
    description: `Use o formulário abaixo para cadastrar um novo recurso em nosso banco de dados.`,
    actions: [<ActionButton type="back" onClick={() => navigate(-1)} />]
  }

  return (
    <Page {...pageConfigs}>
      {errors.length > 0 && <ErrorList errors={errors} onHide={clearErrors} />}
      <FormContext.Provider
        value={{
          formId: 'add-pessoa',
          onSubmit: formMethods.handleSubmit(onSubmit),
          ...formMethods
        }}
      >
        <PessoaForm />
      </FormContext.Provider>
    </Page>
  )
}

export default AddPessoaPage
