import {
  FaHome as HomeIcon,
  FaAngleRight as ArrowIcon,
  FaDatabase as AdminIcon,
  FaSyringe as VacinaIcon,
  FaQuestion as HelpIcon,
  FaCheck as PgqIcon
} from 'react-icons/fa'

import { SidebarProps } from '@presentation/types/shared'
import { RecepcaoIcon, RestauranteIcon } from '@presentation/assets/icons'

export const sidebar: SidebarProps = {
  dropdownIcon: <ArrowIcon />,
  menus: [
    {
      icon: <HomeIcon />,
      title: 'Página Inicial',
      route: {
        path: '/'
      }
    },
    {
      icon: <VacinaIcon />,
      title: 'Vacina',
      items: [
        {
          title: 'Enviar Comprovante',
          route: {
            path: '/vacina/envio-comprovante'
          }
        },
        {
          title: 'Validação',
          route: {
            path: '/vacina/validacao'
          }
        },
        {
          title: 'Não Enviados',
          route: {
            path: '/vacina/nao-enviados'
          }
        }
      ]
    },
    {
      icon: <AdminIcon />,
      title: 'Administração',
      items: [
        {
          title: 'Pessoas',
          route: {
            path: '/administracao/pessoas'
          }
        },
        {
          title: 'Grupos de Acesso',
          route: {
            path: '/administracao/grupos-acesso'
          }
        },
        {
          title: 'Blocos',
          route: {
            path: '/administracao/blocos'
          }
        },
        {
          title: 'Eixos',
          route: {
            path: '/administracao/eixos'
          }
        },
        {
          title: 'Lotações',
          route: {
            path: '/administracao/lotacoes'
          }
        },
        {
          title: 'Setores',
          route: {
            path: '/administracao/setores'
          }
        },
        {
          title: 'Cargos',
          route: {
            path: '/administracao/cargos'
          }
        },
        {
          title: 'Funções',
          route: {
            path: '/administracao/funcoes'
          }
        },
        {
          title: 'Locais',
          route: {
            path: '/administracao/locais'
          }
        },
        {
          title: 'Tipos de Local',
          route: {
            path: '/administracao/tipos-local'
          }
        },
        {
          title: 'Cursos',
          route: {
            path: '/administracao/cursos'
          }
        },
        {
          title: 'Feriados',
          route: {
            path: '/administracao/feriados'
          }
        }
      ]
    },
    {
      icon: <RecepcaoIcon />,
      title: 'Recepção',
      items: [
        {
          title: 'Trânsito da Catraca',
          route: {
            path: '/recepcao/transito-catraca'
          }
        }
      ]
    },
    {
      icon: <RestauranteIcon />,
      title: 'Restaurante',
      items: [
        {
          title: 'Trânsito',
          route: {
            path: '/restaurante/transito-lanche'
          }
        }
      ]
    },
    {
      icon: <HelpIcon />,
      title: 'Ajuda',
      items: [
        {
          title: 'E-mail Acadêmico',
          route: {
            path: '/ajuda/email-academico'
          }
        }
      ]
    },
    {
      icon: <PgqIcon />,
      title: 'PGQ',
      route: {
        path: '/pgq',
        exact: true
      }
    }
  ]
}
