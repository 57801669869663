import { useForm } from '@presentation/hooks'
import { DateInput } from '@presentation/components/molecules'

const DataFimInput: React.FC = () => {
  const { register, formState, watch } = useForm()

  const beginDate = watch('dataInicio')

  return (
    <DateInput
      label="Data de Fim"
      name="dataFim"
      register={register}
      error={formState?.errors.dataFim?.message}
      placeholder="Escolha a data de fim"
      min={beginDate}
      required
    />
  )
}

export default DataFimInput
