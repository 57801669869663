import { createContext } from 'react'

import { ILinksRapidosService } from '@core/contracts/services'

type Props = {
  service: ILinksRapidosService
}

export const LinksRapidosContext = createContext({} as Props)

export const LinksRapidosProvider: React.FC<Props> = ({
  children,
  ...rest
}) => {
  return (
    <LinksRapidosContext.Provider value={rest}>
      {children}
    </LinksRapidosContext.Provider>
  )
}
