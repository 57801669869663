import { Section } from '@presentation/components/molecules'

const DescricaoServicoSection: React.FC = () => {
  return (
    <>
      <Section title="Descrição do Serviço" />

      <p>
        O sistema de gestão integrado (ERP) Intranet é uma iniciativa da
        Coordenação de Tecnologia da Informação (CTI) do IFCE campus Maracanaú,
        desenvolvido pelo Núcleo de Desenvolvimento de Software (NDS). A
        princípio, a plataforma foi criada e desenvolvida com o propósito de
        centralizar as informações dos usuários (alunos, servidores e
        visitantes) em uma base de dados comum, garantindo assim a troca segura
        e confiáveis de informações entre os módulos do sistema. Fora isto, o
        serviço em questão permite ter controle da entrada e saída das pessoas
        no prédio da instituição, aquisição e acompanhamento das refeições do
        Restaurante Acadêmico do campus, otimização dos processos internos do
        setor administrativo, entre outros.
      </p>
    </>
  )
}

export default DescricaoServicoSection
