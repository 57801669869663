import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'

export const ItemWrapper = styled.div`
  width: 285px;
  display: block;
  margin: auto;
  justify-content: center;
`

export const Item = styled(Dropdown.Item)`
  padding: 0.525rem 0.525rem 0.525rem 1.25rem;
  color: ${props => props.theme.colors.textPrimary};

  &:hover {
    background-color: ${props => props.theme.colors.backgroundHover};
    border-radius: 5px;
  }
`
