import { memo } from 'react'
import { Form } from 'react-bootstrap'
import {
  FaLock as SenhaIcon,
  FaUserCircle as IdentificacaoIcon
} from 'react-icons/fa'

import { useForm } from '@presentation/hooks'

import * as S from './styles'

const LoginForm: React.FC = () => {
  const { register, formState, onSubmit } = useForm()

  return (
    <Form onSubmit={onSubmit} id="login-form">
      <Form.Group className="mb-3">
        <S.Input isInvalid={formState.errors.identificacao} className="mb-1">
          <S.InputIcon>
            <IdentificacaoIcon />
          </S.InputIcon>
          <S.InputField
            {...register('identificacao')}
            placeholder="Identificação"
            type="text"
            autocomplete="off"
          />
        </S.Input>
        {formState.errors.identificacao && (
          <Form.Text className="text-danger">
            {formState.errors.identificacao.message}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <S.Input isInvalid={formState.errors.senha} className="mb-1">
          <S.InputIcon>
            <SenhaIcon />
          </S.InputIcon>
          <S.InputField
            {...register('senha')}
            placeholder="Senha"
            type="password"
          />
        </S.Input>

        {formState.errors.senha && (
          <Form.Text className="text-danger">
            {formState.errors.senha.message}
          </Form.Text>
        )}
      </Form.Group>

      <S.CustomSubmitButton
        isValid={formState.isValid}
        isSubmitting={formState.isSubmitting}
      >
        Login
      </S.CustomSubmitButton>
    </Form>
  )
}

export default memo(LoginForm)
