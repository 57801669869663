import styled from 'styled-components'
import { Accordion, AccordionProps } from 'react-bootstrap'

export const StyledAccordion = styled(Accordion)<AccordionProps>`
  button {
    border-radius: 5px;
    background-color: ${props => props.theme.colors.backgroundContent};
    color: ${props => props.theme.colors.textPrimary};
    box-shadow: none;
    &:focus {
      box-shadow: none;
      background-color: ${props =>
        props.theme.colors.backgroundContentAccordeon};
      color: ${props => props.theme.colors.textPrimary};
    }
    &::after {
      filter: ${props => (props.mode === 'dark' ? 'invert(1)' : 'invert(0)')};
    }
    &:not(.collapsed) {
      background-color: ${props =>
        props.mode === 'light'
          ? props.theme.colors.backgroundSidebar
          : props.theme.colors.backgroundContentAccordeon};
      color: ${props =>
        props.mode === 'dark'
          ? props.theme.colors.textPrimary
          : props.theme.colors.textSecondary};
      box-shadow: none;
    }
    &:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c0d9'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      filter: ${props => (props.mode === 'light' ? 'invert(1)' : 'invert(0)')};
    }
  }
`

export const StyledAccordionItem = styled(Accordion.Item)<AccordionProps>`
  background: transparent !important;
`

export const StyledAccordionBody = styled(Accordion.Body)<AccordionProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  border-left: ${props => props.theme.colors.primary} 2px solid;
  margin: 0.1rem 0.5rem;
  padding: 0.7rem;
  a {
    &:hover {
      color: ${props =>
        props.theme.title === 'dark' ? 'white' : 'props.theme.colors.primary'};
    }
  }
`

export const StyledAccordionHeader = styled(Accordion.Header)<AccordionProps>``
