import { useState, useEffect } from 'react'

import { UseErrors } from '@presentation/types/hooks'

export const useErrors: UseErrors = input => {
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (errors.length > 0) {
      if (input?.shouldScrollTop) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }, [errors])

  const clearErrors = () => {
    setErrors([])
  }

  return {
    errors,
    setErrors,
    clearErrors
  }
}
