import * as S from './styles'

const DropFile: React.FC = () => {
  return (
    <S.Container>
      <S.UploadIcon />
      <S.Info>Solte o arquivo</S.Info>
    </S.Container>
  )
}

export default DropFile
