import { Alert } from 'react-bootstrap'

import { MessageProps } from '@presentation/types/components/molecules'

const Message: React.FC<MessageProps> = ({ onHide, children, ...rest }) => {
  return (
    <Alert
      onClose={() => onHide?.()}
      dismissible={onHide !== undefined}
      {...rest}
    >
      {children}
    </Alert>
  )
}

export default Message
