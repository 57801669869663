import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaCamera as CameraIcon } from 'react-icons/fa'

import { useModal } from '@presentation/hooks'
import { MultiModal } from '@presentation/components/organisms'
import { MultiModalProps } from '@presentation/types/components/organisms'

import { CameraStepModal, CropperStepModal } from './components'

type Props = {
  onPictureChange: Dispatch<SetStateAction<File | undefined>>
}

const TakePictureMultiModal: React.FC<Props> = ({ onPictureChange }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const [step, setStep] = useState<number>(1)
  const [capturedImage, setCapturedImage] = useState<string>()
  const [croppedImage, setCroppedImage] = useState<File>()
  const [content, setContent] = useState<ReactNode>()

  useEffect(() => {
    onPictureChange(croppedImage)
  }, [croppedImage])

  useEffect(() => {
    switch (step) {
      case 1:
        setContent(<CameraStepModal onImageChange={setCapturedImage} />)
        break
      case 2:
        if (capturedImage) {
          setContent(
            <CropperStepModal
              image={capturedImage}
              onImageChange={setCroppedImage}
              onAfterCrop={closeModal}
            />
          )
        }
        break
    }
  }, [step])

  const handleOpenModal = () => {
    setStep(1) // reset modal
    openModal()
  }

  const multiModalConfigs: MultiModalProps = {
    icon: <CameraIcon />,
    title: 'Tirar Foto',
    description: 'Verifique se o ambiente da foto é adequado.',
    isOpen,
    size: 'lg',
    onClose: closeModal,
    step: step,
    onStepChange: setStep
  }

  return (
    <>
      <Button variant="outline-light" onClick={handleOpenModal}>
        Tirar Foto
      </Button>

      <MultiModal {...multiModalConfigs}>{content}</MultiModal>
    </>
  )
}

export default TakePictureMultiModal
