import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FaSearch as SearchIcon } from 'react-icons/fa'

import { useFilter } from '@presentation/hooks'
import { Tag } from '@presentation/components/atoms'
import {
  Label,
  Select,
  Input,
  SubmitButton
} from '@presentation/components/molecules'
import {
  FilterDescriptionInput,
  FilterDescriptionNumberInput,
  FilterDescriptionSelect,
  FilterDescriptionSelectApi,
  FilterProps
} from '@presentation/types/components/organisms'

import {
  DescriptionInput,
  DescriptionNumberInput,
  DescriptionSelect,
  DescriptionSelectApi
} from './components'
import { getStatusFilter } from './utils'
import * as S from './styles'

const Filter: React.FC<FilterProps> = ({
  filters,
  fetchData,
  defaultQueries = {},
  hasStatus = true
}) => {
  const { register, control, handleSubmit, reset } = useForm()

  if (hasStatus) {
    filters = [...filters, getStatusFilter()]
  }

  const {
    typeOptions,
    selectedFilter,
    appliedOptions,
    isAllFiltersHaveBeenApplied,
    applyFilter,
    removeFilter,
    removeAllFilters,
    onTypeChange
  } = useFilter({
    filters,
    fetchData,
    defaultQueries
  })

  const renderDescriptionField = () => {
    if (selectedFilter) {
      switch (selectedFilter.description.type) {
        case 'input':
          const inputConfigs = selectedFilter.description
            .configs as FilterDescriptionInput

          return (
            <DescriptionInput
              type={inputConfigs.type}
              name={selectedFilter.type.value}
              register={register}
            />
          )

        case 'number-input':
          const inputNumberConfigs = selectedFilter.description
            .configs as FilterDescriptionNumberInput

          return (
            <DescriptionNumberInput
              name={selectedFilter.type.value}
              control={control}
              {...inputNumberConfigs}
            />
          )

        case 'select':
          const selectConfigs = selectedFilter.description
            .configs as FilterDescriptionSelect

          return (
            <DescriptionSelect
              name={selectedFilter.type.value}
              options={selectConfigs.options}
              control={control}
            />
          )

        case 'select-api':
          const selectApiConfigs = selectedFilter.description
            .configs as FilterDescriptionSelectApi

          return (
            <DescriptionSelectApi
              name={selectedFilter.type.value}
              path={selectApiConfigs.path}
              control={control}
              isMulti={selectApiConfigs.isMulti}
              isPaginate={selectApiConfigs.isPaginate}
              getOptionLabel={selectApiConfigs.getOptionLabel}
              getOptionValue={selectApiConfigs.getOptionValue}
              searchKey={selectApiConfigs.searchKey}
            />
          )
      }
    }

    return <Input disabled />
  }

  const onSubmit = (data: any) => {
    applyFilter(data)
    reset()
  }

  const onRemove = (value: string) => {
    removeFilter(value)
  }

  const onRemoveAll = () => {
    removeAllFilters()
  }

  return (
    <S.Container>
      <S.Header>Filtros</S.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="align-items-end">
          <Col md={3}>
            <Select
              label="Tipo"
              isRequired
              options={typeOptions}
              onChange={onTypeChange}
              isSearchable={false}
              isClearable={true}
              value={selectedFilter ? selectedFilter.type : null}
              isDisabled={isAllFiltersHaveBeenApplied}
            />
          </Col>
          <Col md={3}>
            <Form.Group>
              <Label isRequired={true}>Descrição</Label>
              {renderDescriptionField()}
            </Form.Group>
          </Col>
          <Col md={1}>
            <SubmitButton
              isValid={
                isAllFiltersHaveBeenApplied || selectedFilter !== undefined
              }
            >
              <SearchIcon />
            </SubmitButton>
          </Col>
          <Col md={3} className="lh-lg">
            {appliedOptions.map(option => (
              <Tag
                key={option.value}
                id={option.value}
                bg="neutral"
                onClose={() => onRemove(option.value)}
                className="me-2"
              >
                {option.label}
              </Tag>
            ))}
          </Col>

          <Col xl={2}>
            <Button
              size="sm"
              className="py-2 float-xl-end"
              onClick={onRemoveAll}
            >
              Limpar Filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </S.Container>
  )
}

export default Filter
