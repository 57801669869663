import { rgba } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => rgba(props.theme.colors.textPrimary, 0.4)};
  margin-bottom: 1rem;
`

export const Title = styled.h2`
  color: ${props => props.theme.colors.textPrimary};
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0.6rem 0;
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
