import { FaSearchLocation as TiposLocalIcon } from 'react-icons/fa'

import { PermissaoTipo, TipoLocal } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { TablePage } from '@presentation/components/templates'
import { PageProps } from '@presentation/types/components/templates'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'

import {
  AddTipoLocalModal,
  DeleteTipoLocalModal,
  EditTipoLocalModal,
  ReactivateTipoLocalModal
} from './components'

const TiposLocalPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/tipos-local',
    options: { sortBy: 'nome' },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <TiposLocalIcon />,
    title: 'Tipos de Local',
    description: 'Página de gerenciamento de tipos de local do campus.',
    actions: hasPermission(PermissaoTipo.Escrita)
      ? [<AddTipoLocalModal onAdd={fetchData} />]
      : []
  }

  const filters: Filter[] = [
    {
      type: {
        value: 'nome',
        label: 'Nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      status: 1
    }
  }

  const columns: Array<TableColumn<TipoLocal>> = [
    {
      name: 'Nome',
      selector: tipoLocal => tipoLocal.nome,
      sortable: true,
      center: true,
      grow: 4
    },
    {
      name: 'Ações',
      cell: tipoLocal => (
        <>
          {tipoLocal.status ? (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <EditTipoLocalModal id={tipoLocal.id} onEdit={fetchData} />
              )}
              {hasPermission(PermissaoTipo.Remocao) && (
                <DeleteTipoLocalModal id={tipoLocal.id} onDelete={fetchData} />
              )}
            </>
          ) : (
            <>
              {hasPermission(PermissaoTipo.Escrita) && (
                <ReactivateTipoLocalModal
                  id={tipoLocal.id}
                  onReactivate={fetchData}
                />
              )}
            </>
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      totalItems,
      fetchData,
      isFetching
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default TiposLocalPage
