import { createContext, Dispatch, ReactNode, SetStateAction } from 'react'

type Props = {
  onActionsChange: Dispatch<SetStateAction<ReactNode[]>>
  previousStep: () => void
  nextStep: () => void
  resetSteps: () => void
}

export const MultiModalContext = createContext<Props>({} as Props)
