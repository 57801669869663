import { Form } from 'react-bootstrap'
import ReactSelect, { Theme } from 'react-select'
import { Controller } from 'react-hook-form'
import { useTheme } from 'styled-components'

import { SelectProps } from '@presentation/types/components/molecules'
import { Label } from '@presentation/components/molecules'
import {
  customReactSelectStyles,
  customReactSelectTheme
} from '@presentation/styles'

const Select: React.FC<SelectProps> = ({
  label,
  control,
  placeholder = 'Selecione aqui',
  isRequired,
  error,
  ...rest
}) => {
  const currentTheme = useTheme()

  const reactSelectProps = {
    ...rest,
    placeholder,
    theme: (theme: Theme) => customReactSelectTheme(currentTheme, theme),
    styles: customReactSelectStyles(currentTheme),
    noOptionsMessage: () => 'Nenhum Resultado'
  }

  return (
    <Form.Group>
      {label && <Label isRequired={isRequired}>{label}</Label>}

      {control ? (
        <Controller
          name={rest.name ?? ''}
          control={control}
          render={({ field: { onChange, value }, ...rest }) => {
            return (
              <ReactSelect
                {...rest}
                {...reactSelectProps}
                onChange={(option: any, { action }) => {
                  // permite enviar apenas o value do option no submit
                  if (option) {
                    onChange(option.value)
                  }

                  if (action === 'clear') {
                    onChange(null)
                  }
                }}
                value={reactSelectProps.options?.find(
                  option => option.value === value
                )}
              />
            )
          }}
        />
      ) : (
        <ReactSelect {...reactSelectProps} />
      )}

      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  )
}

export default Select
