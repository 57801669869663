import { Pessoa } from '@core/models/entities'

export enum TransitoStatus {
  Autorizado,
  NaoAutorizado,
  NenhumTransito
}

export type PessoaData = Pick<Pessoa, 'id' | 'nome' | 'email'> & {
  tipo: string
  identificacao: string
}

export type TransitoData = {
  data: string
  totalTransitos: number
}

export type TransitoAutorizadoMessage = PessoaData & TransitoData

export type TransitoNaoAutorizadoMessage = {
  pessoa: PessoaData
  erros: string[]
}

export type UltimosTransitosMessage = {
  quantidadeTransito: {
    data: string
    quantidade: number
  }
  transitos: PessoaData[]
}
