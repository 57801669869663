import { TipoLocalForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  onAdd: () => void
}

const AddTipoLocalModal: React.FC<Props> = ({ onAdd }) => {
  const modalConfigs: AddModalProps = {
    path: '/tipos-local',
    formId: 'add-tipo-local',
    nameForTitle: 'Tipo de Local',
    validationSchema,
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <TipoLocalForm />
    </AddModal>
  )
}

export default AddTipoLocalModal
