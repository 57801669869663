import { lightTheme } from './../../../styles/themes/light'
import { rgba } from 'polished'
import styled from 'styled-components'

export const PersonPicture = styled.div`
  border: 1px solid ${props => rgba(props.theme.colors.textPrimary, 0.125)};
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.background};
  height: 100%;
  width: 85%;
  margin: 0 auto;
  position: relative;
`

export const Picture = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
`

export const Card = styled.div`
  display: flex;
  flex: 1 0 12rem;
  background-color: ${props => props.theme.colors.primary};
  align-items: center;
  justify-content: center;
  color: ${lightTheme.colors.textSecondary};
  flex-direction: column;
`

export const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.2rem 0;
`
export const CardContent = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0rem;
`

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
  }
`

export const MessageTitle = styled.h2`
  font-weight: 600;
`

export const MessageDescription = styled.p`
  margin-bottom: 0;
`

export const SelectApiWrapper = styled.div`
  width: 250px;
`
