type SendComprovanteInput = {
  file: File
}

export type SendComprovante = (input: SendComprovanteInput) => Promise<void>

export enum ComprovanteStatus {
  NaoEnviado = 'Não Enviado',
  Aguardando = 'Aguardando Análise',
  Deferido = 'Deferido',
  Indeferido = 'Indeferido',
  DeferidoManualmente = 'Deferido Manualmente'
}

export type LoadComprovantes = () => Promise<any[]>

type GetComprovanteInfoOutput = {
  status: ComprovanteStatus
  file?: any
  motivo?: string
}

export type GetComprovanteInfo = () => Promise<GetComprovanteInfoOutput>

type CancelEnvioInput = {
  id: string
}

export type CancelEnvio = (input: CancelEnvioInput) => Promise<void>

type LoadComprovanteFileInput = {
  id: string
  isAdmin: boolean
}

export type LoadComprovanteFile = (
  input: LoadComprovanteFileInput
) => Promise<File>

type AcceptComprovanteInput = {
  id: string
}

export type AcceptComprovante = (input: AcceptComprovanteInput) => Promise<void>

type DenyComprovanteInput = {
  id: string
  motivo: string
}

export type DenyComprovante = (input: DenyComprovanteInput) => Promise<void>

type ReleaseComprovanteInput = {
  id: string
}

export type ReleaseComprovante = (
  input: ReleaseComprovanteInput
) => Promise<void>
