import { FeriadoForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  onAdd: () => void
}

const AddFeriadoModal: React.FC<Props> = ({ onAdd }) => {
  const modalConfigs: AddModalProps = {
    path: '/feriados',
    formId: 'add-feriado',
    nameForTitle: 'Feriado',
    validationSchema: validationSchema,
    mapRequest: data => ({
      ...data,
      feriado_tipo_id: data.tipo.id
    }),
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <FeriadoForm />
    </AddModal>
  )
}

export default AddFeriadoModal
