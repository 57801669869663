export default {
  indexes: {
    low: 1,
    medium: 99,
    high: 999
  },

  transitions: {
    default: 'all cubic-bezier(0.685, 0.0473, 0.346, 1) 330ms',
    color: 'color 500ms cubic-bezier(0.25, 1, 0.5, 1)',
    transform: 'transform 250ms cubic-bezier(0.25, 1, 0.5, 1)'
  },

  shared: {
    dashboard: {
      spacing: '1.25rem'
    },

    navbar: {
      height: '4rem'
    },

    sidebar: {
      width: '16.875rem',
      collapseWidth: '3.75rem'
    }
  }
}
