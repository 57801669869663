import { Badge, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap'

import { TagProps } from '@presentation/types/components/atoms'

import * as S from './styles'

const Tag: React.FC<TagProps> = ({
  id,
  onClose,
  tooltipText,
  children,
  ...rest
}) => {
  const renderTooltip = (props: TooltipProps) => {
    return tooltipText ? <Tooltip {...props}>{tooltipText}</Tooltip> : <></>
  }

  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      <Badge
        {...rest}
        key={id}
        style={{ cursor: tooltipText ? 'pointer' : 'default' }}
      >
        {children}
        {onClose && <S.CloseIcon className="ms-1" onClick={() => onClose()} />}
      </Badge>
    </OverlayTrigger>
  )
}

export default Tag
