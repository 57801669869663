import { Form } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import { GroupBase, Theme } from 'react-select'
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate'
import { useTheme } from 'styled-components'

import { HttpClient } from '@core/gateways'
import { setupIntranetApiClient } from '@core/proxies/apis'

import { Label } from '@presentation/components/molecules'
import {
  SelectOption,
  SelectApiProps,
  SelectApiAdditional
} from '@presentation/types/components/molecules'
import {
  customReactSelectStyles,
  customReactSelectTheme
} from '@presentation/styles'

const SelectApi: React.FC<SelectApiProps> = ({
  path,
  control,
  name = '',
  label,
  error,
  searchKey = 'nome',
  placeholder = 'Selecione aqui',
  getOptionLabel,
  getOptionValue,
  isPaginate = true,
  isRequired,
  isClearable = false,
  isDisabled,
  ...rest
}) => {
  const currentTheme = useTheme()

  const loadOptions: LoadOptions<
    SelectOption,
    GroupBase<SelectOption>,
    SelectApiAdditional
  > = async (searchQuery, _, additional) => {
    const response = await HttpClient.of(setupIntranetApiClient()).request({
      url: `${path}`,
      method: 'GET',
      params: {
        ...(isPaginate && { page: additional?.page }),
        ...(isPaginate && { per_page: 20 }),
        status: 1,
        [searchKey]: searchQuery
      }
    })

    return {
      options: isPaginate ? response.body.data.items : response.body.data,
      ...(isPaginate && {
        hasMore: response.body.data.pagination.has_more_pages
      }),
      ...(isPaginate && {
        additional: {
          page: searchQuery ? 2 : +additional!.page + 1
        }
      })
    }
  }

  const getSelectConfigs = () => {
    return {
      key: name,
      loadOptions: loadOptions,
      ...(isPaginate && {
        additional: {
          page: 1
        }
      }),

      isSearchable: true,
      isClearable: isClearable,
      isDisabled: isDisabled,
      placeholder: placeholder,
      getOptionLabel: (option: any) =>
        getOptionLabel ? getOptionLabel(option) : option.nome,
      getOptionValue: (option: any) =>
        getOptionValue ? getOptionValue(option) : option.id,
      noOptionsMessage: () => 'Nenhum Resultado',
      theme: (theme: Theme) => customReactSelectTheme(currentTheme, theme),
      styles: customReactSelectStyles(currentTheme),
      ...rest
    }
  }

  return (
    <Form.Group>
      {label && <Label isRequired={isRequired}>{label}</Label>}

      {control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <AsyncPaginate
                value={field.value}
                onChange={field.onChange}
                {...getSelectConfigs()}
              />
            )
          }}
        />
      ) : (
        <AsyncPaginate
          value={rest.value}
          onChange={rest.onChange}
          {...getSelectConfigs()}
        />
      )}

      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form.Group>
  )
}

export default SelectApi
