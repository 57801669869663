import { rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
  }

  body {
    font: 400 1rem 'Karla', 'Inter', Helvetica, sans-serif;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.textPrimary};
    overflow-x: hidden;
  }

  ol, ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colors.secondary}
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 14px 14px transparent;
    border: solid 4px transparent;
    border-radius: .5rem;
    background: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${props =>
      rgba(props.theme.colors.primary, 0.7)};
    border: solid 3px transparent;
    border-radius: 14px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 10px 10px ${props => props.theme.colors.primary};
  }
`

export default GlobalStyle
