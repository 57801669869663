import { DeleteModal } from '@presentation/components/templates'
import { DeleteModalProps } from '@presentation/types/components/templates'

type Props = {
  id: number
  onDelete: () => void
}

const DeleteCursoModal: React.FC<Props> = ({ id, onDelete }) => {
  const modalConfigs: DeleteModalProps = {
    id,
    nameForTitle: 'Curso',
    path: '/cursos',
    onDelete
  }

  return <DeleteModal {...modalConfigs} />
}

export default DeleteCursoModal
