import {
  FaEye as ViewIcon,
  FaPen as EditIcon,
  FaTrash as DeleteIcon,
  FaUndoAlt as ReactivateIcon,
  FaCheck as ApproveIcon,
  FaTimes as DenyIcon,
  FaTelegramPlane as SendIcon,
  FaUnlockAlt as ResetPasswordIcon
} from 'react-icons/fa'

import { ActionModalButtonProps } from '@presentation/types/shared'

export const actionButtons: ActionModalButtonProps = {
  add: {
    variant: 'primary',
    content: 'Novo'
  },

  view: {
    variant: 'info',
    content: <ViewIcon />,
    tooltipText: 'Visualizar'
  },

  edit: {
    variant: 'warning',
    content: <EditIcon />,
    tooltipText: 'Editar'
  },

  delete: {
    variant: 'danger',
    content: <DeleteIcon />,
    tooltipText: 'Remover'
  },

  reactivate: {
    variant: 'info',
    content: <ReactivateIcon />,
    tooltipText: 'Reativar'
  },

  approve: {
    variant: 'success',
    content: <ApproveIcon />,
    tooltipText: 'Deferir'
  },

  deny: {
    variant: 'danger',
    content: <DenyIcon />,
    tooltipText: 'Indeferir'
  },

  send: {
    variant: 'success',
    content: <SendIcon />,
    tooltipText: 'Liberar'
  },

  back: {
    variant: 'primary',
    content: 'Voltar'
  },

  resetPassword: {
    variant: 'danger',
    content: <ResetPasswordIcon />,
    tooltipText: 'Redefinir senha'
  }
}
