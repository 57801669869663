import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

export const ResponsavelSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Responsável"
      name="responsavel"
      path="/pessoa-identificacoes?tipo=3&situacao=5&sort_by=nome"
      control={control}
      error={formState?.errors.responsavel?.message}
      getOptionLabel={option => option.pessoa?.nome}
      getOptionValue={option => option.id}
      isRequired
    />
  )
}

export default ResponsavelSelectApi
