import { Yup } from '@core/proxies'

export const validationSchema = Yup.object({
  nome: Yup.string()
    .required('O campo é obrigatório.')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.')
    .personName('Digite um nome válido.'),
  nomeSocial: Yup.string()
    .nullable()
    .personName('Digite um nome válido.')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.'),
  dataNascimento: Yup.string().nullable().required('O campo é obrigatório.'),
  cpf: Yup.string()
    .required('O campo é obrigatório.')
    .cpf('Digite um CPF válido.'),
  emailInstitucional: Yup.string()
    .nullable()
    .email('Digite um e-mail válido.')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.'),
  emailPessoal: Yup.string()
    .nullable()
    .email('Digite um e-mail válido.')
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.'),
  telefone: Yup.string()
    .nullable()
    .phone('Digite um telefone válido.')
    .max(20, 'O campo atingiu o limite máximo de 20 caracteres'),
  observacoes: Yup.string()
    .nullable()
    .max(255, 'O campo atingiu o limite máximo de 255 caracteres.'),
  identificacoes: Yup.array()
    .min(1, 'É necessário que a pessoa possua pelo menos uma identificação.')
    .required('required')
})
