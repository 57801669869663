import { media } from '@presentation/styles'
import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 0 0.75rem 0.75rem;
  margin-top: 0rem;

  ${media.greaterThan('mobile')`
    margin-top: 1.2rem;
  `}
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;

  * {
    margin-bottom: 0.5rem;
  }

  ${media.greaterThan('mobile')`
    flex-direction: row;

    * {
      margin-left: 1.2rem;
     }
  `}
`
