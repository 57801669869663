import { PessoaCategoriaAttr, PessoaTipo } from '@core/models/entities'
import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

type Props = {
  selectedTipo: PessoaTipo
  categoriaAttr?: PessoaCategoriaAttr
}

const CategoriaSelectApi: React.FC<Props> = ({ categoriaAttr }) => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      key={categoriaAttr}
      label="Categoria"
      name="categoria"
      path={`/categorias?attr_id=${categoriaAttr}&sort_by=descricao`}
      control={control}
      error={formState?.errors.categoria?.message}
      isRequired
      isPaginate={false}
      getOptionLabel={option => option.descricao}
      getOptionValue={option => option.id}
      searchKey="descricao"
    />
  )
}

export default CategoriaSelectApi
