import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`

export const InfoWrapper = styled.span`
  margin-left: 4px;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.primary};
  }
`
