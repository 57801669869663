import * as yup from 'yup'

export const validationSchema = yup.object({
  wasAccepted: yup
    .boolean()
    .oneOf(
      [true],
      'Você deve aceitar os termos de uso para acessar a plataforma'
    )
})
