import ReactSlider, { SliderProps as ReactSliderProps } from 'rc-slider'
import { useTheme } from 'styled-components'

type SliderProps = Omit<
  ReactSliderProps,
  'handleStyle' | 'trackStyle' | 'railStyle'
>

const Slider: React.FC<SliderProps> = props => {
  const theme = useTheme()

  return (
    <ReactSlider
      {...props}
      handleStyle={{ backgroundColor: theme.colors.primary }}
      trackStyle={{
        background: theme.colors.primary
      }}
    />
  )
}

export default Slider
