import { Profile } from '@presentation/components/molecules'

import * as S from './styles'

const NotificationDropdownHeader: React.FC = () => {
  return (
    <S.Container>
      <Profile />
    </S.Container>
  )
}

export default NotificationDropdownHeader
