import { FaCheck as CheckIcon } from 'react-icons/fa'
import { withLinksRapidos } from '@presentation/components/hocs'
import { Page } from '@presentation/components/templates'
import { Section, Accordion } from '@presentation/components/molecules'
import * as S from './styles'
import { dapConfigs, dgConfigs, direnConfigs } from '../utils/pgq-links'

const GestaoQualidade = () => {
  const pageConfigs = {
    title: 'Página de plano gestão de qualidade',
    description: 'Página para visualização de gestão de qualidade.',
    icon: <CheckIcon />,
    actions: []
  }

  return (
    <Page {...pageConfigs}>
      <Section title="Lista de PDF's">
        <S.AccordionWrapper>
          <S.Container>
            <S.Subtitle>
              DAP - Diretoria de Administração e Planejamento
            </S.Subtitle>
            {dapConfigs.map((singleAccordion, index) => {
              return <Accordion {...singleAccordion} key={index} />
            })}
          </S.Container>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <S.Container>
            <S.Subtitle>DG - Direção Geral</S.Subtitle>
            {dgConfigs.map((singleAccordion, index) => {
              return <Accordion {...singleAccordion} key={index} />
            })}
          </S.Container>
        </S.AccordionWrapper>
        <S.AccordionWrapper>
          <S.Container>
            <S.Subtitle>DIREN - Diretoria de Ensino</S.Subtitle>
            {direnConfigs.map((singleAccordion, index) => {
              return <Accordion {...singleAccordion} key={index} />
            })}
          </S.Container>
        </S.AccordionWrapper>
      </Section>
    </Page>
  )
}

export default withLinksRapidos(GestaoQualidade)
