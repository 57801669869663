import { memo, useEffect, useState } from 'react'

import { useAuth, useDashboard } from '@presentation/hooks'
import { SidebarMenuProps } from '@presentation/types/shared'

import {
  SidebarNavigationMenu,
  SidebarNavigationMenuDropdown
} from './menu/sidebar-navigation-menu'
import * as S from './sidebar-navigation.styles'

const SidebarNavigation: React.FC = () => {
  const { canAccessRoute } = useAuth()
  const { isOpenSidebar, sidebar } = useDashboard()
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null)

  const onOpenDropdown = (index: number) => setActiveDropdown(index)
  const onCloseDropdown = () => setActiveDropdown(null)

  const canViewMenu = (menu: SidebarMenuProps) => {
    if (menu.items) {
      const menuPaths = menu.items.map(item => item.route.path)
      const canView = menuPaths.some(menuPath => {
        return canAccessRoute(menuPath)
      })

      return canView
    }

    if (menu.route) {
      return canAccessRoute(menu.route.path)
    }

    return false
  }

  // Fecha o dropdown ativo quando houver mudança de aberto/fechado no sidebar
  useEffect(() => {
    setActiveDropdown(null)
  }, [!isOpenSidebar])

  return (
    <S.Container>
      <S.MenuWrapper>
        {sidebar.menus.map((menu, index) => {
          const commonProps = {
            menu: menu,
            key: index
          }

          return (
            <>
              {canViewMenu(menu) ? (
                menu.items ? (
                  <SidebarNavigationMenuDropdown
                    isOpen={activeDropdown === index}
                    onOpen={() => onOpenDropdown(index)}
                    onClose={() => onCloseDropdown()}
                    {...commonProps}
                  />
                ) : (
                  <SidebarNavigationMenu {...commonProps} />
                )
              ) : null}
            </>
          )
        })}
      </S.MenuWrapper>
    </S.Container>
  )
}

export default memo(SidebarNavigation)
