import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

const CargoSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Cargo"
      name="cargo"
      path={`/cargos?sort_by=nome`}
      control={control}
      error={formState?.errors.cargo?.message}
      isRequired
    />
  )
}

export default CargoSelectApi
