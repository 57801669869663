import { AxiosInstance } from 'axios'

import { HttpClient } from '@core/gateways'
import { LinkRapido } from '@core/models/entities'
import { NotFoundError, UnexpectedError } from '@core/models/errors'
import { setupIntranetApiClient } from '@core/proxies/apis'
import { HttpStatusCode } from '@core/types/gateways'
import { ILinksRapidosService } from '@core/contracts/services'

export class LinksRapidosService implements ILinksRapidosService {
  httpClient: AxiosInstance

  constructor() {
    this.httpClient = setupIntranetApiClient()
  }

  async loadAll(): Promise<LinkRapido[]> {
    const response = await HttpClient.of(setupIntranetApiClient()).request({
      url: '/links-rapidos',
      method: 'GET'
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body.data.items
      default:
        throw new UnexpectedError()
    }
  }

  async loadImageById(id: number): Promise<File> {
    const response = await HttpClient.of(setupIntranetApiClient()).request({
      url: `/links-rapidos/${id}/imagem`,
      method: 'GET',
      responseType: 'blob'
    })

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        const blob = new Blob([response.body])
        const file = new File([blob], `${id}`, {
          type: response.headers['content-type']
        })
        return file
      case HttpStatusCode.notFound:
        throw new NotFoundError()
      default:
        throw new UnexpectedError()
    }
  }
}
