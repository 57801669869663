import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaHistory as HistoryIcon } from 'react-icons/fa'

import { Comprovante } from '@core/models/entities'
import { VacinaService } from '@core/services'
import { Alert, Date } from '@core/gateways'
import { ComprovanteStatus } from '@core/types/services'
import { useModal } from '@presentation/hooks'
import { toTitleCase } from '@presentation/utils'
import { Modal, Table } from '@presentation/components/organisms'
import { ViewComprovanteModal } from '@presentation/components/shared'
import {
  ModalProps,
  TableColumn
} from '@presentation/types/components/organisms'
import { Tag } from '@presentation/components/atoms'

const ShowEnvioHistoryModal: React.FC = () => {
  const { isOpen, openModal, closeModal } = useModal()

  const [comprovantes, setComprovantes] = useState<any[]>([])

  useEffect(() => {
    if (isOpen) {
      const handleComprovantes = async () => {
        try {
          const comprovantes = await VacinaService.loadComprovantes()
          setComprovantes(comprovantes)
        } catch (error) {
          await onError(error as Error)
        }
      }

      void handleComprovantes()
    }
  }, [isOpen])

  const onError = async (error: Error) => {
    closeModal()
    await Alert.call({
      title: 'Falha no Histórico',
      description: error.message,
      type: 'error'
    })
  }

  const modalConfigs: ModalProps = {
    isOpen,
    icon: <HistoryIcon />,
    title: `Histórico de Envio`,
    description: `Acompanhe a situação de cada comprovante já enviado por você.`,
    actions: [],
    size: 'xl',
    onClose: closeModal
  }

  const tableColumns: Array<TableColumn<Comprovante>> = [
    {
      name: 'Comprovante',
      selector: data => data.nome_arquivo,
      center: true,
      grow: 2.5
    },
    {
      name: 'Situação',
      cell: data => {
        switch (data.deferido) {
          case null:
            return <Tag bg="warning">{ComprovanteStatus.Aguardando}</Tag>
          case 0:
            return <Tag bg="danger">{ComprovanteStatus.Indeferido}</Tag>
          case 1:
            return <Tag bg="success">{ComprovanteStatus.Deferido}</Tag>
        }
      },
      center: true
    },
    {
      name: 'Data de Envio',
      selector: data =>
        Date.format({
          date: data.data_de_criacao,
          pattern: 'DD, H:MI'
        }),
      center: true,
      grow: 1.5
    },
    {
      name: 'Última Atualização',
      selector: data => {
        return Date.format({
          date: data.ultima_atualizacao,
          pattern: 'DD, H:MI'
        })
      },
      center: true,
      grow: 1.5
    },
    {
      name: 'Ações',
      cell: data => (
        <ViewComprovanteModal
          comprovanteId={data.id}
          pessoaName={toTitleCase(data.pessoa.nome)}
        />
      ),
      center: true
    }
  ]

  return (
    <>
      <Button variant="positive" onClick={openModal}>
        Visualizar Histórico
      </Button>
      <Modal {...modalConfigs}>
        <Table
          data={comprovantes}
          columns={tableColumns}
          isPaginationVisible={false}
        />
      </Modal>
    </>
  )
}

export default ShowEnvioHistoryModal
