import { MouseEvent } from 'react'
import { Button } from 'react-bootstrap'
import { FaCheck as DeferirIcon } from 'react-icons/fa'

import { VacinaService } from '@core/services'
import { Alert } from '@core/gateways'
import { useModal } from '@presentation/hooks'
import { ActionButton } from '@presentation/components/molecules'
import { Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'

type Props = {
  comprovanteId: string
  pessoaName: string
  onAccept: () => void
}

const DeferirComprovanteModal: React.FC<Props> = ({
  comprovanteId,
  pessoaName,
  onAccept
}) => {
  const { isOpen, openModal, closeModal } = useModal()

  const onSuccess = async () => {
    closeModal()
    onAccept()

    await Alert.call({
      title: 'Deferido com Sucesso',
      type: 'success'
    })
  }

  const onError = async (error: Error) => {
    closeModal()
    await Alert.call({
      title: 'Falha no Deferimento',
      description: error.message,
      type: 'error'
    })
  }

  const onConfirm = async (event: MouseEvent<HTMLButtonElement>) => {
    try {
      await VacinaService.acceptComprovante({
        id: comprovanteId
      })
      await onSuccess()
    } catch (error) {
      await onError(error as Error)
    }
  }

  const buttonActions = [
    <Button variant="success" onClick={onConfirm}>
      Confirmar
    </Button>
  ]
  const modalConfigs: ModalProps = {
    isOpen,
    icon: <DeferirIcon />,
    title: 'Deferimento de Documento',
    description: `Tem certeza de que deseja aprovar o comprovante enviado por ${pessoaName} ?`,
    actions: buttonActions,
    size: 'sm',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="approve" onClick={openModal} />
      <Modal {...modalConfigs}></Modal>
    </>
  )
}

export default DeferirComprovanteModal
