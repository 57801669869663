import { TablePageProps } from '@presentation/types/components/templates'
import { Page } from '@presentation/components/templates'
import { Filter, Table } from '@presentation/components/organisms'

const TablePage: React.FC<TablePageProps> = ({
  pageConfigs,
  filterConfigs,
  tableConfigs
}) => {
  return (
    <Page {...pageConfigs}>
      <Filter {...filterConfigs} />
      <Table {...tableConfigs} />
    </Page>
  )
}

export default TablePage
