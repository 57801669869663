import { FeriadoForm } from '@presentation/components/organisms'
import { EditModal } from '@presentation/components/templates'
import { EditModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  id: string
  onEdit: () => void
}

const EditFeriadoModal: React.FC<Props> = ({ id, onEdit }) => {
  const modalConfigs: EditModalProps = {
    id,
    path: '/feriados',
    formId: 'edit-feriado',
    nameForTitle: 'Feriado',
    validationSchema,
    mapRequest: data => ({
      ...data
    }),
    onEdit
  }

  return (
    <EditModal {...modalConfigs}>
      <FeriadoForm />
    </EditModal>
  )
}

export default EditFeriadoModal
