import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup
    .string()
    .required('O campo é obrigatório')
    .max(100, 'O campo atingiu o limite máximo de 100 caracteres'),
  data: yup.date().required('O campo é obrigatório'),
  tipo: yup.object(),
  descricao: yup
    .string()
    .max(100, 'O campo atingiu o limite máximo de 100 caracteres')
})
