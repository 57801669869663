import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { rgba } from 'polished'

export const Container = styled(Dropdown.Header)`
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: ${props => props.theme.colors.textPrimary};
  font-weight: 700;

  span {
    float: right;
    color: ${props => rgba(props.theme.colors.textPrimary, 0.7)};

    a {
      :hover {
        color: ${props => props.theme.colors.textPrimary};
      }

      small {
        font-size: 0.75rem;
      }
    }
  }
`
