import { Pessoa, PessoaIdentificacao } from '@core/models/entities'
import { toTitleCase } from '@presentation/utils'

export const mapData = (input: Pessoa) => {
  return {
    nome: toTitleCase(input.nome),
    nomeSocial: input.nome_social,
    dataNascimento: input.nascimento,
    rg: input.identidade,
    cpf: input.cpf,
    emailInstitucional: input.email_institucional,
    emailPessoal: input.email,
    telefone: input.celular,
    numeroCartao: input.numero_cartao,
    observacoes: input.obs,
    identificacoes: mapIdentificacoesData(input.identificacoes)
  }
}

export const mapIdentificacoesData = (
  identificacoes: PessoaIdentificacao[]
) => {
  const identificacoesRequest = []

  for (const identificacao of identificacoes) {
    const data = mapIdentificacaoData(identificacao)
    identificacoesRequest.push(data)
  }

  return identificacoesRequest
}

export const mapIdentificacaoData = (identificacao: PessoaIdentificacao) => {
  const data = {
    identificacaoId: identificacao.id,
    identificacao: identificacao.identificacao,
    tipo: identificacao.pessoa_tipo,
    situacao: identificacao.situacao,
    gruposAcesso: identificacao.grupos_acesso,
    categoria: identificacao.categoria
  }

  Object.assign(data, mapIdentificacaoTipoData(identificacao))

  return data
}

const mapIdentificacaoTipoData = (identificacao: PessoaIdentificacao) => {
  const tipoAttr = identificacao.pessoa_tipo.attr

  switch (tipoAttr) {
    case 'tecnico':
    case 'integrado':
    case 'graduacao':
      return {
        ...identificacao.aluno,
        tipoId: identificacao.aluno?.id,
        dataInicio: identificacao.aluno?.data_inicial,
        dataFim: identificacao.aluno?.data_final
      }

    case 'terceirizado':
      return {
        ...identificacao.terceirizado,
        tipoId: identificacao.terceirizado?.id,
        telefoneResponsavel: identificacao.terceirizado?.contato_responsavel
      }

    case 'extensao':
      return {
        ...identificacao.extensao,
        tipoId: identificacao.extensao?.id,
        dataInicio: identificacao.extensao?.data_inicial,
        dataFim: identificacao.extensao?.data_final
      }

    case 'visitante':
      return {
        ...identificacao.visitante,
        tipoId: identificacao.visitante?.id,
        dataInicio: identificacao.visitante?.data_inicial,
        dataFim: identificacao.visitante?.data_final
      }

    default:
      return {
        ...identificacao[tipoAttr],
        tipoId: identificacao[tipoAttr]?.id
      }
  }
}
