import { media } from '@presentation/styles'
import styled from 'styled-components'

export const Container = styled.div`
  p,
  li {
    line-height: 1.5;
    text-align: justify;
  }

  p:last-child {
    margin-bottom: 0rem;
  }
`

export const PlayerWrapper = styled.div`
  margin: 0 auto;
  height: 300px;
  margin-bottom: 1.5rem;

  ${media.greaterThan('tablet')`
    width: 90%;
    height: 400px;
  `}

  ${media.greaterThan('desktop')`
    width: 80%;
    height: 500px;
  `}
`
