import { useEffect, useState } from 'react'
import { FaHome as HomeIcon } from 'react-icons/fa'

import { LinkRapido } from '@core/models/entities'
import { useLinksRapidos } from '@presentation/hooks'
import { withLinksRapidos } from '@presentation/components/hocs'
import { Page } from '@presentation/components/templates'
import { Section } from '@presentation/components/molecules'

import { LinkCard } from './components'
import * as S from './styles'

const HomePage = () => {
  const linksRapidos = useLinksRapidos()

  const [links, setLinks] = useState<LinkRapido[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadLinks = async () => {
      try {
        const links = await linksRapidos.loadAll()
        setLinks(links)
        setIsLoading(false)
      } catch (e) {
        console.error(e)
      }
    }

    void loadLinks()
  }, [])

  const pageConfigs = {
    title: 'Página Inicial',
    description: 'Página para visualização de dados do Banco Central.',
    icon: <HomeIcon />,
    actions: [],
    isLoading: isLoading
  }

  return (
    <Page {...pageConfigs}>
      <Section title="Links Rápidos">
        <S.LinksWrapper>
          {links?.map(link => {
            return (
              <LinkCard
                key={link.id}
                linkId={link.id}
                path={link.url}
                title={link.nome}
              />
            )
          })}
        </S.LinksWrapper>
      </Section>
    </Page>
  )
}

export default withLinksRapidos(HomePage)
