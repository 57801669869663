import styled from 'styled-components'
import { rgba } from 'polished'

import { media } from '@presentation/styles'
import { lightTheme } from '@presentation/styles/themes'

export const Background = styled.img`
  display: none;

  ${media.greaterThan('mobile')`
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100%;
    filter: blur(2px);
    transform: scale(1.01); // corrige bordas brancas devido o blur
    z-index: ${lightTheme.indexes.low};
  `};
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  z-index: ${lightTheme.indexes.high};

  background-color: ${lightTheme.colors.background};

  ${media.greaterThan('mobile')`
    /* Begin: centraliza horizontalmente e verticalmente */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* End: centraliza horizontalmente e verticalmente */
    width: 30rem;
    padding: 2rem 3rem;
    border-radius: 5px;
    background-color: ${rgba(lightTheme.colors.background, 0.8)};
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
  `}
`

export const Header = styled.div`
  width: 100%;
  position: relative; // importante para alinhamento no divider (absolute position)
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 1.6rem;
`

export const IfceLogoWrapper = styled.div`
  width: 45%;
  text-align: center;
`

export const IfceLogo = styled.img`
  margin: 0 auto;
  width: 5.625rem;
`

export const SystemLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  svg {
    width: 3.5rem;
    margin: 0 auto;
  }
`

export const SystemName = styled.h2`
  margin-top: 0.3rem;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  color: ${lightTheme.colors.textPrimary};
`

export const Divider = styled.div`
  position: absolute;
  left: 50%;
  content: '';
  border: 1px solid ${lightTheme.colors.textPrimary};
  opacity: 0.3;
  height: calc(100% - 1rem);
`

export const Title = styled.h1`
  border-left: thick solid ${lightTheme.colors.primary};
  padding-left: 0.6rem;

  font-size: 1.325rem;
  font-weight: 700;

  margin-bottom: 1.25rem;
`

export const Content = styled.div``
