import { Section } from '@presentation/components/molecules'

const DefinicoesSection: React.FC = () => {
  return (
    <>
      <Section title="Definições" />

      <p>
        Para melhor compreensão das expressões aqui utilizadas, vale elucidar
        sobre a conceito de cada uma:
      </p>

      <ul>
        <li>
          <strong>Sistema de gestão integrado (ERP):</strong> é uma categoria de
          software que permite o uso centralizado dos processos internos da
          instituição em um único modelo de gestão;
        </li>
        <li>
          <strong>Usuário:</strong> todas as pessoas que possui um vínculo
          institucional ao IFCE campus Maracanaú. Considera-se um usuário: aluno
          do técnico, integrado, graduação, mestrado ou de extensão devidamente
          matriculados; servidor com vínculo empregatício à instituição, como
          técnicos administrativos, terceirizados ou estagiários; visitante com
          a devida autorização;
        </li>
      </ul>
    </>
  )
}

export default DefinicoesSection
