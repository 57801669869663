import { useTheme } from 'styled-components'
import * as S from './styles'
import { AccordionProps } from '@presentation/types/components/molecules/accordeon'

const Accordion: React.FC<AccordionProps> = ({
  BodyText,
  HeaderText
}: AccordionProps) => {
  const { title } = useTheme()
  return (
    <>
      <S.StyledAccordion mode={title}>
        <S.StyledAccordionHeader>{HeaderText}</S.StyledAccordionHeader>
        <S.StyledAccordionBody>{BodyText}</S.StyledAccordionBody>
      </S.StyledAccordion>
    </>
  )
}

export default Accordion
