import { HttpClient } from '@core/gateways'
import { UnexpectedError, ValidationError } from '@core/models/errors'
import { setupIntranetV2ApiClient } from '@core/proxies/apis/intranet-v2'
import { HttpStatusCode } from '@core/types/gateways'
import {
  AutorizaTransitoLanche,
  ObterTransitoStats
} from '@core/types/services'

export const autorizaTransitoLanche: AutorizaTransitoLanche = async ({
  numeroCartao,
  pessoaId
}) => {
  const response = await HttpClient.of(setupIntranetV2ApiClient()).request({
    url: `/restaurante/transito-lanche`,
    method: 'POST',
    body: {
      numero_cartao: numeroCartao,
      pessoa_id: pessoaId
    }
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body
    case HttpStatusCode.badRequest:
      throw new ValidationError(response.body.errors)
    default:
      throw new UnexpectedError()
  }
}

export const obterTransitoStats: ObterTransitoStats = async () => {
  const response = await HttpClient.of(setupIntranetV2ApiClient()).request({
    url: '/restaurante/transito-lanche/hoje',
    method: 'GET'
  })

  switch (response.statusCode) {
    case HttpStatusCode.ok:
      return response.body
    default:
      throw new UnexpectedError()
  }
}
