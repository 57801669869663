import styled, { css } from 'styled-components'
import { Form } from 'react-bootstrap'
import { rgba } from 'polished'

export const Input = styled(Form.Control)`
  ${({ theme }) => css`
    color: ${theme.colors.textPrimary} !important;
    background-color: ${theme.colors.backgroundInput};

    &:focus {
      background-color: ${theme.colors.backgroundInput};
      border: 1px solid ${theme.colors.primary};
      box-shadow: 0 0 0 0.25rem ${rgba(theme.colors.primary, 0.25)};
    }

    &:disabled {
      border: 1px solid ${theme.colors.borderInput};
      background-color: ${theme.colors.background} !important;
    }

    &::placeholder {
      color: ${rgba(theme.colors.textPrimary, 0.75)};
    }
  `}
`
