import { handleLinks } from '../components/handleLinks'

const capLinks = [
  {
    title: 'FLUXO ALMOX 01 - ENVIAR EMPENHO PARA FORNECEDOR.pdf',
    link: 'https://drive.google.com/file/d/1GP4fWWeMQfhJcXAAlftKCw4_eQ5HNNYI/view'
  },
  {
    title: 'FLUXO ALMOX 02 - ACOMPANHAR ENTREGA DE MATERIAIS.pdf',
    link: 'https://drive.google.com/file/d/1GN9Vl94jQCbbwpKHpqE3KZi4BuAPyt0V/view'
  },
  {
    title: 'FLUXO ALMOX 03 - RECEBER PROVISÓRIO DE MATERIAIS.pdf',
    link: 'https://drive.google.com/file/d/1GMcHPD1DWwGSZaI6AUVIl2nHGof9yeSk/view'
  },
  {
    title: 'FLUXO ALMOX 04 - RECEBIMENTO DEFINITIVO DE MATERIAIS.pdf',
    link: 'https://drive.google.com/file/d/1GMb0RsVgMBWh7sSk0SYb_7fwxwEATsAE/view'
  },
  {
    title: 'FLUXO ALMOX 05 - FORNECIMENTO DE MATERIAL DE CONSUMO.pdf',
    link: 'https://drive.google.com/file/d/1GHsVy0wRwbwUyVKHvREJ_DLx64pLHlXX/view'
  },
  {
    title:
      'FLUXO PATRIM 01 - TRANSFERÊNCIA DE RESPONSABILIDADE PATRIMONIAL_.pdf',
    link: 'https://drive.google.com/file/d/1GEov31LslOVRKJ5NkGxP7nCGx-YCeu44/view'
  },
  {
    title:
      'FLUXO PATRIM 02 - EMISSÃO DE CERTIDÃO NEGATIVA DE ENCARGOS (“NADA CONSTA”)_.pdf',
    link: 'https://drive.google.com/file/d/1G7oBXyeRVlyc4P1b1iAlmWni2PFxtPLS/view'
  },
  {
    title: 'POP01 - Enviar empenho para Fornecedor.pdf',
    link: 'https://drive.google.com/file/d/1yMTgbbLpWmqPfaUWuwsUpNgfJVHGRG-K/view?usp=drive_link'
  },
  {
    title: 'POP02 - Acompanhar entrega de materiais.pdf',
    link: 'https://drive.google.com/file/d/1Tirs5ax5Ayc7gDe8Ulr-auFQg6TX7lBa/view?usp=drive_link'
  },
  {
    title: 'POP03 - Recebimento provisório de materiais.pdf',
    link: 'https://drive.google.com/file/d/1s1DFHnGMPSpjY8ypIHzImJQBJPPwEqMZ/view?usp=drive_link'
  },
  {
    title: 'POP04 - Recebimento definitivo de materiais.pdf',
    link: 'https://drive.google.com/file/d/1RGL_6pvDAQxyzRZtjdKapDAy7SCIGH_w/view?usp=drive_link'
  }
]

const cifraLinks = [
  {
    title: 'FLUXO CINFRA 01 - ATENDIMENTO DE OCORRÊNCIA.pdf',
    link: 'https://drive.google.com/file/d/1FtXYn7jUxr8oowlMNUJ3AmOuvCrQpjEW/view'
  },
  {
    title: 'FLUXO CINFRA 02 - DEMANDA MÉDIO E LONGO PRAZO.pdf',
    link: 'https://drive.google.com/file/d/1HTDyooum_2Jv55M0cBRrMUhmyZJLBLQD/view'
  },
  {
    title: 'FLUXO CINFRA 05 - MANUTENÇÃO PLATAFORMAS ELEVATÓRIAS.pdf',
    link: 'https://drive.google.com/file/d/1HSH3badSkj9FUhtb4Z5TrzCNOJliX_t-/view'
  },
  {
    title: 'FLUXO CINFRA 09 - LIMPEZA DOS RESERVATÓRIOS.pdf',
    link: 'https://drive.google.com/file/d/1HBcAmd6T4laJugJJfF7NlCXm7Y8Fwjnu/view'
  },
  {
    title: 'FLUXO CINFRA 011 - LIBERAÇÃO DE ACESSO À LABORATÓRIOS.pdf',
    link: 'https://drive.google.com/file/d/1HU9btiHGr1jd8q7JyhPXu4vt-_cQJ7Gg/view'
  }
]
const CTILinks = [
  {
    title:
      'POP01 - Solicitar serviços materiais para o setor de informática.pdf',
    link: 'https://drive.google.com/file/d/1KQscdeSFdHwQdS-5GNNfbb7-Y4vtHPtG/view?usp=drive_link'
  },
  {
    title: 'POP02 - Processo de Gestão de Incidentes.pdf',
    link: 'https://drive.google.com/file/d/1KQhlydG0gNtfAYLBjFJZLdMkBNjO2OZM/view?usp=drive_link'
  }
]

const CACLinks = [
  {
    title:
      'FLUXO CAC 01 - SELECIONAR FORNECEDOR ATRAVÉS DE PREGÃO ELETRÔNICO.pdf',
    link: 'https://drive.google.com/file/d/1KomXUWUr5GMH_A6eqtDMiO0Zit9do9C_/view?usp=drive_link'
  },
  {
    title:
      'FLUXO CAC 02 - SELECIONAR FORNECEDOR ATRAVÉS DE DISPENSA DE LICITAÇÃO.pdf',
    link: 'https://drive.google.com/file/d/1L-tmJ2RnynJ5_cAOo35vt36Q3a9U0iKT/view?usp=drive_link'
  },
  {
    title:
      'FLUXO CAC 03 - REALIZAR A CONTRATAÇÃO ATRAVÉS DE INEXIGIBILIDADE DE LICITAÇÃO.pdf',
    link: 'https://drive.google.com/file/d/1L7_BBPJ0jo44QsJg2bOM0ybaPC36GQ2y/view?usp=drive_link'
  }
]

const EstagioLinks = [
  {
    title: 'FLUXO ESTÁGIO 01 - ATIVIDADES LABORAIS.pdf',
    link: 'https://drive.google.com/file/d/1LFx4jNbVPqNcseDSwRG9QkFaPCDahHId/view?usp=drive_link'
  },
  {
    title: 'FLUXO ESTÁGIO 02 - ESTÁGIO OBRIGATÓRIO E NÃO OBRIGATÓRIO.pdf',
    link: 'https://drive.google.com/file/d/1LGF9-abcpTv0NMj4y7d9Sp4FyEhp95Oy/view?usp=drive_link'
  }
]

const GABLinks = [
  {
    title: 'FLUXO GAB01 - CESSÃO DE AUDITORIO PARA PÚBLICO EXTERNO.pdf',
    link: 'https://drive.google.com/file/d/1L7h1NFSaUDVHn-MmU-aFBp6-Ckz3xJOg/view?usp=drive_link'
  },
  {
    title: 'FLUXO GAB02 - Organizar colação de grau.pdf',
    link: 'https://drive.google.com/file/d/1LDEzo_JJmfUiztz22XV_DlzFVLqh_GsS/view?usp=drive_link'
  }
]

export const dapConfigs = [
  {
    BodyText: capLinks.map(link => handleLinks({ ...link })),
    HeaderText: 'CAP - Coordenação de Almoxarifado e Patrimônio'
  },
  {
    BodyText: cifraLinks.map(link => handleLinks({ ...link })),
    HeaderText: 'CINFRA - Coordenação de Infraestrutura'
  },
  {
    BodyText: CACLinks.map(link => handleLinks({ ...link })),
    HeaderText: 'CAC - Coordenação de Aquisições e Contratos'
  }
]

export const dgConfigs = [
  {
    BodyText: CTILinks.map(link => handleLinks({ ...link })),
    HeaderText: 'CTI - Coordenação de Tecnologia da Informação'
  },
  {
    BodyText: GABLinks.map(link => handleLinks({ ...link })),
    HeaderText: 'GAB - Gabinete da Direção Geral'
  }
]

export const direnConfigs = [
  {
    BodyText: EstagioLinks.map(link => handleLinks({ ...link })),
    HeaderText: 'Setor de Estágio'
  }
]
