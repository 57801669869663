import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { Image } from 'react-bootstrap'

import { ProfileProps } from '.'

export const Container = styled.div<ProfileProps>`
  ${({ theme, variant }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${ProfileImage} {
      /* box-shadow: 0 0 0 2px
        ${variant === 'primary'
        ? `${theme.colors.primary};`
        : `${theme.colors.textSecondary};`}; */
      border: 1px solid transparent;
      background: ${variant === 'primary'
        ? `${theme.colors.primary};`
        : `${theme.colors.white};`};
    }

    ${ProfileName} {
      color: ${variant === 'primary'
        ? `${theme.colors.textPrimary};`
        : `${theme.colors.white};`};
    }

    ${ProfileRole} {
      color: ${variant === 'primary'
        ? `${rgba(theme.colors.textPrimary, 0.8)};`
        : `${rgba(theme.colors.white, 0.8)};`};
    }
  `}
`

export const ProfileImage = styled(Image)`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  padding: 1px;
`

export const ProfileName = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
`

export const ProfileRole = styled.span`
  font-size: 0.9rem;
  font-weight: 400;
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5rem;
`
