import { BlocoForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  onAdd: () => void
}

const AddBlocoModal: React.FC<Props> = ({ onAdd }) => {
  const modalConfigs: AddModalProps = {
    path: '/blocos',
    formId: 'add-bloco',
    nameForTitle: 'Bloco',
    validationSchema: validationSchema,
    mapRequest: data => ({
      ...data,
      eixo_id: data.eixo.id
    }),
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <BlocoForm />
    </AddModal>
  )
}

export default AddBlocoModal
