import { env } from '@main/configs'
import { BrandProps } from '@presentation/types/components/molecules'

import * as S from './styles'

const Brand: React.FC<BrandProps> = ({ variant = 'full' }) => {
  return (
    <S.Container to={env.app.homepage}>
      {['full', 'icon'].includes(variant) && env.app.logo.normal}

      {['full', 'name'].includes(variant) && (
        <S.BrandText>{process.env.REACT_APP_NAME}</S.BrandText>
      )}
    </S.Container>
  )
}

export default Brand
