import { useForm } from '@presentation/hooks'
import { SelectApi } from '@presentation/components/molecules'

export const SetorSelectApi: React.FC = () => {
  const { control, formState } = useForm()

  return (
    <SelectApi
      label="Setor"
      name="setor"
      path={`/setores?sort_by=nome`}
      control={control}
      error={formState?.errors.setor?.message}
      isRequired
    />
  )
}

export default SetorSelectApi
