export const hasIdentificacaoDuplicate = (
  identificacao: string,
  fields: any[],
  ignoreId?: number
) => {
  if (ignoreId !== undefined) {
    const editField = fields[ignoreId]

    for (const field of fields) {
      if (field !== editField) {
        if (field.identificacao.toString() === identificacao) return true
      }
    }

    return false
  } else {
    return fields.some(
      field => field.identificacao.toString() === identificacao
    )
  }
}
