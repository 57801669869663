export type Permissao = {
  id: number
  nome: string
  descricao: string
  categoria: string
  status: boolean
}

export type GrupoAcessoPermissao = Omit<
  Permissao,
  'descricao' | 'categoria'
> & {
  tipo: PermissaoTipo
}

export enum PermissaoTipo {
  Leitura = 'R',
  Escrita = 'W',
  Remocao = 'D',
  LeituraEscrita = 'RW',
  LeituraRemocao = 'RD',
  EscritaRemocao = 'WD',
  FullControl = 'RWD'
}
