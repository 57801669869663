import { MouseEvent, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaTrash as RemoveIcon } from 'react-icons/fa'

import { Alert } from '@core/gateways'
import { AdministracaoService } from '@core/services'
import { NotFoundError, UnexpectedError } from '@core/models/errors'
import { useModal } from '@presentation/hooks'
import { ActionButton } from '@presentation/components/molecules'
import { Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'
import { DeleteModalProps } from '@presentation/types/components/templates'

const DeleteModal: React.FC<DeleteModalProps> = ({
  id,
  path,
  nameForTitle,
  onDelete,
  dataKeyForDescription = 'nome'
}) => {
  const { isOpen, openModal, closeModal } = useModal()
  const [data, setData] = useState<any>()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (isOpen) {
      const loadData = async () => {
        try {
          const data = await AdministracaoService.loadOne({ id, path })
          setData(data)
        } catch (error) {
          onError(error as Error)
        }
      }
      void loadData()
    }
  }, [isOpen])

  const onSuccess = () => {
    closeModal()
    if (onDelete) {
      onDelete()
    }
    Alert.callSuccess({
      title: 'Removido com Sucesso'
    })
  }

  const onError = (error: Error) => {
    closeModal()
    Alert.callError({
      title: 'Falha na Remoção',
      description: error.message
    })
  }

  const onConfirm = async (event: MouseEvent<HTMLButtonElement>) => {
    try {
      setIsSubmitting(false)
      await AdministracaoService.remove({
        id,
        path
      })
      onSuccess()
      setIsSubmitting(true)
    } catch (error) {
      if (error instanceof UnexpectedError || error instanceof NotFoundError) {
        onError(error)
      }
    }
  }

  const buttonActions = [
    <Button variant="success" onClick={onConfirm} disabled={isSubmitting}>
      Confirmar
    </Button>
  ]
  const configs: ModalProps = {
    isOpen,
    icon: <RemoveIcon />,
    title: `Remoção de ${nameForTitle}`,
    description: `Tem certeza de que deseja excluir o item '${
      data ? data[dataKeyForDescription] : ''
    }' ?`,
    actions: buttonActions,
    size: 'sm',
    onClose: closeModal
  }

  return (
    <>
      <ActionButton type="delete" onClick={openModal} />
      <Modal {...configs} />
    </>
  )
}

export default DeleteModal
