import styled from 'styled-components'
import BarLoader from 'react-spinners/BarLoader'

export const Container = styled.div`
  position: absolute;
  z-index: ${props => props.theme.indexes.high + 1};

  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${props => props.theme.colors.background};
`

export const ProgressBar = styled(BarLoader)``

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 100%;
    max-width: 120px;
    margin-bottom: 35px;
  }
`
