import { AxiosInstance, AxiosResponse } from 'axios'

import { HttpClient } from '@core/types/gateways'

class AxiosHttpClient implements HttpClient {
  private constructor(private readonly axiosClient: AxiosInstance) {}

  static of(axiosClient: AxiosInstance): AxiosHttpClient {
    return new AxiosHttpClient(axiosClient)
  }

  async request(input: HttpClient.Input): Promise<HttpClient.Output> {
    let response: AxiosResponse

    try {
      response = await this.axiosClient.request({
        url: input.url,
        method: input.method,
        data: input.body,
        headers: input.headers,
        params: input.params,
        responseType: input.responseType ? input.responseType : 'json'
      })
    } catch (error: any) {
      response = error.response
    }

    return {
      statusCode: response.status,
      body: response.data,
      headers: response.headers
    }
  }
}

export default AxiosHttpClient
