import { Col, Form, Row } from 'react-bootstrap'

import { Input, SelectApi, TextInput } from '@presentation/components/molecules'
import { useForm } from '@presentation/hooks'

const LotacaoForm: React.FC = () => {
  const { register, control, formState, formId, onSubmit } = useForm()

  return (
    <Form id={formId} onSubmit={onSubmit}>
      <Row className="mb-3">
        <Col md>
          <Input
            label="Nome"
            name="nome"
            register={register}
            error={formState?.errors.nome?.message}
            required
            type="text"
            placeholder="Digite o nome da lotação"
          />
        </Col>
        <Col>
          <SelectApi
            name="responsavel"
            control={control}
            path="/pessoas?tipo=3&situacao=5"
            label="Responsável"
            isRequired
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Input
            label="Email"
            name="email"
            register={register}
            error={formState?.errors.email?.message}
            required
            type="text"
            placeholder="Digite o email da lotação"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md>
          <TextInput
            label="Observações"
            name="observacoes"
            register={register}
            error={formState?.errors.observacoes?.message}
            required
            placeholder="Escreva alguma observação sobre a lotação"
          />
        </Col>
      </Row>
    </Form>
  )
}

export default LotacaoForm
