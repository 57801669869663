import { useEffect, useState } from 'react'
import { Variant } from 'react-bootstrap/esm/types'

import { Message } from '@presentation/components/molecules'

import * as S from './styles'
import { TransitoStatus } from '../../types'

type Props = {
  status: TransitoStatus
  errors: string[]
}

const MessageStatus: React.FC<Props> = ({ status, errors }) => {
  const [variant, setVariant] = useState<Variant>()
  const [title, setTitle] = useState<string>()

  useEffect(() => {
    switch (status) {
      case TransitoStatus.Autorizado:
        setVariant('success')
        setTitle('Trânsito Autorizado!')
        break
      case TransitoStatus.NaoAutorizado:
        setVariant('danger')
        setTitle('Trânsito Não Autorizado!')
        break
      case TransitoStatus.NenhumTransito:
        setVariant('warning')
        setTitle('Nenhum Trânsito Registrado!')
        break
    }
  }, [status])

  return (
    <Message variant={variant}>
      <S.MessageContent>
        <S.MessageTitle>{title}</S.MessageTitle>
        {status === TransitoStatus.NaoAutorizado &&
          errors.length > 0 &&
          errors.map(error => <S.MessageErrorItem>{error}</S.MessageErrorItem>)}
      </S.MessageContent>
    </Message>
  )
}

export default MessageStatus
