import Skeleton from 'react-loading-skeleton'

import * as S from './styles'

type Props = {
  isLoading: boolean
}

const Content: React.FC<Props> = ({ isLoading, children }) => {
  return (
    <S.Container>
      {isLoading ? <Skeleton width={'500'} height={'60vh'} /> : children}
    </S.Container>
  )
}

export default Content
