import * as S from './styles'

const Header: React.FC = () => {
  return (
    <S.Container>
      <h2>Termos de Uso</h2>
      <p>
        <i>Versão 1</i> <br /> Maracanaú, 01 de Março de 2021
      </p>
    </S.Container>
  )
}

export default Header
