import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FaTimes as IndeferirIcon } from 'react-icons/fa'

import { Alert } from '@core/gateways'
import { VacinaService } from '@core/services'
import { ValidationError } from '@core/models/errors'
import {
  ActionButton,
  ErrorList,
  SubmitButton
} from '@presentation/components/molecules'
import { IndeferimentoForm, Modal } from '@presentation/components/organisms'
import { ModalProps } from '@presentation/types/components/organisms'
import { useModal } from '@presentation/hooks'
import { FormContext } from '@presentation/contexts'

import { validationSchema } from './validation'

type Props = {
  comprovanteId: string
  pessoaName: string
  onDeny: () => void
}

const IndeferirComprovanteModal: React.FC<Props> = ({
  comprovanteId,
  pessoaName,
  onDeny
}) => {
  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })
  const { isOpen, openModal, closeModal } = useModal()
  const [apiErrors, setApiErrors] = useState<string[]>([])

  const formId = 'indeferir-comprovante-form'

  const clearApiErrors = () => {
    setApiErrors([])
  }

  const handleCloseModal = () => {
    closeModal()
    formMethods.reset()
  }

  const onSuccess = async () => {
    handleCloseModal()
    onDeny()

    await Alert.call({
      title: 'Indeferido com Sucesso',
      type: 'success'
    })
  }

  const onError = async (error: Error) => {
    handleCloseModal()
    await Alert.call({
      title: 'Falha de Indeferimento',
      description: error.message,
      type: 'error'
    })
  }

  const onSubmit: SubmitHandler<any> = async input => {
    try {
      await VacinaService.denyComprovante({
        id: comprovanteId,
        motivo: input.motivo
      })
      await onSuccess()
    } catch (error) {
      if (error instanceof ValidationError) {
        setApiErrors(error.errors)
      } else {
        await onError(error as Error)
      }
    }
  }

  const buttonActions = [
    <SubmitButton
      form={formId}
      isValid={formMethods.formState.isValid}
      isSubmitting={formMethods.formState.isSubmitting}
    >
      Salvar
    </SubmitButton>
  ]
  const modalConfigs: ModalProps = {
    isOpen,
    icon: <IndeferirIcon />,
    title: 'Indeferimento de Documento',
    description: `Use o formulário abaixo para negar o comprovante enviado por ${pessoaName}.`,
    actions: buttonActions,
    onClose: handleCloseModal
  }

  return (
    <>
      <ActionButton type="deny" onClick={openModal} />
      <Modal {...modalConfigs}>
        {apiErrors.length > 0 && (
          <ErrorList errors={apiErrors} onHide={clearApiErrors} />
        )}
        <FormContext.Provider
          value={{
            formId,
            onSubmit: formMethods.handleSubmit(onSubmit),
            ...formMethods
          }}
        >
          <IndeferimentoForm />
        </FormContext.Provider>
      </Modal>
    </>
  )
}

export default IndeferirComprovanteModal
