import { Button } from 'react-bootstrap'

import { Title } from '@presentation/components/atoms'
import { ModalProps } from '@presentation/types/components/organisms'

import * as S from './styles'

const Modal: React.FC<ModalProps> = ({
  icon,
  title,
  description,
  actions,
  size,
  isOpen,
  onClose,
  children
}) => {
  return (
    <>
      {isOpen && <Title title={title} />}
      <S.Modal
        show={isOpen}
        onHide={onClose}
        size={size}
        backdrop="static"
        centered
      >
        <S.Modal.Header closeButton>
          <S.Modal.Title id="contained-modal-title-vcenter">
            {icon} {title}
          </S.Modal.Title>
        </S.Modal.Header>
        <S.Modal.Body>
          <S.Description>{description}</S.Description>
          {children && <S.Content>{children}</S.Content>}
        </S.Modal.Body>
        <S.Modal.Footer>
          <S.ButtonGroup>
            <Button variant="danger" onClick={onClose}>
              Fechar
            </Button>
            {actions?.map(action => action)}
          </S.ButtonGroup>
        </S.Modal.Footer>
      </S.Modal>
    </>
  )
}

export default Modal
