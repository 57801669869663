import { SectionProps } from '@presentation/types/components/molecules'

import * as S from '@presentation/components/molecules/sections/styles'

const Section: React.FC<SectionProps> = ({
  title,
  actions,
  children,
  ...rest
}) => {
  return (
    <>
      <S.Container {...rest}>
        <S.Title>{title}</S.Title>
        {actions && <S.Actions>{actions.map(action => action)}</S.Actions>}
      </S.Container>

      {children}
    </>
  )
}

export default Section
