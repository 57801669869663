import { Variant } from 'react-bootstrap/esm/types'

import * as S from './styles'

type Props = {
  status: string
  variant: Variant
}

const CatracaStatusTag: React.FC<Props> = ({ status, variant }) => {
  return <S.Container bg={variant}>{status}</S.Container>
}

export default CatracaStatusTag
