import { LocalForm } from '@presentation/components/organisms'
import { AddModal } from '@presentation/components/templates'
import { AddModalProps } from '@presentation/types/components/templates'

import { validationSchema } from './shared'

type Props = {
  onAdd: () => void
}

const AddLocalModal: React.FC<Props> = ({ onAdd }) => {
  const modalConfigs: AddModalProps = {
    path: '/locais',
    formId: 'add-local',
    nameForTitle: 'Local',
    validationSchema: validationSchema,
    mapRequest: data => ({
      ...data,
      bloco_id: data.bloco.id,
      tipo_local_id: data.tipoLocal.id
    }),
    onAdd
  }

  return (
    <AddModal {...modalConfigs}>
      <LocalForm />
    </AddModal>
  )
}

export default AddLocalModal
