import { animated, useTransition } from 'react-spring'

import { TransitionProps } from '@presentation/types/components/organisms'

const Transition: React.FC<TransitionProps> = ({
  flag,
  configs,
  currentComponent,
  transitionComponent
}) => {
  const transition = useTransition(flag, { ...configs })

  return transition((styles, item) => (
    <animated.div style={styles}>
      {item ? transitionComponent : currentComponent}
    </animated.div>
  ))
}

export default Transition
