import styled from 'styled-components'

export const Container = styled.header`
  line-height: 1.5;

  h2 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 1.1rem;
  }

  * {
    text-align: center !important;
  }
`
