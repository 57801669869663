import { Form, OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap'
import { FaInfoCircle as InfoIcon } from 'react-icons/fa'

import { LabelProps } from '@presentation/types/components/molecules'

import * as S from './styles'

const Label: React.FC<LabelProps> = ({
  isRequired,
  tooltipText,
  children,
  ...rest
}) => {
  const renderTooltip = (props: TooltipProps) => {
    return tooltipText ? <Tooltip {...props}>{tooltipText}</Tooltip> : <></>
  }

  return (
    <S.Container>
      <Form.Label
        {...rest}
        className={isRequired ? 'required' : ''}
        style={{ marginBottom: 0 }}
      >
        {children}
        {tooltipText && (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top-start"
            overlay={renderTooltip}
          >
            <S.InfoWrapper>
              <InfoIcon />
            </S.InfoWrapper>
          </OverlayTrigger>
        )}
      </Form.Label>
    </S.Container>
  )
}

export default Label
