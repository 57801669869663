import { useNavigate } from 'react-router-dom'
import { RiUserSearchFill as PessoasIcon } from 'react-icons/ri'

import { PermissaoTipo, Pessoa } from '@core/models/entities'
import { useAuth, useFetch } from '@presentation/hooks'
import { toTitleCase } from '@presentation/utils'
import { ActionButton, RowsOnCell } from '@presentation/components/molecules'
import {
  Filter,
  FilterProps,
  TableColumn,
  TableProps
} from '@presentation/types/components/organisms'
import { TablePage } from '@presentation/components/templates'
import {
  PessoaIdentificacoesCell,
  PessoaIdentificacoesHead
} from '@presentation/components/shared'
import { PageProps } from '@presentation/types/components/templates'
import { ResetPasswordModal } from './components'

const PessoasPage: React.FC = () => {
  const { hasPermission } = useAuth()
  const navigate = useNavigate()
  const { data, isFetching, totalItems, fetchData } = useFetch({
    path: '/pessoas',
    options: {
      sortBy: 'nome'
    },
    queries: {
      status: 1
    }
  })

  const pageConfigs: PageProps = {
    icon: <PessoasIcon />,
    title: 'Pessoas',
    description: 'Página de gerenciamento de pessoas do campus.',
    actions: hasPermission(PermissaoTipo.Escrita)
      ? [
          <ActionButton
            type="add"
            onClick={() => navigate('/administracao/pessoas/cadastro')}
          />
        ]
      : []
  }

  const filters: Filter[] = [
    {
      type: {
        label: 'Nome',
        value: 'nome'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        label: 'Número de Cartão',
        value: 'numero_cartao'
      },
      description: {
        type: 'number-input',
        configs: {
          allowLeadingZeros: false
        }
      }
    },
    {
      type: {
        label: 'Tipo',
        value: 'tipo'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/pessoa-tipos?sort_by=descricao',
          isMulti: true,
          getOptionLabel: option => option.descricao,
          getOptionValue: option => option.id,
          searchKey: 'descricao'
        }
      }
    },
    {
      type: {
        label: 'Identificação',
        value: 'identificacao'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    },
    {
      type: {
        label: 'Situação',
        value: 'situacao'
      },
      description: {
        type: 'select-api',
        configs: {
          path: '/situacoes?sort_by=descricao',
          isMulti: true,
          isPaginate: false,
          getOptionLabel: option => option.descricao,
          getOptionValue: option => option.id,
          searchKey: 'descricao'
        }
      }
    },
    {
      type: {
        label: 'Email',
        value: 'email_institucional'
      },
      description: {
        type: 'input',
        configs: {
          type: 'text'
        }
      }
    }
  ]

  const filterConfigs: FilterProps = {
    filters,
    fetchData,
    defaultQueries: {
      status: 1
    },
    hasStatus: false
  }

  const columns: Array<TableColumn<Pessoa>> = [
    {
      name: 'Nome',
      selector: pessoa => toTitleCase(pessoa.nome),
      sortable: true,
      center: true,
      grow: 2
    },
    {
      name: <PessoaIdentificacoesHead />,
      cell: pessoa => (
        <PessoaIdentificacoesCell items={pessoa.identificacoes} />
      ),
      center: true,
      grow: 2
    },
    {
      name: 'Número do Cartão',
      selector: pessoa => pessoa.numero_cartao ?? '',
      center: true
    },
    {
      name: 'Email',
      cell: data => {
        const items = [data.email, data.email_institucional]
        if (items) {
          return <RowsOnCell items={items} />
        }
      },
      center: true,
      grow: 1.5
    },
    {
      name: 'Ações',
      cell: pessoa => (
        <>
          {hasPermission(PermissaoTipo.Escrita) && (
            <ActionButton
              type="edit"
              onClick={() => navigate(`/administracao/pessoas/${pessoa.id}`)}
            />
          )}
          {hasPermission(PermissaoTipo.Escrita) && (
            <ResetPasswordModal pessoa={pessoa} onReset={fetchData} />
          )}
        </>
      ),
      center: true
    }
  ]

  const tableConfigs: TableProps = {
    columns,
    data,
    apiConfigs: {
      fetchData,
      isFetching,
      totalItems
    }
  }

  return (
    <TablePage
      pageConfigs={pageConfigs}
      filterConfigs={filterConfigs}
      tableConfigs={tableConfigs}
    />
  )
}

export default PessoasPage
